import { NavigateFunction } from "react-router";
import { apiPost, apiGet, apiPut } from "../../_shared/services";
import K from "../../_shared/constants";

export async function sendCardToken(data: any, nav?: NavigateFunction) {
  const url = K.API_URLS.CONNECT_CARD;
  return apiPost(url, data, { returnDataExpected: true }, nav);
}
export async function getCards(nav?: NavigateFunction) {
  const url = K.API_URLS.CONNECT_CARD;
  return apiGet(url, { returnDataExpected: true }, nav);
}

export async function getSingleCard (card_id: any, nav?: NavigateFunction) {
  const url = K.API_URLS.CONNECT_SINGLE_CARD(card_id);
  return apiGet(url, { returnDataExpected: true }, nav);
}

export async function editSingleCard (card_id:string, data: any, nav?: NavigateFunction) {
  const url = K.API_URLS.CONNECT_SINGLE_CARD(card_id);
  return apiPut(url, data, { returnDataExpected: true }, nav);
}
