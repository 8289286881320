import { NavigateFunction } from "react-router";
import K from "../constants";
import { apiGet, apiPost, nonSecurePost } from "./apiService";

export async function fetchUserExist(data?: any, nav?: NavigateFunction) {
  const url = K.API_URLS.ACCOUNT_EXIST_CHECK;
  return nonSecurePost(url, data, { returnDataExpected: true });
}


export function getCurrentUserBankAccount(nav?: NavigateFunction) {
  return apiGet(
    K.API_URLS.CONNECT_USER_BANK_ACCOUNTS,
    {
      returnDataExpected: true,
    },
    nav
  );
}