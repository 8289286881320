import { Fragment, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import styled from "styled-components";
import { BankConnection, Loading } from "../_shared/components";
import { ConditionRenderComponent } from "../_shared/components/ConditionRender";

import { useFinicity } from "./hooks";
import PageContent from "../_shared/components/PageContent/PageContent";
import WafiFooter from "../_shared/components/PageContent/WafiFooter";
declare const window: any;

const Finicity: React.FC = () => {
  const { loading, finicityUrl, error, fetchFinicity } = useFinicity();

  return (
    <PageContent >
      <>
        <ConditionRenderComponent
          renderIf={(loading || !finicityUrl) && !error}
        >
          <Loading active={true} />
        </ConditionRenderComponent>
        <ConditionRenderComponent renderIf={error}>
          <BankConnection onContinue={fetchFinicity} loading={loading} />
        </ConditionRenderComponent>
        <ConditionRenderComponent renderIf={!!finicityUrl}>
          <div>
            <FrameWrapper id="connect-container" />
            {/* {!error && <WafiFooter />} */}
          </div>
        </ConditionRenderComponent>
      </>
    </PageContent>
  );
};

const FrameWrapper = styled("div")`
  iframe {
    height: 100vh !important;
    width: 100% !important;
  }
`;

export default Finicity;
