import { Flex, WafiButton } from "../../@styles";
import { Icon } from "semantic-ui-react";
import Close from "../../assets/Close.png";
import { ConditionRenderComponent } from "../ConditionRender";
import { showQuit } from "../../services/quitService";
import { getAccessCode } from "../../services";
import { useSettings } from "../../../../redux/hooks/useSettings";
import { useUser } from "../../../../redux/hooks";

interface IModalHeader {
  leftType?: "signin" | "signup" | "back";
  handleLeftAction?: () => void;
  rightType?: "cancel" | "close";
  handleRightAction?: () => void;
}

export default function ModalHeader({
  handleLeftAction,
  leftType,
  handleRightAction,
  rightType,
}: IModalHeader) {
  const { cancel } = useSettings();

  return (
    <Flex className="center center-hor">
      <Flex className="full-width center">
        <ConditionRenderComponent renderIf={!!leftType}>
          <ConditionRenderComponent renderIf={leftType === "signup"}>
            <WafiButton
              className="margin-right-auto"
              onClick={handleLeftAction}
            >
              {" "}
              Sign up{" "}
            </WafiButton>
          </ConditionRenderComponent>
          <ConditionRenderComponent renderIf={leftType === "signin"}>
            <WafiButton
              className="margin-right-auto"
              onClick={handleLeftAction}
            >
              {" "}
              Sign in{" "}
            </WafiButton>
          </ConditionRenderComponent>
          <ConditionRenderComponent renderIf={leftType === "back"}>
            <Icon
              name="arrow left"
              size="large"
              className="margin-right-auto pointer"
              onClick={handleLeftAction}
            />
          </ConditionRenderComponent>
        </ConditionRenderComponent>
        <ConditionRenderComponent renderIf={!!rightType && !!cancel}>
          <img
            src={Close}
            className={
              !!leftType ? "cancel pointer" : "margin-left-auto cancel pointer"
            }
            onClick={()=>showQuit('quit')}
          />
        </ConditionRenderComponent>

        <ConditionRenderComponent renderIf={rightType === "close"}>
          <img
            src={Close}
            className={
              !!leftType ? "cancel pointer" : "margin-left-auto cancel pointer"
            }
            onClick={handleLeftAction}
          />
        </ConditionRenderComponent>
      </Flex>
    </Flex>
  );
}
