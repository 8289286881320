import styled from "styled-components";

export const LearnMoreWrapper = styled("div")`
.wafiBanner{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    cursor: pointer;
}

.p-0{
    padding: 0px !important;
}

.margin-top-5{
    margin-top: 5px;
}

.wafiBanner .sectionBanner:nth-child(1){
    display: flex;
    align-items: center;

}   

.wafiInfo{
    color: #707070;
}

.promoFont{
    font: normal normal normal 1em/18px Cochin;
    letter-spacing: 0.8px;
    color: #2C39D1;
    margin-right: 20px;
}

.wafilogo{
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #E6EFFF;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    width: 76px;
    height: 30px;
    margin-right: 10px;
}

.waficheckoutlogo{

    width: 76px;
    height: 30px;
    display: flex;
}

.waficheckoutlogo img{
    width: 70px;
    height: 20px;
    margin-right: 20px;
    margin-left: 5px !important;
}

.checkoutButton{
    background-color: #FFFFFF;
    position: relative;
    top: -10px;
}

.wafilogo img{
    width: 70px;
    height: 20px;
}

.exclaim{
    color: #2C39D1 !important;
    font-size: 15px !important;
}


/* The wafiModal (background) */
.wafiModal {
    position: fixed; /* Stay in place */
    z-index: 999; /* Sit on top */
    padding-top: 3%; /* Location of the box */
    padding-bottom: 3%;
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  }
  
  /* wafiModal Content */
  .wafiModal-content {
    position: relative;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 20px;
    margin: auto;
    padding: 0;
    width: 420px;
    min-height: 682px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
    -webkit-animation-name: animatetop;
    -webkit-animation-duration: 0.4s;
    animation-name: animatetop;
    animation-duration: 0.4s
  }
  
  /* Add Animation */
  @-webkit-keyframes animatetop {
    from {top:-300px; opacity:0} 
    to {top:0; opacity:1}
  }
  
  @keyframes animatetop {
    from {top:-300px; opacity:0}
    to {top:0; opacity:1}
  }
  
  /* The Close Button */
  .close {
    color: #000;
    float: right;
    font-size: 28px;
    font-weight: bold;
    margin: 10px;
  }
  
  .close:hover,
  .close:focus {
    color: white;
    text-decoration: none;
    cursor: pointer;
  }
  
  .wafiModal-header {
    height: 250px;
    border-radius: 20px 20px 0px 0px;
    background: #1956CB2B 0% 0% no-repeat padding-box;
    position: relative;
  }
  
  .wafiModal-body {
    padding: 20px 20px;
  }

  .margin-bottom-6{
    margin-bottom: 6px;
  }
  
.wafi-d-flex{
    display: flex;
}

.wafi-align-items{
    align-items: center;
}

.wafiModal-body p{
    color: #000;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
  
}

.blackgb{
    background-color: #000;
    color: white;
    font-size: 15px;
    border-radius: 50%;
    height: 38px;
    width: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
}

.wafiHeader{
    width: 100%;
    position: absolute;
    top: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.wafiHeader img{
    height: 28px;
}


.wafi-mb-25{
    margin-bottom: 25px;
}

.wafiMotto{
    text-align: center;
    font: normal normal bold 25px/30px Cochin;
    letter-spacing: 0px;
    color: #000000;
    margin-top: 20px;
}

.wafiPayFont{
    color: black;
    font-size: 15px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
   
}

.wafiPayFont span{
    font-size: 12px;
    margin: 0px 10px;
    color: #2C39D1;
}

.checkout-option{
    display: flex;
    align-items: center;
}

.no-show{
    display: none;
}

@media only screen and (max-width: 420px) {
    .wafiModal-content {
        width: 95%;
    }

    .sectionBanner .wafilogo{
        display: none;
    }

    .promoFont{
        margin-right: 10px;
    }
    .wafiBanner2, .wafiPayFont{
        display: flex;
        align-items: center;
        justify-content: center;
    }

  }


  /* @media only screen and (max-width: 420px) {

    .sectionBanner .wafilogo{
        display: none;
    }

  } */
`;