
import { Flex, _1624Cochin, _1618Cochin } from "../../@styles";
import { ConditionRenderComponent } from "../ConditionRender";

interface IWafiFooter {
  typeText?: string;
  page?: string;
  handleType?: () => void;
}

export default function WafiFooter({ typeText, handleType, page }: IWafiFooter) {
  return (
    <>

      <ConditionRenderComponent renderIf={!!typeText}>
        <Flex className="center-hor margin-top-19">
          <_1618Cochin onClick={handleType} style={{ cursor: "pointer" }}>
            {typeText}
          </_1618Cochin>
        </Flex>
      </ConditionRenderComponent>
      <Flex className="center-hor ">
        <_1624Cochin color="#707070" alignText="center">
          <div className={page !== ('signup' || 'fast-connect') ? "bottom-30" : "mt-20"}>
            Secured with 256 bit encryption
          </div>
        </_1624Cochin>
      </Flex>
    </>
  );
}
