import { Flex, WafiText } from "../../@styles";
import WafiIconSmall from "../../assets/wafi.png";

export default function WafiImage() {
  return (
    <Flex className="center-hor center margin-top-19">
      <img src={WafiIconSmall} alt="wafi-icon" className="wafi-icon-small" />
    </Flex>
  );
}
