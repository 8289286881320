import React, { useCallback, useEffect, useState } from "react";
import { Button, Icon } from "semantic-ui-react";
import K from "../../constants";
import { EventEmitter } from "../../services";
import { NotificationParams } from "../../@types";
import { StyledDiv, styles } from "./styles/Notification";
import { Flex } from "../PinForm/styles/PinForm";
const Notification: React.FC<{}> = () => {
  const [parameters, updateParameters] = useState<NotificationParams>(
    {} as any
  );
  const {
    autoHides,
    durationShown,
    content,
    iconColor,
    icon,
    iconSize,
    isDismissable,
    isError,
    isWarning,
    handleActiveTimeout,
  } = parameters;
  const hide = useCallback(() => {
    updateParameters({} as any);
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    if (!autoHides) return;
    const timeout = setTimeout(hide, durationShown);
    return () => clearTimeout(timeout);
  }, [autoHides, durationShown, hide]);

  useEffect(() => {
    const eventId = EventEmitter.subscribe(
      K.EVENTS.SHOW_NOTIFICATION,
      updateParameters
    );
    return () => EventEmitter.unsubscribe(K.EVENTS.SHOW_NOTIFICATION, eventId);
  }, []);

  useEffect(() => {
    const eventId = EventEmitter.subscribe(K.EVENTS.DISMISS_NOTIFICATION, hide);
    return () =>
      EventEmitter.unsubscribe(K.EVENTS.DISMISS_NOTIFICATION, eventId);
  }, [hide]);

  return (
    <StyledDiv
      style={content ? styles.show : styles.hide}
      className={`${isError ? "error" : isWarning ? "warning" : "success"}`}
    >
      <Flex>
        {icon && <Icon name={icon} size={iconSize} color={iconColor} />}
        <span className="content">{content}</span>
        {isDismissable && (
          <Button
            circular
            basic
            onClick={hide}
            size="mini"
            icon="close"
            className="exit-btn"
          />
        )}
      </Flex>

      {handleActiveTimeout && (
        <div className="button_active cursor" onClick={handleActiveTimeout}>
          Stay active
          {/* <Button onClick={handleActiveTimeout} content="Stay active" /> */}
        </div>
      )}
    </StyledDiv>
  );
};

export default Notification;
