import { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import Constants from "./modules/_shared/constants";
import {
  formatPhoneNumberToExtension,
  isAuthenticated,
} from "./modules/_shared/services";
import crypto from "crypto-js";
import { getRecipientData } from "./modules/registration/services";
import { useUser } from "./redux/hooks";
import { fetchUserExist } from "./modules/_shared/services/userService";
import localforage from "localforage";
import { useLogin } from "./modules/login/hooks";
import { useConsent } from "./modules/consent/hooks";

// import crypto from "crypto";
interface IProps {}

const Landing: React.FC<IProps> = () => {
  const navigate = useNavigate();
  // const params = useParams();
  const location = useLocation();
  const { state } = useUser();
  const {handleSignIn} = useConsent()

  async function getData() {
    const res = await getRecipientData();

    if (!res.success) return;

    sessionStorage.setItem(
      Constants.CLIENT_NAME.SESSION_TOKEN,
      res.data["client_name"]
    );
  }

  useEffect(() => {
    const auth = async () => {
      // get recipient data
      const accessCode = sessionStorage.getItem(
        Constants.ACCESS_CODE.SESSION_TOKEN
      );
      if (accessCode) getData();

      const isAuth = await isAuthenticated();
      /**
       * authenticated has to be true/false, else its not been set yet
       * since the function to check authentication is asynchronous
       */
      console.log("isAuth", isAuth);
      if (isAuth === true || isAuth === false) {
        if (isAuth) {
          navigate(`/`);
        } else {
          handleSignIn()
        }
      }
    };
    auth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default Landing;
