import Constants from "../../../constants";
import { nonSecurePost } from "../../../services";
import { TokenObject } from "../@types";

export const getInitialPin = (): TokenObject => ({
  token: "",
});

export function initializeVerification(data: string) {
  const requestData = {
    emailPhone: data,
  };
  const url = Constants.API_URLS.INITIALIZE_VERIFICATION;
  return nonSecurePost(url, requestData, { returnDataExpected: true });
}

export function verifyToken(data: any) {
  const requestData = {
    emailPhone: data.emailPhone,
    token: data.token,
  };
  const url = Constants.API_URLS.VERIFY_TOKEN;
  return nonSecurePost(url, requestData, { returnDataExpected: true });
}

export const copyToClipboard = (str?: string) => {
  if (
    navigator &&
    navigator.clipboard &&
    navigator.clipboard.writeText &&
    str
  ) {
    return navigator.clipboard.writeText(str);
  }

  return Promise.reject("The Clipboard API is not available.");
};
