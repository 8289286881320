import axios from "axios";
import {
  apiPost,
  getBaseApiUrl,
  instance,
  nonSecurePost,
} from "../../_shared/services";
import { LoginObject } from "../@types";
import K from "../constants";

export const getInitialLogInData = (): LoginObject => {
  return {
    id: "",
    password: "",
  };
};

export function getInitialPhoneData(phoneData?: string, userId?: string) {
  let phoneId = "";
  let emailId = "";
  if (userId && userId.includes("@")) {
    emailId = userId;
    phoneId = "";
  }

  if (phoneData && phoneData.includes("@")) {
    emailId = phoneData;
    phoneId = "";
  }
  if (userId && !userId.includes("@")) {
    emailId = "";
    phoneId = userId;
  }

  if (phoneData && !phoneData.includes("@")) {
    emailId = "";
    phoneId = phoneData;
  }
  return {
    phone: phoneId,
    email: emailId,
  };
}

// non-secure request
export async function login(data: LoginObject) {
  const url = K.API_URLS.LOGIN;
  return nonSecurePost(url, data, { returnDataExpected: true });
}

export async function initializeResetPin(data: any) {
  const url = K.API_URLS.INITIALIZE_RESET_PIN;
  return nonSecurePost(url, data, { returnDataExpected: true });
}

export async function changePin(data: any) {
  const url = K.API_URLS.CHANGE_PIN;
  return nonSecurePost(url, data, { returnDataExpected: true });
}
