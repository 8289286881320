import styled from "styled-components";
import { Button } from "semantic-ui-react";
import K from "./../constants";

// place items in the middle
export const BodyWrapper = styled("div")`
  width: 100%;
  box-sizing: border-box;

  .wafi-icon {
    display: block;
    ${K.LAYOUT.MEDIA_QUERIES.TABLET_PX} {
      display: none;
    }
  }

  .wafi-icon-small {
    display: none;
    ${K.LAYOUT.MEDIA_QUERIES.TABLET_PX} {
      display: block;
    }
  }

  .cancel {
    width: 16px !important;
    height: 16px !important;
    font-size: inherit !important;
  }

  .pointer {
    cursor: pointer;
    :hover {
      transform: scale(1.2);
    }
  }
`;

export const FlexWrapper = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
`;

export const Flex = styled("div")`
  display: flex;

  .margin-right-auto {
    margin-right: auto !important;
  }
`;

export const SectionWrapper = styled("div")`
  display: flex;
  flex-wrap: wrap;

  ${K.LAYOUT.MEDIA_QUERIES.LAPTOP_PX} {
    background: #8d8d8d1a 0% 0% no-repeat padding-box;
    opacity: 1;
    padding: 1.2em 1em;
    border-radius: 40px;
  }

  .flex-wrapper {
    flex: 100%;

    &:not(:first-of-type) {
      margin-top: 19px;
    }

    ${K.LAYOUT.MEDIA_QUERIES.LAPTOP_PX} {
      flex: 50%;

      &:nth-of-type(2) {
        margin-top: 0px;
      }
    }
  }
`;

// modal-like wrapper
export const BoxWrapper = styled("div")`
  width: 100%;
  margin: auto;
  padding: 35px 25px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;

  ${K.LAYOUT.MEDIA_QUERIES.TABLET_PX} {
    width: 50%;
  }

  .center {
    align-items: center;
  }

  .center-hor {
    justify-content: center;
  }

  .full-width {
    width: 100% !important;
  }

  .full-width2 {
    width: 100% !important;

    ${K.LAYOUT.MEDIA_QUERIES.TABLET_PX} {
      width: 65% !important;
    }
  }

  .margin-top-70 {
    margin-top: 70px !important;
  }

  .margin-top-40 {
    margin-top: 40px !important;
  }

  .margin-top-19 {
    margin-top: 19px !important;
  }

  .margin-top-12 {
    margin-top: 19px !important;
  }
  .margin-top-4 {
    margin-top: 4px !important;
  }
`;

export const SignUpButton = styled(Button)`
  background: #ffffff 0% 0% no-repeat padding-box !important;
  border: 1px solid #000000 !important;
  border-radius: 5px !important;
  font: normal normal bold 16px/19px Cochin !important;
  letter-spacing: 0px !important;
  color: #000000 !important;
  width: 129px;
`;

export const WafiText = styled("h4")`
  font: normal normal bold 20px/24px Kefa;
  text-align: left;
  color: #000000;
  opacity: 1;
  padding-left: 12px;
  letter-spacing: 4px;
  margin: 0px;

  ${K.LAYOUT.MEDIA_QUERIES.TABLET_PX} {
    font: normal normal bold 28px/34px Kefa;
    letter-spacing: 5.6px;
  }
`;

export const ConnectText = styled("h4")`
  text-align: center;
  font: normal normal bold 19px/24px Cochin;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
`;

export const ConnectSubText = styled("h4")`
  text-align: center;
  font: normal normal normal 16px/18px Cochin;
  letter-spacing: 0px;
  color: #2c39d1;
  opacity: 1;
`;

export const ContinueButton = styled(Button)`
  background: #000000 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 3px 6px #00000029 !important;
  border-radius: 5px !important;
  opacity: 1;
  height: 50px;
  // width: 75%;
`;

export const ContinueText = styled("span")`
  text-align: left;
  font: normal normal bold 20px/14px Cochin;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
`;

export const ContinueSubText = styled("span")`
  font: normal normal normal 16px/20px Cochin;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
`;

export const SecureText = styled("h4")`
  text-align: center;
  font: normal normal normal 16px/24px Cochin;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
`;
