import * as yup from "yup";
import {
  isVOIPNumber,
  strictValidatePhoneNumber,
} from "../../_shared/services";
import { getInitialAddresses } from "./registrationService";

export const AddressValidationSchema = yup
  .array()
  .of(
    yup
      .object()
      .shape({
        street1: yup.string().required("Address is required").nullable(),
        apartment_no: yup.string(),
        city: yup.string().required("City is required").nullable(),
        state: yup.string().required("State is required").nullable(),
        postalCode: yup.string().required("Postal Code is required").nullable(),
        country: yup.string().required("Country is required").nullable(),
      })
      .transform((value: any) => {
        if (
          !value.street1 &&
          !value.city &&
          !value.state &&
          !value.postalCode &&
          !value.country
        )
          return undefined;

        return value;
      })
      .default(undefined)
    // .optional()
  )
  .min(0)
  .max(2);
// .notRequired()
// .default(getInitialAddresses());

export const PersonalInfoValidationSchema = yup.object().shape({
  firstName: yup.string().required("First Name is required"),
  lastName: yup.string().required("Last Name is required"),
  email: yup
    .string()
    .email("Invalid email")
    .required("Email Address is required"),
  phone: yup
    .string()
    .required("Phone Number is required")
    .test(
      "is valid phone number?",
      "Not a valid phone number",
      (value: any) => {
        return strictValidatePhoneNumber(value);
      }
    )
    .min(12, "Phone number must be 10 digits"),
  // addresses: AddressValidationSchema,
});
