import { v4 } from "uuid";
import K from "../constants";

/**
 * a simple function that implements an interface to allow
 * components to communicate through a subscribe/dispatch system
 */
export const EventEmitter = (function () {
  const events: Record<string, Array<{ id: string; func: Function; eventState?: any  }>> = {};

  return {
    events,
    dispatch: (event: string, data: any = null) => {
      if (!events[event]) return; // no one is listening to this event
      events[event].forEach((handler) => {
        // console.log("got i nhere");
        handler.func(data);
      });
    },

    subscribe: (event: string, callback: Function, eventState?: string) => {
      if (!events[event]) events[event] = []; // new event
      const eventId = v4();
      events[event] = events[event].concat([{ id: eventId, func: callback , eventState: eventState}]);
      return eventId;
    },

    unsubscribe: (event: string, id: string) => {
      if (!events[event]) return;
      events[event] = events[event].filter((handler) => handler.id !== id);
    },
  };
})();

export function emitEvent(event: string, data?: any) {
  EventEmitter.dispatch(event, data);
}

export function emitReloadEvent() {
  EventEmitter.dispatch(K.EVENTS.RELOAD);
}

export default EventEmitter;
