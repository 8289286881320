import * as yup from "yup";
import {
  strictValidatePhoneNumber,
  strictValidateEmail,
} from "../../_shared/services";

export const PhoneValidationSchema = yup.object().shape({
  phone: yup
    .string()
    .required("Phone number is required")
    .test(
      "is valid phone number?",
      "Not a valid phone number",
      (value: any) => {
        return strictValidatePhoneNumber(value);
      }
    )
    .min(12, "Phone number must be 10 digits"),
});
export const EmailValidationSchema = yup.object().shape({
  email: yup
    .string()
    .required("Email is required")
    .test(
      "is valid email address?",
      "Not a valid valid email address",
      (value: any) => {
        return strictValidateEmail(value);
      }
    ),
});
