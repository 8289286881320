import { FormikErrors } from "formik";
import { Form, Input, Icon } from "semantic-ui-react";
import { FormChangeDataProps } from "../../hooks/useForm";
import { InputWrapper } from "./styles/InputField";
import { useState } from "react";

interface IInputFieldProps {
  label: string | React.ReactNode;
  required?: boolean;
  className?: string;
  error?: string;
  id?: string;
  autocomplete?: string;
  name?: string;
  value?: string | (() => string);
  onBlur?: (event: any) => Promise<void> | Promise<FormikErrors<any>>;
  onChange?: (
    _: React.SyntheticEvent<HTMLElement, Event> | undefined,
    data: FormChangeDataProps
  ) => Promise<void>;
  placeholder?: string;
  type?: string;
  disabled?: boolean;
  maxLength?: number;
  handleKeyPress?: any;
  autoFocus?: boolean;
}

const InputField: React.FC<IInputFieldProps> = (props) => {
  const {
    label,
    className,
    error,
    name,
    value,
    onBlur,
    onChange,
    placeholder,
    type,
    disabled,
    maxLength,
    handleKeyPress,
    id, 
    autocomplete,
    autoFocus
  } = props;
  const [show, setShow] = useState<boolean>(false)

  const HandleShow = () =>{
    setShow(!show)
  }

  return (
    <InputWrapper className={className}>
      <Form.Field>{label}</Form.Field>
      <Input
        error={!!error}
        name={name}
        id={id}
        autoComplete={autocomplete}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        placeholder={placeholder}
        type={type === "password" ? 
          !show ? type : "text"
        : type}
        disabled={disabled}
        maxLength={maxLength}
        onKeyDown={handleKeyPress}
        autoFocus={autoFocus || false}
      />

      {type === "password" && 
      <span onClick={HandleShow} className="right-10 eye-color"><Icon name={show ? "eye" : "eye slash"} inverted={true} />
      </span>}
      
      {!!error && <small className="err" >{error}</small>}
    </InputWrapper>
  );
};

export default InputField;
