import * as React from "react";
import { StyledDiv } from "./styles/Loading";
import WafiIconSmall from "../../assets/WafiIcon.svg";
export interface Props {
  active: boolean;
  compact?: boolean;
}

const Loading: React.FC<Props> = ({ active, compact }) => {
  if (active)
    return (
      <StyledDiv role="progressbar" style={compact ? { width: "auto" } : {}}>
        <div className="spinner">
          <img src={WafiIconSmall} alt="" />
        </div>

        <div className="secured">
          <p>Secured with 256 bit encryption</p>
        </div>
      </StyledDiv>
    );
  return null;
};

export default Loading;
