import { useDispatch, useSelector } from "react-redux";
import { ReduxState } from "../@types";
import { dispatchState } from "../reducers";

export function useSettings() {
  const state = useSelector((state: ReduxState) => state.settings);

  const dispatch = useDispatch();

  async function updateCancel(value: boolean) {
    dispatch(
      dispatchState(
        {
          ...state,
          cancel: value,
        },
        "SETTINGS"
      )
    );
  }

  return {
    cancel: state.cancel,
    updateCancel,
  };
}
