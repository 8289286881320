import { useDispatch, useSelector } from "react-redux";
import { ReduxState } from "../@types";
import { dispatchState } from "../reducers";
import jwt_decode from "jwt-decode";
import {
  decrypt,
  handleVerificationState,
  showErrorMessage,
} from "../../modules/_shared/services";
import Constants from "../../modules/_shared/constants";
import localforage from "localforage";
import { IToken } from "../../modules/_shared/@types";
import { decryptToken } from "../../modules/_shared/services/decryptService";

export function useUser() {
  const state = useSelector((state: ReduxState) => state.user);

  const dispatch = useDispatch();

  async function saveLoginData(userData: IToken, rememberDevice: boolean) {
    // sends me the token
    console.log("rememberDevice", rememberDevice);

    const decryptResponse = await decryptToken(userData);
    if (decryptResponse.error || !decryptResponse.data) {
      showErrorMessage("Could not decrypt");
      return;
    }

    let exp = decryptResponse.data.data.claims.exp;

    // always store in session 
    sessionStorage.setItem(
      Constants.STORAGE_KEYS.JWT_TOKEN,
      decryptResponse.data.data.access_token
    );

    sessionStorage.setItem(
      Constants.STORAGE_KEYS.REFRESH_TOKEN,
      decryptResponse.data.data.refresh_token
    );

    // if we are to remember device them we also store in local forage
    if (rememberDevice) {
      await localforage.setItem(
        Constants.STORAGE_KEYS.JWT_TOKEN,
        decryptResponse.data.data.access_token
      );
      await localforage.setItem(
        Constants.STORAGE_KEYS.REFRESH_TOKEN,
        decryptResponse.data.data.refresh_token
      );
    } 

    await localforage.setItem("rememberDevice", rememberDevice ? "1" : "0");
    await localforage.setItem(Constants.STORAGE_KEYS.jWT_EXPIRY, exp);

    await handleVerificationState(Constants.VERIFICATION.STATES.VERIFIED);

    // gets the current user id from the get current user endpoint and store in the local storage
    dispatch(
      dispatchState(
        {
          ...state,
          currentUser: decryptResponse.data.data.claims,
          authenticated: true,
          verified: true,
        },
        "USER"
      )
    );
  }

  function userVerified(val: boolean) {
    dispatch(
      dispatchState(
        {
          ...state,
          verified: val,
        },
        "USER"
      )
    );
  }
  function setRememberDevice(val: boolean) {
    dispatch(
      dispatchState(
        {
          ...state,
          rememberDevice: val,
        },
        "USER"
      )
    );
  }
  function setSignin(val: boolean) {
    dispatch(
      dispatchState(
        {
          ...state,
          signin: val,
        },
        "USER"
      )
    );
  }

  function setSignup(val: boolean) {
    dispatch(
      dispatchState(
        {
          ...state,
          signup: val,
        },
        "USER"
      )
    );
  }

  function savePrefetchedUser(value?: any) {
    dispatch(
      dispatchState(
        {
          ...state,
          prefetchedUser: value,
        },
        "USER"
      )
    );
  }

  return {
    saveLoginData,
    userVerified,
    state,
    savePrefetchedUser,
    setRememberDevice,
    setSignin,
    setSignup,
  };
}
