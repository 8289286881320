import { useEffect } from "react"
import { getOperationMode } from "./_shared/services"


// Add this to the head of each page on Shop, Wallet, Connect, Dashboard, Account
// Paste this code as high in the <head> of the page as possible
const GAScriptHead = `
<!-- Google Tag Manager -->
<script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','GTM-M8VNRS6G');</script>
<!-- End Google Tag Manager -->
`

// Paste this code immediately after the opening <body> tag:
const GAScriptBody = `
<!-- Google Tag Manager (noscript) -->
<noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-M8VNRS6G"
height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
<!-- End Google Tag Manager (noscript) -->
`

const useGA = () => {
    useEffect(()=>{
        const body = document.getElementsByTagName("body")
        const head = document.getElementsByTagName("head")
        
        const opMode = getOperationMode()

        if (body.length > 0 && body.length > 0 && opMode === "production"){
        console.log("Configuring GA ... ... ...")
        head[0].insertAdjacentHTML("afterbegin", GAScriptHead);
        body[0].insertAdjacentHTML("afterbegin", GAScriptBody);
    }
    }, [])
}


export default useGA;
