import styled from "styled-components";
import K from "../../../constants";
import { Button, Input } from "semantic-ui-react";

export const Flex = styled("div")`
  display: flex;

  .margin-right-auto {
    margin-right: auto !important;
  }

  .margin-left-auto {
    margin-left: auto !important;
  }

  .phonefield {
    input {
      letter-spacing: 7.2px !important;
      text-align: center !important;
      height: 50px !important;
    }
  }

  .spinner {
    width: 70px;
    text-align: center;
  }
  .spinner > div {
    width: 10px;
    height: 10px;
    background-color: #707070;

    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bounceDelay 1.4s infinite ease-in-out both;
    animation: sk-bounceDelay 1.4s infinite ease-in-out both;
  }

  .spinner .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }
  .spinner .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }
  @keyframes sk-bounceDelay {
    0%,
    80%,
    100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }
`;
export const FlexWrapper = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
`;

// modal-like wrapper
export const BoxWrapper = styled("div")`
  width: 100%;
  margin: auto;
  padding: 35px 25px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;

  .wafi-icon {
    display: block;
    ${K.LAYOUT.MEDIA_QUERIES.TABLET_PX} {
      display: none;
    }
  }

  .wafi-icon-small {
    display: none;
    ${K.LAYOUT.MEDIA_QUERIES.TABLET_PX} {
      display: block;
    }
  }

  .cancel {
    width: 16px !important;
    height: 16px !important;
    font-size: inherit !important;
  }

  .pointer {
    cursor: pointer;
    :hover {
      transform: scale(1.2);
    }
  }

  ${K.LAYOUT.MEDIA_QUERIES.TABLET_PX} {
    width: 50%;
  }

  .center {
    align-items: center;
    // text-align: center;
  }

  .center-hor {
    justify-content: center;
  }

  .full-width {
    width: 80% !important;
  }

  .progress-bar {
    .bar {
      background-color: #2c39d1 !important;
    }
  }

  .full-width2 {
    width: 100%;

    ${K.LAYOUT.MEDIA_QUERIES.TABLET_PX} {
      width: 100%;
    }
  }

  .margin-top-70 {
    margin-top: 70px !important;
  }

  .margin-top-40 {
    margin-top: 40px !important;
  }

  .margin-top-19 {
    margin-top: 19px !important;
  }

  .margin-top-12 {
    margin-top: 19px !important;
  }
`;

export const SignUpButton = styled(Button)`
  background: #ffffff 0% 0% no-repeat padding-box !important;
  border: 1px solid #000000 !important;
  border-radius: 5px !important;
  font: normal normal bold 16px/19px Cochin !important;
  letter-spacing: 0px !important;
  color: #000000 !important;
`;

export const WafiText = styled("h4")`
  font: normal normal bold 20px/24px Kefa;
  text-align: left;
  color: #000000;
  opacity: 1;
  padding-left: 12px;
  letter-spacing: 4px;
  margin: 0px;

  ${K.LAYOUT.MEDIA_QUERIES.TABLET_PX} {
    font: normal normal bold 28px/34px Kefa;
    letter-spacing: 5.6px;
  }
`;

export const PhoneText = styled("h4")`
  font: normal normal bold 24px/14px Cochin;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  text-align: center;
`;

export const GrayBox = styled("h4")`
  background: #f7f7f7 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  padding: 16px;
`;
