import { Icon, SemanticICONS } from "semantic-ui-react";
import {
  SectionWrapper,
  Flex,
  IconContainer,
  TitleText,
  SubTitleText,
} from "./styles/SectionContainer";

interface IProps {
  name: SemanticICONS;
  titleText: string;
  subTitleText: string;
  className?: string;
}

const SectionContainer: React.FC<IProps> = (props) => {
  const { name, titleText, subTitleText, className } = props;

  return (
    <SectionWrapper className={className}>
      <Flex className="center-hor">
        <Flex className="full-width">
          <div>
            <IconContainer>
              <Icon name={name} inverted size="large" />
            </IconContainer>
          </div>
          <div className="margin-left-2">
            <TitleText>{titleText}</TitleText>
            <SubTitleText className="margin-top-2">{subTitleText}</SubTitleText>
          </div>
        </Flex>
      </Flex>
    </SectionWrapper>
  );
};

export default SectionContainer;
