import { AxiosError } from "axios";
import { default as GLOBAL_ERRORS } from "../errors";
import { ApiRequestError, IRequestOptions } from "../@types";
import { showErrorMessage } from "./notificationService";
import {
  Navigate,
  NavigateFunction,
  Route,
  Routes,
  useNavigate,
} from "react-router";
import { logout } from "./authService";
import Constants from "../constants";

const UNKNOWN_ERROR_STATUS_CODE = 1000;
const NETWORK_ERROR_STATUS_CODE = 999;
const _401_CODE = 401;

/**
 * In most cases, errors that occur when communicating
 * with the API are handled in the same way,
 * this utility function can be used for that.
 */
export function processErrorResponse(
  error: AxiosError,
  options: IRequestOptions = {},
  nav?: NavigateFunction
): ApiRequestError {
  let {
    useServerErrorMsg = true,
    customErrors = GLOBAL_ERRORS,
    suppressErrors,
    fixedErrorMessage,
  } = options;
  console.log(error, error.message, error.response?.status, error.response?.data, error.response, error.request, error.config);
  /**
   * if for whatever reason, the error thrown has no status code, we use 1000
   */
  let errorStatus = error?.response?.status || UNKNOWN_ERROR_STATUS_CODE;
  // console.log(errorStatus);
  // if (errorStatus === _401_CODE) {
  //   // console.log("I got here", nav);
  //   // nav && nav("/login");
  //   nav && nav(`/${Constants.ROUTES.login}`);
  // }
  if (useServerErrorMsg && error?.response?.data?.message) {
    showErrorMessage(error.response.data.message);
  } else {
    /**
     * In the custom error object that is passed to this function the key **1000** has a special meaning
     * if it is available, then it means that apart from the other errors defined in the object (if any)
     * all other error codes should display the error message dictated by 1000's value.
     * Hence if its present, then we don't merge custom errors with the shared error object
     */
    if (customErrors) {
      customErrors = customErrors[UNKNOWN_ERROR_STATUS_CODE]
        ? customErrors
        : { ...GLOBAL_ERRORS, ...customErrors };
    }

    if (error.message && error.message.indexOf("Network Error") !== -1)
      errorStatus = NETWORK_ERROR_STATUS_CODE;
    if (!suppressErrors) {
      /**
       * The last bit, customErrors[1000], will make sure that if a status code
       * for which we have no defined error is thrown, we still get to show a generic message
       */
      showErrorMessage(
        fixedErrorMessage ||
          customErrors[errorStatus] ||
          customErrors[UNKNOWN_ERROR_STATUS_CODE]
      );
    }
  }
  return {
    error: errorStatus,
    errorData: error.response?.data?.data,
    message: error.response?.data?.message ?? error.message,
  };
}
