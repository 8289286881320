import { Icon } from "semantic-ui-react";
import { Flex, ContinueButton, _2014Cochin, _1618Cochin } from "../../@styles";
import { showQuit } from "../../services/quitService";
import { ConditionRenderComponent } from "../ConditionRender";
import ModalHeader from "../PageContent/ModalHeader";
import WafiFooter from "../PageContent/WafiFooter";
import { WafiText, ErrorWrapper, SmileyText } from "./styles/BankConnection";

interface PhoneProps {
  onContinue?: () => void;
  onExit?: () => void;
}

const BankError: React.FC<PhoneProps> = (props) => {
  const { onContinue, onExit} = props;
  return (
    <>
      <ModalHeader rightType="close" handleLeftAction={onExit} />
      <Flex className="center-hor center margin-top-19">
      <_2014Cochin color="#000" display="block">
        Something went wrong 
      </_2014Cochin>
      </Flex>
      <Flex className="center-hor margin-top-40">
        <ErrorWrapper className="full-width2">
            Something went wrong while attempting to connect your bank.
            Please try to connect the same bank or a different one again. 
            Make sure the checking account and profile information checkbox is checked.
            If this issue persists, exit and try a different method.
        </ErrorWrapper>
      </Flex>

      <Flex className="center-hor margin-top-19">
        {/* <SmileyText>😔</SmileyText> */}
      </Flex>
      
        <Flex className="center-hor margin-top-40">
          <ContinueButton
            bg="#000"
            className="full-width2"
            onClick={onContinue}
          >
            <Icon name="lock" inverted={true} />
            <_2014Cochin color="#fff">Connect your bank</_2014Cochin>
          </ContinueButton>
        </Flex>
    
      <Flex className="center-hor margin-top-19 pointer">
        <_1618Cochin onClick={onExit}>
          Exit
        </_1618Cochin>
      </Flex>
      <WafiFooter />
    </>
  );
};

export default BankError;
