import { Icon, Popup } from "semantic-ui-react";
import useForm from "../../hooks/useForm";
import {
  copyToClipboard,
  getInitialPin,
  PinValidationSchema,
} from "./services";
import {
  Flex,
  InputField,
  _2414Cochin,
  _1620Cochin,
  _1614Cochin,
  ContinueButton,
  ContinueText,
  _2014Cochin,
} from "../../@styles";
import ModalHeader from "../PageContent/ModalHeader";
import { showQuit } from "../../services/quitService";
import WafiImage from "../PageContent/WafiImage";
import WafiFooter from "../PageContent/WafiFooter";
import { useCallback, useEffect, useState } from "react";
import { ConditionRenderComponent } from "../ConditionRender";
import { checkIfCharacter } from "../../services";

interface VerificationProps {
  onContinue?: any;
  leftAction?: "signup" | "back";
  handleLeftAction?: () => void;
  title: string;
  mainText: string;
  subText: string;
  boldText: any;
  inputRightAction?: string;
  handleInputRightAction?: () => void;
  loading?: boolean;
  cancel?: boolean;
  sendCode: (value?: any) => any;
  modal?: boolean;
  token?: string;
  changephoneNo : boolean;

}

const VerificationForm: React.FC<VerificationProps> = (props) => {
  const {
    onContinue,
    leftAction,
    handleLeftAction,
    mainText,
    subText,
    boldText,
    title,
    inputRightAction,
    handleInputRightAction,
    loading,
    cancel,
    sendCode,
    modal,
    token,
    changephoneNo,

  } = props;

  const form = useForm({
    initialValues: getInitialPin(),
    onSubmit,
    validationSchema: PinValidationSchema,
  });
  //
  const {
    handleSubmit,
    isSubmitting,
    values,
    getError,
    handleBlur,
    handleChange,
    isValid,
    dirty,
  } = form;

  const [copied, setCopied] = useState<boolean>(false);
  const [wait, setWait] = useState(false);
  const [timer, setTimer] = useState<number>(30);
  const timeOutCallback = useCallback(
    () => setTimer((currTimer) => currTimer - 1),
    []
  );

  useEffect(() => {
    timer > 0 && setTimeout(timeOutCallback, 1000);
  }, [timer, timeOutCallback]);

  const resetTimer = function () {
    if (timer <= 0) {
      setTimer(30);
    }
  };
  function pad(d:any) {
    return d < 10 ? "0" + d.toString() : d.toString();
  }



  const item:any = localStorage.getItem("resendtoken");
  const resentToken = JSON.parse(item);
  const Handletwofactor = async () => {
    let currentuser:any = localStorage.getItem("currentLogin");

    


    if (currentuser &&  resentToken && resentToken[currentuser] && resentToken[currentuser].value && resentToken[currentuser].value >= 3) {

          if (resentToken[currentuser].updated_at < new Date().getTime()) {
          setWait(false)
          } else {
            setWait(true);
          }
        } else {
          setWait(false)
 
        }
    
  };
  useEffect(() => {
    Handletwofactor();
  }, [resentToken]);

  useEffect(() => {
    if (copied) {
      setTimeout(() => setCopied(false), 500);
    }
  }, [copied]);

  async function onSubmit() {
    await onContinue(values.token);
  }
  const handleKeyDown = async (event: any) => {

    if (event.key === "Enter") {
      // handleSubmit();
      await onContinue(values.token);
    }
  };

  const handleClipAction = async () => {
    const res = await copyToClipboard(token);

    setCopied(true);
  };
  function handleSendToken(){
    sendCode()
    resetTimer()

  }
  const zero: number = 0

  return (
    <>
      <ModalHeader
        leftType={leftAction}
        handleLeftAction={handleLeftAction}
        rightType={cancel ? "cancel" : undefined}
      />
      <WafiImage />
      <Flex className="center-hor margin-top-40">
        <div className="full-width">
          <_2414Cochin color="#000" display="block">
            {title}
          </_2414Cochin>
          <_1620Cochin className="margin-top-12" display="block" color="#000">
            {mainText}
            <span className="bold">{boldText}</span>
            
            {subText}
          </_1620Cochin>
        </div>
      </Flex>
      {/* <Flex className="margin-top-70 flex-end">
        <ConditionRenderComponent renderIf={!copied}>
          <Popup
            content="Click to copy token"
            trigger={
              <Icon
                name="clipboard outline"
                className="pointer clipboard"
                size="big"
                onClick={handleClipAction}
              />
            }
          />
        </ConditionRenderComponent>
        <ConditionRenderComponent renderIf={!!copied}>
          <Popup
            content="Token copied"
            trigger={
              <Icon name="checkmark" className="pointer clipboard" size="big" />
            }
          />
        </ConditionRenderComponent>
      </Flex> */}
      <Flex className="center-hor margin-top-70 ">
        <InputField
          className="full-width2 pinfield"
          name="token"
          error={getError("token")}
          onChange={handleChange}
          onBlur={handleBlur}
          value={values.token}
          placeholder="000000"
          maxLength={6}
          onKeyDown={handleKeyDown}
        />
      </Flex>
      <Flex className="center-hor margin-top-12">
        <div className="full-width2" style={{ display: "flex" }}>
     { wait ?  (
      <_1614Cochin
          className="margin-right-auto"
           
          color="red"
        >
          Limit exceeded, try again after 24 hours
          </_1614Cochin>) : (timer <= zero ? (  <_1614Cochin
          className="margin-right-auto pointer"
          onClick={handleSendToken}
          color="#2c39d1"
        >
          Send code again?
        </_1614Cochin>) :(  <_1614Cochin
          className="margin-right-auto"
          color="#2c39d1"
        > Didn't get code? 00:{pad(timer)} </_1614Cochin>))
}
         <_1614Cochin onClick={handleInputRightAction}>
         {inputRightAction}
       </_1614Cochin>

          {/* {changephoneNo && <_1614Cochin
            className="margin-left-auto pointer"
            onClick={handleLeftAction}
            color="#2c39d1"
          >
            change phone no.
          </_1614Cochin>} */}
        
          
        </div>
      </Flex>
      <Flex className="center-hor margin-top-40">
        <ContinueButton
          className="full-width2"
          onClick={handleSubmit}
          disabled={!(dirty && isValid) || isSubmitting || loading}
          loading={isSubmitting || loading}
          type="submit"
        >
          <Icon name="lock" inverted={true} />
          <_2014Cochin color="#fff">Continue</_2014Cochin>
        </ContinueButton>
      </Flex>
      <WafiFooter
        typeText={
          leftAction === "signup"
            ? "Don’t have a Wafi account? Sign up"
            : undefined
        }
        handleType={leftAction === "signup" ? handleLeftAction : undefined}
      />
    </>
  );
};

export default VerificationForm;
