import { FlexCenter, WafiAgreement } from "../../@styles"


const WafiNotes: React.FunctionComponent = () => {
  return(
    <FlexCenter>
        <WafiAgreement>
            By clicking “Continue” you agree to our 
            <a target="_blank" rel="noreferrer"  href="https://www.wafi.cash/terms-of-service"> terms of service </a> and <a target="_blank" rel="noreferrer"  href="https://www.wafi.cash/privacy-policy"> privacy policy.</a>
            
        </WafiAgreement>
    </FlexCenter>
  ) ;
};

export default WafiNotes;
