import {   getReadiness  } from "../../registration/services/registrationService";
import {    showErrorMessage  } from "../../_shared/services";


const useReadiness = () => {
  // Function to wait for the specified number of milliseconds
  const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

  const waitForReadiness = async () => {
    let noOfTrials = 0;
    const maxTrials = 2;
    const delay = 2000;

    while (noOfTrials <= maxTrials) {
      const res = await getReadiness();

      if (res.data.checkout && res.data.connect && res.data.payment && res.data.wallet) {
        return true;
      }

      noOfTrials++;

      if (noOfTrials == maxTrials) {
        showErrorMessage(`System is not ready, Try again later`);
        console.log(`System is not ready after ${maxTrials} trials.`);
        return false;
      }

      await sleep(delay);
    }
  };

  return { waitForReadiness };
};

export default useReadiness;
