import React from "react";
import { BodyWrapper, BoxWrapper, FlexWrapper } from "../../@styles";

interface IPageContent {
  content?: React.ReactElement;
  children?: React.ReactElement;
  noPadding?: boolean;
}

export default function PageContent({
  content,
  children,
  noPadding,
}: IPageContent) {
  return (
    <BodyWrapper>
      <FlexWrapper>
        <BoxWrapper noPadding={noPadding}>{children}</BoxWrapper>
      </FlexWrapper>
    </BodyWrapper>
  );
}
