import { PathRouteProps, RouteProps } from "react-router";
import { ProtectedRouteProps } from "./_shared/components";
import Constants from "./_shared/constants";
import { Login } from "./login";
import { PaymentMethod } from "./paymentMethod";
import { OnboardCard } from "./cardForm";
import { Consent } from "./consent";
import { Registration } from "./registration";
import { Finicity } from "./finicity";

const routePaths = Constants.ROUTES;

export const protectedRoutes: ProtectedRouteProps[] = [
  { path: routePaths.finicity, element: Finicity },
  { path: routePaths.paymentMethod, element:PaymentMethod },
  { path: routePaths.onboardCard, element:OnboardCard },
];


export const defaultRoutes: PathRouteProps[] = [
  { path: routePaths.login, element: Login },
  { path: routePaths.consent, element: Consent },
  { path: routePaths.registration, element: Registration },
];
