import styled, { css } from "styled-components";
import K from "./constants";
import { Button, Input, Modal } from "semantic-ui-react";
// place items in the middle
export const ContinueSession = styled(Button)`
  background: #000000 0% 0% no-repeat padding-box !important;
  border-radius: 5px !important;
  opacity: 1 !important;
  //   width: 100% !important;
  text-align: center !important;
  font: normal normal bold 16px/30px Cochin;
  letter-spacing: 0px !important;
  color: #ffffff !important;
  opacity: 1 !important;
  margin: 0px !important;
  // height: 50px !important;
`;

export const ChangeBankText = styled("h4")`
  font: normal normal bold 20px/23px Cochin;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin: 0;
`;

export const ModalWrapper = styled(Modal)`
  .mg-42 {
    margin: 42px 0px 0px 20px;
  }

  .mgb-42 {
    margin-bottom: 40px;
  }

  &&.ui.modal {
    width: 30% !important;

    ${K.LAYOUT.MEDIA_QUERIES.TABLET_PX} {
      width: 40% !important;
    }
    ${K.LAYOUT.MEDIA_QUERIES.MOBILE} {
      width: 45% !important;
    }

    ${K.LAYOUT.MEDIA_QUERIES.SMALL_PX} {
      width: 95% !important;
    }

    ${K.LAYOUT.MEDIA_QUERIES.LAPTOP_PX} {
      width: 30% !important;
    }
  }

  .center {
    align-items: center;
  }
`;

export const BodyWrapper = styled("div")`
  width: 100%;
  box-sizing: border-box;
  background: #e3e0e0 0% 0% no-repeat padding-box;

  .img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: -webkit-fill-available;
    z-index: -1;
  }

  .mt-20 {
    margin-top: 20px;
  }

  .bottom-30 {
    width: 100%;
    position: absolute;
    bottom: 20px;
    right: 0;
    left: 0;
  }

  .pinfield {
    input {
      text-align: center !important;
      letter-spacing: 14.4px !important;
    }
  }

  .progress-bar {
    .bar {
      background-color: #2c39d1 !important;
    }
  }

  .flex-end {
    justify-content: flex-end;
  }

  .clipboard {
    font-size: 1.5rem !important;
  }

  .spinner {
    width: 70px;
    text-align: center;
  }
  .spinner > div {
    width: 10px;
    height: 10px;
    background-color: #2c39d1;

    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bounceDelay 1.4s infinite ease-in-out both;
    animation: sk-bounceDelay 1.4s infinite ease-in-out both;
  }

  .spinner .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }
  .spinner .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }
  @keyframes sk-bounceDelay {
    0%,
    80%,
    100% {
      -webkit-transform: scale(0);
      transform: scale(0);
    }
    40% {
      -webkit-transform: scale(1);
      transform: scale(1);
    }
  }

  .margin-top-100 {
    margin-top: 100px;
  }

  .pointer {
    cursor: pointer !important;
    :hover {
      transform: scale(1.2) !important;
    }
  }
`;

export const FlexWrapper = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
`;

export const FlexCenter = styled("div")`
  align-items: center;
  display: flex;
  justify-content: center;
`;
export const ColFlex = styled("div")`
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
`;

export const Flex = styled("div")`
  align-items: center;
  display: flex;

  .margin-right-auto {
    margin-right: auto !important;
  }

  .margin-left-auto {
    margin-left: auto !important;
  }
  .wafi-icon-small {
    width: 120px;
  }
  ${K.LAYOUT.MEDIA_QUERIES.MOBILE} {
    .wafi-icon-small {
      width: 95px;
    }
  }
  .font-blue {
    color: #2c39d1;
  }
`;

export const FormWrapper = styled("div")`
  border-radius: 20px;
  opacity: 1;
  padding: 1.2em 0;
  display: flex;
  flex-wrap: wrap;

  .wafi-icon {
    display: block;
    ${K.LAYOUT.MEDIA_QUERIES.TABLET_PX} {
      display: none;
    }
  }

  .wafi-icon-small {
    display: none;
    ${K.LAYOUT.MEDIA_QUERIES.TABLET_PX} {
      display: block;
    }
  }

  .flex-wrapper-padding {
    ${K.LAYOUT.MEDIA_QUERIES.LAPTOP_PX} {
      padding: 0 0.8em;
    }
  }

  .flex-wrapper {
    flex: 100%;

    &:not(:first-of-type) {
      margin-top: 19px;
    }

    ${K.LAYOUT.MEDIA_QUERIES.LAPTOP_PX} {
      padding: 0 0.8em;

      flex: 50%;

      &:nth-of-type(2) {
        margin-top: 0px;
      }
    }
  }

  .phonefield {
    input {
      letter-spacing: 0.1px !important;
    }
  }
`;

// modal-like wrapper
export const BoxWrapper = styled("div")<{ noPadding?: boolean }>`
  width: 100%;
  min-height: 100vh;
  margin: auto;
  padding: ${({ noPadding }) => (noPadding ? "15px 0px" : "15px 25px")};
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
  overflow: auto;
  ::-webkit-scrollbar {
    width: 4px;
  }

  .cancel {
    width: 16px !important;
    height: 16px !important;
    font-size: 20px !important;
  }

  .pointer {
    cursor: pointer !important;
    :hover {
      transform: scale(1.2) !important;
    }
  }

  #manaulAddress {
    .field {
      margin: 0 !important;
    }
  }

  ${K.LAYOUT.MEDIA_QUERIES.LAPTOP_PX} {
    width: 460px;
    min-height: 670px;
    position: relative;
    border-radius: 10px;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 10px 50px #0000004d;
  }

  ${K.LAYOUT.MEDIA_QUERIES.TABLET_PX} {
    width: 460px;
    min-height: 670px;
    position: relative;
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 10px 50px #0000004d;
    border-radius: 10px;
  }

  .center {
    align-items: center;
    // text-align: center;
  }

  .margin-top-70 {
    margin-top: 70px;
  }

  .center-hor {
    justify-content: center;
  }

  .full-width {
    width: 100% !important;
  }

  .full-width2 {
    width: 100% !important;

    ${K.LAYOUT.MEDIA_QUERIES.TABLET_PX} {
      width: 100% !important;
      border: none;
    }
  }

  h1 {
    font-weight: 900;
    font-size: 165px;
    line-height: 1;
    // margin-bottom: 0px;
    opacity: 0.6;
  }

  h2 {
    font-weight: 700;
    font-size: 20px;
    // margin-bottom: 12px;
    opacity: 0.9;
  }
  .login-text {
    font: normal normal normal 16px/18px Cochin;
    margin: 8px 0;
    color: #2c39d1;
    cursor: pointer;
  }

  .margin-top-70 {
    margin-top: 70px !important;
  }

  .margin-top-80 {
    margin-top: 80px !important;
  }

  .margin-top-40 {
    margin-top: 40px !important;
  }

  .margin-top-30 {
    margin-top: 30px !important;
  }

  .margin-top-52 {
    margin-top: 52px !important;
  }

  .margin-top-19 {
    margin-top: 19px !important;
  }

  .margin-top-20 {
    margin-top: 20px !important;
  }
  // margin-0 {
  //   margin-bottom: 0px !important;
  // }

  .margin-top-12 {
    margin-top: 12px !important;
  }

  .margin-top-10 {
    margin-top: 10px !important;
  }
  .margin-top-4 {
    margin-top: 4px !important;
  }
  .bold {
    font-weight: bold !important;
  }
  .err {
    color: red !important;
  }
`;

export const WafiButton = styled(Button)`
  background: #ffffff 0% 0% no-repeat padding-box !important;
  border: 1px solid #000000 !important;
  border-radius: 5px !important;
  font: normal normal bold 16px/19px Cochin !important;
  letter-spacing: 0px !important;
  color: #000000 !important;

  height: 40px;
  display: flex !important;
  align-items: center;
  justify-content: center;

  ${K.LAYOUT.MEDIA_QUERIES.LAPTOP_PX} {
    width: 129px;
  }
`;

export const WafiText = styled("span")`
  font: normal normal bold 20px/24px Kefa;
  text-align: left;
  color: #000000;
  opacity: 1;
  padding-left: 12px;
  letter-spacing: 4px;
  margin: 0px;

  ${K.LAYOUT.MEDIA_QUERIES.TABLET_PX} {
    font: normal normal bold 28px/34px Kefa;
    letter-spacing: 5.6px;
  }
`;

export const PhoneText = styled("span")`
  font: normal normal bold 24px/14px Cochin;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
`;

export const PhoneSubText = styled("span")`
  font: normal normal normal 16px/20px Cochin;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
`;

export const ContinueButton = styled(Button)<{ bg?: string }>`
  background: ${({ bg }) => bg ?? "black"} 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 3px 6px #00000029 !important;
  border-radius: 5px !important;
  opacity: 1;
  height: 50px;
  width: 65%;
`;

export const ContinueText = styled("span")`
  text-align: left;
  font: normal normal bold 20px/14px Cochin;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
`;

export const WafiAgreement = styled("span")`
  text-align: center;
  font: normal normal normal 16px/18px Cochin;
  letter-spacing: 0px;
  color: #707070;
  padding: 20px 5px 0px 5px;

  a {
    text-decoration: underline;
    color: #707070 !important;
  }
`;

export const SecureText = styled("span")`
  text-align: center;
  font: normal normal normal 16px/24px Cochin;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
`;

export const FontSharedStyles = css<{
  color?: string;
  alignText?: string;
  display?: string;
}>`
  opacity: 1;
  letter-spacing: 0px;
  margin: 0px;
  color: ${({ color }) => color ?? "#2c39d1"};
  text-align: ${({ alignText }) => alignText ?? "left"};
  display: ${({ display }) => display ?? "inline"} !important;
  line-height: 20px;
`;

export const _1618Cochin = styled("span")<{
  color?: string;
  alignText?: string;
  display?: string;
}>`
  font: normal normal normal 16px/18px Cochin;
  ${FontSharedStyles}
`;

export const _1624Cochin = styled("span")<{
  color?: string;
  alignText?: string;
  display?: string;
}>`
  font: normal normal normal 16px/24px Cochin;
  ${FontSharedStyles}
`;

export const _1924Cochin = styled("span")<{
  color?: string;
  alignText?: string;
  display?: string;
}>`
  font: normal normal bold 19px/24px Cochin;
  ${FontSharedStyles}
`;

export const _1626Cochin = styled("span")<{
  color?: string;
  alignText?: string;
  display?: string;
}>`
  font: normal normal bold 24px/26px Cochin;
  ${FontSharedStyles}
`;

export const _1620Cochin = styled("span")<{
  color?: string;
  alignText?: string;
  display?: string;
}>`
  font: normal normal normal 16px/20px Cochin;
  ${FontSharedStyles}
`;

export const _2414Cochin = styled("span")<{
  color?: string;
  alignText?: string;
  display?: string;
}>`
  font: normal normal bold 24px/14px Cochin;
  ${FontSharedStyles}
`;

export const _1614Cochin = styled("span")<{
  color?: string;
  alignText?: string;
  display?: string;
}>`
  font: normal normal normal 16px/14px Cochin;
  ${FontSharedStyles}
`;

export const _2014Cochin = styled("span")<{
  color?: string;
  alignText?: string;
  display?: string;
}>`
  font: normal normal normal 20px/14px Cochin;
  ${FontSharedStyles}
`;

export const _2023Cochin = styled("span")<{
  color?: string;
  alignText?: string;
  display?: string;
}>`
  text-align: center !important;
  width: 100%;
  font: normal normal bold 20px/23px Cochin;
  ${FontSharedStyles}
`;

export const _1821Cochin = styled("span")<{
  color?: string;
  alignText?: string;
  display?: string;
}>`
  font: normal normal normal 18px/21px Cochin;
  ${FontSharedStyles}
`;

export const _2428Cochin = styled("span")<{
  color?: string;
  alignText?: string;
  display?: string;
}>`
  font: normal normal normal 24px/28px Cochin;
  ${FontSharedStyles}
`;

export const InputField = styled(Input)`
  background: #ffffff 0% 0% no-repeat padding-box !important;
  border: 0.5px solid #000000 !important;
  border-radius: 5px !important;
  font: normal normal normal 18px/14px Cochin !important;
  letter-spacing: 7.2px !important;
  color: #707070 !important;
`;

export const ErrorWrapper = styled("div")`
  background: #f89494 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  text-align: left;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  padding: 1.2em;
  font: normal normal normal 16px/20px Cochin;
`;
