import * as yup from "yup";
import { strictValidateNumber, strictValidatePassword } from "../../../services";

export const PinValidationSchema = yup.object().shape({
  pin: yup.string().required("Pin is required")
  .test(
    "is valid number",
    "Pin must be numbers",
    (value: any) => {
      return strictValidateNumber(value);
    }
  )
  .test(
    "is valid password",
    "Pin number contains identical or sequential numbers e.g (111, 123, 654)",
    (value: any) => {
      return strictValidatePassword(value);
    }
  )
  .max(6),
});


export const LoginPinValidationSchema = yup.object().shape({
  pin: yup.string().required("Pin is required")
  .test(
    "is valid number",
    "Pin must be numbers",
    (value: any) => {
      return strictValidateNumber(value);
    }
  )
  .max(6),
});