export enum LoginPages {
  PHONE = "phone",
  PIN = "pin",
  VERIFICATION = "verification",
  INIT_RESET_PIN = "init_reset_pin",
  RESET_PIN = "reset_pin",
}

export type LoginObject = {
  id: string;
  password: string;
};

export type PhoneObject = {
  emailPhone: string;
};
