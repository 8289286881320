import { useEffect, useMemo, useState } from "react";
import PageContent from "../_shared/components/PageContent/PageContent";
import { useLocation, useNavigate } from "react-router";
import { registerProps } from "../_shared/@types";
import { Icon } from "semantic-ui-react";
import { _2414Cochin, _1620Cochin, Flex, _2014Cochin, ContinueButton } from "../_shared/@styles";
import ModalHeader from "../_shared/components/PageContent/ModalHeader";
import WafiImage from "../_shared/components/PageContent/WafiImage";
import WafiFooter from "../_shared/components/PageContent/WafiFooter";
import { PaymentMethodRow, PaymentMethodRowIcon, Recommended } from "./styles/Login";
import { sendCardToken, getSingleCard, editSingleCard } from "./services/cardService";
import { getToken } from "../_shared/services";
import K from "../registration/constants";
import { getFinicityUrl } from "../finicity/services/finicityService";
import Constants from "../_shared/constants";
import { Loading } from "../_shared/components";
import { getOperationMode } from "../_shared/services"


const CardForm: React.FC<any> = (props) => {
  const [loading, setLoading] = useState(false);
  const [loadingForm, setLoadingForm] = useState(false);
  const [finixForm, setFinixForm] = useState<any>();
  const { search, pathname } = useLocation();
  const [error, setError] = useState("");
  const [card, setCard] = useState<any>(null);
  const navigate = useNavigate();
  const card_id = new URLSearchParams(search).get("card_id");

  const options = {
    showAddress: true,
    showLabels: true,
    labels: {
      name: "Name on card",
      number: "Card number",
      expiration_date: "Expiry Date",
      security_code: "CVV",
      address_postal_code: "Zip/Postal code",
    },
    showPlaceholders: true,
    placeholders: {
      security_code: "CVV",
      name: "John Doe",
      address_postal_code: "123456",
    },
    hideFields: [
      "address_line1",
      "address_line2",
      "address_city",
      "address_state",
      "address_region",
      "address_country",
    ],
    requiredFields: ["name", "address_postal_code"],
    hideErrorMessages: false,
    errorMessages: {
      name: "Please enter a valid name",
      address_postal_code: "Please enter a postal code",
    },
    styles: {
      default: {
        color: "black",
        border: "2px solid black",
        borderRadius: "8px",
        padding: "6px 8px",
        fontFamily: "Cochin !important",
        fontSize: "14px",
        fontWeight: 500,
      },
    },
    onLoad: function () {
      setLoadingForm(false);
    },
  };

  async function handleProceed() {
    const token = JSON.parse(sessionStorage.getItem("tokens")!);
    let url = new URLSearchParams(search).get("continue_url");

    if (!token) return (window as any).location.replace(url);

    let exp: any = await getToken(Constants.STORAGE_KEYS.jWT_EXPIRY);
    let RD: any = await getToken("rememberDevice");
    const accessToken = encodeURIComponent(token.access_token);
    const refreshToken = encodeURIComponent(token.refresh_token);
    const expiry = encodeURIComponent(exp);
    // console.log(url, "herurle");
    url = url?.includes("?")
      ? `${url}&A_TK=${accessToken}&R_TK=${refreshToken}&EXP=${expiry}&REM_DEVICE=${RD}`
      : `${url}?A_TK=${accessToken}&R_TK=${refreshToken}&EXP=${expiry}&REM_DEVICE=${RD}`;

    (window as any).location.replace(url);
  }

  async function handleGotoFinicity() {
    setLoading(true);
    let url = new URLSearchParams(search).get("continue_url");
    const finresponse = await getFinicityUrl();
    if (finresponse.error) {
      setLoading(false);
      return;
    }
    sessionStorage.setItem(K.STORAGE_KEYS.WAFI_FIN_URL, finresponse.data.link);
    setLoading(false);
    navigate(`/finicity?continue_url=${url ?? ""}`);
  }

  const onSubmit = async () => {
    setLoading(true);
    setError("");
    const opMode = getOperationMode()
    const finixOpMode = opMode === "production" ? "live" : "sandbox"
    const finixAppId = process.env.REACT_APP_FINIX_APP_ID
    console.log("finix", finixAppId, finixOpMode)
    
    finixForm.submit(finixOpMode, finixAppId, async (err: any, res: any) => {
      const tokenData = res.data || {};
      const token = tokenData.id;
      if (!token) {
        setLoading(false);
        setError("Invalid card details");
        return;
      }
      const data = { token, processor: "finix" };
      let cardRes = null;
      if (card_id) {
        cardRes = await editSingleCard(card_id, data);
      } else {
        cardRes = await sendCardToken(data);
      }
      console.log(cardRes);
      if (!cardRes?.error) {
        handleProceed();
      }
      setLoading(false);
    });

  };

  const fetchCard = async () => {
    setLoading(true);
    const res = await getSingleCard(card_id);
    setCard(res.data);
    setLoading(false);
  };

  useEffect(() => {
    if (card_id) fetchCard();
  }, [card_id]);

  useEffect(() => {
    setLoadingForm(true);
    const form: any = (window as any).Finix.CardTokenForm("finix-form", options);
    setFinixForm(form);
  }, []);

  const handleBack = () => {
    if (card_id) {
      handleProceed();
    } else {
      navigate(`/payment-method${search}`);
    }
  }

  return (
    <PageContent>
      <form autoComplete="on">
        <ModalHeader rightType="cancel" leftType="back" handleLeftAction={handleBack} />
        <br />
        <WafiImage />
        <Flex className="center-hor margin-top-40">
          <div className="full-width">
            <_2414Cochin display="block" color="#000">
              {card_id ? "Edit" : "Add"} your card {card && `ending with ...${card.last_four}`}
            </_2414Cochin>
            <_1620Cochin display="block" color="#000" className="margin-top-12">
              We’ll save your card in your Wafi Wallet.
            </_1620Cochin>
            <_1620Cochin display="block" color="red" className="margin-top-12">
              {error}
            </_1620Cochin>
          </div>
        </Flex>

        <Flex className="center-hor margin-top-20 flex-col">
          {loadingForm && (
            <div>
              <Loading active={loadingForm} compact={false}/>
            </div>
          )}
          <div id="finix-form"></div>
        </Flex>

        <Flex className="center-hor margin-top-40">
          <ContinueButton
            className="full-width2"
            onClick={(e: any) => {
              e.preventDefault();
              onSubmit();
            }}
            disabled={loading}
            type="submit"
            loading={loading}
          >
            <Icon name="lock" inverted={true} />

            <_2014Cochin color="#fff">{card_id ? "Edit Card" : "Add Card"}</_2014Cochin>
          </ContinueButton>
        </Flex>

        <WafiFooter
          typeText={"Pay with Bank instead"}
          handleType={handleGotoFinicity}
          page={"signup"}
        />
      </form>
    </PageContent>
  );
};

export default CardForm;
