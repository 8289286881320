import { useCallback, useEffect, useState } from "react";
import {
  IProcessingData,
  PersonalInfoObject,
  RegistrationData,
  RegistrationPages,
} from "../@types";
import { useLocation, useNavigate } from "react-router-dom";
import {
  formatRegistrationData,
  getInitialRegistrationData,
  getRecipientData,
  register,
} from "../services/registrationService";
import {
  initializeVerification,
  verifyToken,
} from "../../_shared/components/VerificationForm/services";
import {
  formatPhoneNumberToExtension,
  removeSeperators,
  showErrorMessage,
  showSuccessMessage,
  twilioLookUp,
} from "../../_shared/services";
import { useUser } from "../../../redux/hooks";
import { getFinicityUrl } from "../../finicity/services/finicityService";
import K from "../constants";
import { fetchUserExist } from "../../_shared/services/userService";
import PersonalInfoForm from "../form/PersonalInfoForm";
import useReadiness from "../../_shared/hooks/useReadiness";

function useRegistration() {
  const { search } = useLocation();
  const {waitForReadiness} = useReadiness()
  // add a useReducer here to store the states temporarily, you do not need to store it with the main state
  const navigate = useNavigate();
  const [page, setPageType] = useState<RegistrationPages>(
    RegistrationPages.PERSONAL_INFORMATION
  );
  const [registrationData, setRegistrationData] = useState<
    Partial<RegistrationData>
  >(getInitialRegistrationData());

  const { saveLoginData, savePrefetchedUser, state } = useUser();
  const [loading, setLoading] = useState<boolean>(false);
  const [prefetched, setPrefetched] = useState<boolean>(false);
  const [token, setToken] = useState<undefined | string>(undefined);
  const [changephoneNo, setchangephoneNo] = useState<boolean>(false);

  const [showProcessing, setShowProcessing] = useState<IProcessingData | null>(
    null
  );
  const [showBankError, setShowBankError] = useState<boolean>(false);
  useEffect(() => {
    if (state.prefetchedUser) {
      console.log(state.prefetchedUser);
      recipientData();
    } else {
      setPrefetched(true);
    }
  }, [state.prefetchedUser]);

  // useEffect(() => {
  //   const data = state.prefetchedUser;

  //   if (state.signup) {
  //     return;
  //   }

  //   if (
  //     data &&
  //     data.recipient &&
  //     data.recipient.phone &&
  //     data.recipient.address &&
  //     data.recipient.address.state_code
  //   ) {
  //     console.log(registrationData);
  //     switchToVerification();

  //     // setchangephoneNo(true)
  //     handleInitVerification(data.recipient.phone);
  //   }

  //   if (
  //     data &&
  //     data.recipient &&
  //     data.recipient.phone &&
  //     (!data.recipient.address || !data.recipient.address.state_code)
  //   ) {
  //     switchToVerification();
  //     // setchangephoneNo(true)
  //     handleInitVerification(data.recipient.phone);
  //   }
  // }, []);

  const recipientData = useCallback(async () => {
    setPrefetched(false);

    const { recipient } = state.prefetchedUser;
    console.log(recipient);
    let personalInfo: PersonalInfoObject = {
      firstName: recipient?.first_name ?? null,
      lastName: recipient?.last_name ?? null,
      email: recipient?.email ?? null,

      phone: recipient?.phone ?? null,
      addresses: [
        {
          street1: recipient?.address?.street_address ?? "",
          apartment_no: recipient?.address?.apartment_no ?? "",
          city: recipient?.address?.city ?? "",
          state: recipient?.address?.state ?? "",
          postalCode: recipient?.address?.zip_code ?? "",
          country: recipient?.address?.country_code ?? "",
        },
      ],
    };
    const data = {
      pin: "",
      personalInfo: personalInfo,
    };

    setRegistrationData(data);
    setPrefetched(true);
  }, [state.prefetchedUser]);

  useEffect(() => {
    // console.log(registrationData);
  }, [registrationData]);

  function switchToPersonalInfo() {
    setPageType(RegistrationPages.PERSONAL_INFORMATION);
  }

  function switchToAddress() {
    //check if address info was supplied
    console.log(registrationData.personalInfo?.addresses[0]);
    if (
      registrationData.personalInfo?.addresses[0].street1 != "" &&
      registrationData.personalInfo?.addresses[0].city != "" &&
      registrationData.personalInfo?.addresses[0].state != "" &&
      registrationData.personalInfo?.addresses[0].postalCode != "" &&
      registrationData.personalInfo?.addresses[0].country != ""
    ) {
      page !== RegistrationPages.PIN
        ? setPageType(RegistrationPages.PIN)
        : setPageType(RegistrationPages.PERSONAL_INFORMATION);
    } else {
      setPageType(RegistrationPages.ADDRESS);
    }
  }

  function switchToVerification() {
    setPageType(RegistrationPages.VERIFICATION);
  }

  function switchToPin() {
    setPageType(RegistrationPages.PIN);
  }

  function handleSignIn() {
    navigate(`/${K.ROUTES.login}${search}`);
  }

  function switchToPayment() {
    navigate("/payment");
  }

  function switchToFinicity(url: string) {
    console.log("finicity", url);
    navigate(`/finicity${url}`);
  }
  const Handletwofactor = async () => {
    let currentuser: any = localStorage.getItem("currentLogin");
    const item: any = localStorage.getItem("resendtoken");
    const resentToken = JSON.parse(item);

    if (resentToken) {
      if (!resentToken[currentuser]) {
        const newVal = {
          [currentuser]: {
            value: 1,
            updated_at: new Date().getTime() + 24 * 60 * 60 * 1000,
          },
        };

        localStorage.setItem(
          "resendtoken",
          JSON.stringify({
            ...resentToken,
            ...newVal,
          })
        );
      } else {
        resentToken[currentuser].value++;
        resentToken[currentuser].updated_at =
          new Date().getTime() + 24 * 60 * 60 * 1000;

        localStorage.setItem("resendtoken", JSON.stringify(resentToken));
      }
    } else {
      const obj = {
        [currentuser]: {
          value: 1,
          updated_at: new Date().getTime() + 24 * 60 * 60 * 1000,
        },
      };
      localStorage.setItem("resendtoken", JSON.stringify(obj));
    }
  };

  async function handleInitVerification(value?: any) {
    localStorage.setItem(
      "currentLogin",
      value
        ? formatPhoneNumberToExtension(value)
        : formatPhoneNumberToExtension(registrationData.personalInfo?.phone!)
    );
    setLoading(true);
    const verificationResponse = await initializeVerification(
      value
        ? formatPhoneNumberToExtension(value)
        : formatPhoneNumberToExtension(registrationData.personalInfo?.phone!)
    );

    setLoading(false);
    if (verificationResponse.error) {
      showErrorMessage("Couldn't send verification token");
      return { error: true };
    }
    // if (verificationResponse.data && verificationResponse.data.data)
    //   setToken(verificationResponse.data.data.code);
    // console.log(verificationResponse);
    Handletwofactor();
    showSuccessMessage("Verification pin sent to your phone number");
    return { success: true };
  }

  async function onPersonalInfoSubmit(values: any) {
    setLoading(true);
    setRegistrationData({ ...registrationData, personalInfo: values });
    const accessCode = sessionStorage.getItem(K.ACCESS_CODE.SESSION_TOKEN);
    if (accessCode) {
      const emailExist = await fetchUserExist({
        email: values.email,
      });
      const phoneExist = await fetchUserExist({
        phone_number: formatPhoneNumberToExtension(values.phone),
      });

      if (
        emailExist.data &&
        !emailExist.data.data.exist &&
        phoneExist.data &&
        phoneExist.data.data.exist
      ) {
        setLoading(false);
        showErrorMessage(
          "This phone number is associated with a different user"
        );

        return;
      }
      if (
        emailExist.data &&
        emailExist.data.data.exist &&
        phoneExist.data &&
        !phoneExist.data.data.exist
      ) {
        setLoading(false);
        showErrorMessage("This email is associated with a different user");

        return;
      }

      if (
        emailExist.data &&
        emailExist.data.data.exist &&
        phoneExist.data &&
        phoneExist.data.data.exist
      ) {
        const recipientInfo = state?.prefetchedUser?.recipient;

        if (!recipientInfo.phone) {
          const user = {
            ...state.prefetchedUser,
            recipient: {
              ...state.prefetchedUser.recipient,
              email: values.email,
              phone: values.phone,
              first_name: values.firstName,
              last_name: values.lastName,
            },
          };

          savePrefetchedUser(user);
        }
        
        setLoading(false);
        showErrorMessage("Account exists. Redirected to sign in page");
        navigate(`/${K.ROUTES.login}${search}`);

        return;
      }
    }

    const res: any = await handleInitVerification(values?.phone!);

    setLoading(false);
    if (res.success) switchToVerification();
  }
  function updateResendTokenOnSuccessfulVerification() {
    let currentuser = localStorage.getItem("currentLogin");
    const item: any = localStorage.getItem("resendtoken");
    const resentToken = JSON.parse(item);

    let name: any = currentuser;
    const { [name]: removedProperty, ...others } = resentToken;
    localStorage.setItem("resendtoken", JSON.stringify(others));
  }

  async function handleSubmitVerification(value: string) {
    // confirm verification token
    const verificationResponse = await verifyToken({
      emailPhone: formatPhoneNumberToExtension(
        registrationData.personalInfo?.phone!
      ),
      token: value,
    });
    if (
      verificationResponse.error ||
      (verificationResponse.data && !verificationResponse.data.data)
    ) {
      showErrorMessage(
        verificationResponse.message ??
          "Couldn't verify token. Try again later "
      );
      return Promise.resolve();
    }
    updateResendTokenOnSuccessfulVerification();
    const data = state.prefetchedUser;

    if (
      data &&
      data.recipient &&
      data.recipient.address &&
      data.recipient.address.state
    ) {
      switchToPin();
    } else {
      switchToAddress();
    }
  }

  function onAddressSubmit(values: any, prevData: any) {
    // setLoading(true);
    prevData.addresses[0] = values;
    console.log(prevData);
    setRegistrationData({
      personalInfo: prevData,
      pin: "",
    });

    switchToPin();
  }

  async function onPinSubmit(values: any) {
    setLoading(true);
    setRegistrationData({ ...registrationData, pin: values });

    // register the user here
    const res: any = await handleLogin(values);
    if (!res?.success) {
      setShowProcessing(null);
      setLoading(false);
      let err = "Couldn't authenticate user";
      if (res?.errorMessage) {
        err = res?.errorMessage;
      }
      showErrorMessage(err);
      return;
    }
    setShowProcessing({
      value: 3.5,
      content: "Hang on! Taking you to connect your bank account",
    });
    const token = res.data;
    sessionStorage.setItem("tokens", JSON.stringify(token));
    // let url = new URLSearchParams(search).get("continue_url");
    sessionStorage.setItem("new_user", "1");

    const merchant_accepts_card = state.prefetchedUser?.channels?.includes("card")
    const merchant_accepts_bank = state.prefetchedUser?.channels?.includes("bank")

    if (!merchant_accepts_card){
      // go to finicity
      return await handleFinicityUrl();
    }

    if (!merchant_accepts_bank){
      // go to add card
      return goToAddCard();
    }

    return goToPaymentOptions()
  }

  
  async function goToPaymentOptions() {
    let url = new URLSearchParams(search).get("continue_url")||"";
    console.log("payment-methods", url);
    navigate(`/${K.ROUTES.paymentMethod}?continue_url=${url}`);
  }

  async function goToAddCard() {
    let url = new URLSearchParams(search).get("continue_url")||"";
    console.log("payment-methods", url);
    navigate(`/${K.ROUTES.onboardCard}?continue_url=${url}`);
  }

  async function handleFinicityUrl() {
    setLoading(true);
    const ready = await waitForReadiness()
    if (!ready) return
    let url = new URLSearchParams(search).get("continue_url");
    const finresponse = await getFinicityUrl();
    if (finresponse.error) {
      setLoading(false);
      setShowProcessing(null);
      // show finicity error
      setShowBankError(true);
      return;
    }

    sessionStorage.setItem(K.STORAGE_KEYS.WAFI_FIN_URL, finresponse.data.link);
    setLoading(false);
    switchToFinicity(`?continue_url=${url ?? ""}`);
  }

  async function handleLogin(pin: string) {
    setLoading(true);
    const regData = formatRegistrationData({
      ...registrationData,
      pin: pin,
    });

    const registrationResponse = await register(regData);
    setShowProcessing({
      value: 1,
      content: "Creating your account",
    });
    if (registrationResponse.error || !registrationResponse.data) {
      setShowProcessing(null);
      showErrorMessage(
        registrationResponse.message ?? "Couldn't register user "
      );
      setLoading(false);
      return { success: false, errorMessage: registrationResponse.message };
    }
    setShowProcessing({
      value: 2,
      content: "Account created, authenticating you into WAFI",
    });
    showSuccessMessage("User created successfully");

    const tokenData = {
      access_token: registrationResponse.data.access,
      refresh_token: registrationResponse.data.refresh,
    };
    console.log(state, state.rememberDevice);
    await saveLoginData(tokenData, state.rememberDevice);

    setLoading(false);
    return { success: true, data: tokenData };
  }

  return {
    page,
    setPageType,
    switchToAddress,
    switchToVerification,
    switchToPin,
    handleSignIn,
    switchToPersonalInfo,
    switchToPayment,
    registrationData,
    setRegistrationData,
    onPersonalInfoSubmit,
    onPinSubmit,
    handleSubmitVerification,
    loading,
    handleInitVerification,
    prefetched,
    token,
    showProcessing,
    handleFinicityUrl,
    showBankError,
    onAddressSubmit,
    changephoneNo,
  };
}

export default useRegistration;
