import Constants from "../_shared/constants";

const K = {
  ...Constants,

  FINICITY: {
    JS_SCRIPT:
      "https://connect2.finicity.com/assets/sdk/finicity-connect.min.js",
  },
};

export default K;
