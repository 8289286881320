import K from "../constants";
import { OperationMode } from "../@types";

export function getOperationMode(): OperationMode {
  const host = window.location.hostname;
  if (host.includes("localhost")) {
    return "local";
  }
  if (host.includes("dev")) {
    return "dev";
  }
  if (host.includes("sandbox-uat")) {
    return "sandbox-uat";
  }
  if (host.includes("sandbox")) {
    return "sandbox";
  }
  if (host.includes("uat")) {
    return "uat";
  }
  return "production";
}

export function getBaseApiUrl() {
  return K.BASE_API_URL
}
