/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { Action } from "redux";
import { ActionTypes } from "../actions";

type IAction = Action & { payload: any };

export function dispatchState<S>(
  data: Partial<S>,
  actionTypeKey: keyof typeof ActionTypes
) {
  return {
    type: ActionTypes[actionTypeKey],
    payload: data,
  };
}

export default function buildReducer<S>(
  initialState: S,
  actionTypeKey: keyof typeof ActionTypes
) {
  return function (state = initialState, action: IAction) {
    switch (action.type) {
      case ActionTypes[actionTypeKey]:
        return { ...state, ...action.payload };

      case ActionTypes.LOG_OUT:
        return initialState;

      default:
        return state;
    }
  };
}
