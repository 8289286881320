import Constants from "../../../constants";
import styled from "styled-components";

export const ConnectedBankAccountsWrapper = styled("div")`
  /* UI Properties */
  // background: #e3e0e0 0% 0% no-repeat padding-box;
  // border-radius: 10px;
  opacity: 1;
  // padding: 20px 10px;

  .ui.checkbox label {
    // text-align: left;
    font: normal normal normal 16px/24px Cochin;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
  }

  .mfull-width {
    width: 100% !important;
  }

  .field {
    margin: 0px !important;
  }
`;

export const FieldWrapper = styled("div")`
  display: flex;
  flex-wrap: wrap;

  .flex-wrapper {
    flex: 100%;

    ${Constants.LAYOUT.MEDIA_QUERIES.TABLET_PX} {
      flex: 50%;
    }
  }

  .p-left {
    ${Constants.LAYOUT.MEDIA_QUERIES.TABLET_PX} {
      // padding-left: 0.3em;
    }
  }

  .p-right {
    ${Constants.LAYOUT.MEDIA_QUERIES.TABLET_PX} {
      // padding-right: 0.3em;
    }
  }
`;

export const ConnectedBankAccountText = styled("h4")`
  font: normal normal bold 20px/24px Cochin;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
`;
