import localforage from "localforage";
import { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import Constants from "../../_shared/constants";
import {
  saveAccessToken,
  showErrorMessage,
  getToken,
} from "../../_shared/services";
import K from "../constants";
import { getFinicityUrl } from "../services/finicityService";
import { useUser } from "../../../redux/hooks";

declare const window: any;

function useFinicity() {
  //leverage use reducers when you have the time
  const [finicityUrl, setFinicityUrl] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<boolean>(false);
  const { saveLoginData, userVerified, state } = useUser();

  // const [value, setValue] = useState<number>(1);
  const componentMounted = useRef(false);
  const navigate = useNavigate();
  const { search, pathname } = useLocation();

  function handleErrorCancel() {
    const canPay = new URLSearchParams(search).get("f_s");
    const url = new URLSearchParams(search).get("continue_url");

    if (canPay === "true") {
      (window as any).location.replace(url);
    } else {
      setError(true);
    }
  }

  function handleSuccess() {
    if (sessionStorage.getItem("new_user")) return handleNewUser();

    return handleReturningUser();
  }

  async function handleNewUser() {
    const token = JSON.parse(sessionStorage.getItem("tokens")!);
    if (!token) return;
    let url = new URLSearchParams(search).get("continue_url");

    let exp: any = await getToken(Constants.STORAGE_KEYS.jWT_EXPIRY);
    let RD: any = await getToken("rememberDevice");
    const accessToken = encodeURIComponent(token.access_token);
    const refreshToken = encodeURIComponent(token.refresh_token);
    const expiry = encodeURIComponent(exp);
    // console.log(url, "herurle");
    url = url?.includes("?")
      ? `${url}&A_TK=${accessToken}&R_TK=${refreshToken}&EXP=${expiry}&REM_DEVICE=${RD}`
      : `${url}?A_TK=${accessToken}&R_TK=${refreshToken}&EXP=${expiry}&REM_DEVICE=${RD}`;

    (window as any).location.replace(url);
  }

  const getCleanUrl = function (url: any) {
    return url.replace(/#.*$/, "").replace(/\?.*$/, "");
  };

  async function handleReturningUser() {
    let url = new URLSearchParams(window.location.search).get("continue_url");
    let R_TK = new URLSearchParams(window.location.search).get("R_TK");
    let A_TK = new URLSearchParams(window.location.search).get("A_TK");
    let EXP: any = new URLSearchParams(window.location.search).get("EXP");
    let RD: any = await getToken("rememberDevice");
    if (R_TK) {
      let newUrl = `${getCleanUrl(url)}?A_TK=${encodeURIComponent(
        A_TK!
      )}&R_TK=${encodeURIComponent(R_TK)}&EXP=${encodeURIComponent(
        EXP
      )}&REM_DEVICE=${RD}`;
      (window as any).location.replace(newUrl);
      return;
    }
    (window as any).location.replace(url);
  }

  function loadScript() {
    setLoading(true);

    const script = document.createElement("script");

    script.src = K.FINICITY.JS_SCRIPT;
    script.async = true;
    script.addEventListener("load", async function () {
      setLoading(false);
      // await setValue(5);

      window["finicityConnect"].launch(finicityUrl!, {
        selector: "#connect-container",
        success: (event: any) => {
          // console.log("there success", event);
          handleSuccess();
        },
        cancel: (event: any) => {
          // console.log("there cancel", event);
          handleErrorCancel();
        },
        error: (error: any) => {
          // console.log("there errror", error);
          handleErrorCancel();
        },
      });
    });
    script.addEventListener("error", function (e) {
      // console.log("errro srcipt", e);
      setLoading(false);
    });
    document.body.appendChild(script);

    return script;
  }

  async function getBaseApiUrl() {
    setLoading(true);
    setError(false);
    const res = sessionStorage.getItem(K.STORAGE_KEYS.WAFI_FIN_URL);
    if (res) {
      setFinicityUrl(res);
      componentMounted.current = true;
    } else {
      setError(true);
    }
    setLoading(false);
  }

  async function fetchFinicity() {
    // Location
    // window.location.reload();
    // navigate("/finicity", { replace: true });
    setError(false);
    setLoading(true);

    const res = await getFinicityUrl();
    if (!res.data) {
      setLoading(false);
      setError(true);
      showErrorMessage("Try again later");
      return;
    }
    sessionStorage.setItem(K.STORAGE_KEYS.WAFI_FIN_URL, res.data.link);
    setFinicityUrl(res.data.link);
    setLoading(false);
  }

  const baseUrlCallback = useCallback(getBaseApiUrl, []);
  const loadScriptCallback = useCallback(loadScript, [finicityUrl]);

  async function initializeFinicity() {
    saveAccessToken(search);
    const res = await getFinicityUrl({});
    if (res.data) {
      sessionStorage.setItem(K.STORAGE_KEYS.WAFI_FIN_URL, res.data.link);
    }

    baseUrlCallback();
  }
  useEffect(() => {
    initializeFinicity();
    return () => {
      componentMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (finicityUrl) loadScriptCallback();
  }, [finicityUrl]);

  return {
    loading,
    finicityUrl,
    error,
    fetchFinicity,
    // value,
  };
}

export default useFinicity;
