import React, { useState, useEffect, useRef } from "react";
import { AddressForm } from "@lob/react-address-autocomplete";
import { useJsApiLoader, Autocomplete } from "@react-google-maps/api";
import "./addressForm.css";
import { Form, Icon } from "semantic-ui-react";
import ModalHeader from "../PageContent/ModalHeader";
import WafiImage from "../PageContent/WafiImage";
import {
  Flex,
  _2414Cochin,
  _1620Cochin,
  FormWrapper,
  _2014Cochin,
  ContinueButton,
} from "../../@styles";
import { checkEmpty } from "../../services";
import Countries from "./countries.json"
import {
  getStateSugestions
} from "../../../registration/services/registrationService";



const formatAddress = (data={}) => {
  return {
    primary_line: data.street1 ?? data.primary_line??"",
    secondary_line: data.apartment_no ?? data.secondary_line ?? "",
    city: data.city??"",
    state: data.state??"",
    zip_code: data.postalCode ?? data.zip_code ?? "",
    country: data.country || data.country_code || "US",
  };
};

const libraries = ["places"]

const Address = (props) => {
  const { onContinue, EditInfo } = props;
  const [showAddress, setShowAddress] = useState(false);
  const [stateSugestions, setStateSugestions] = useState([]);
  const [openStateSuggestions, setOpenStateSuggestions] = useState(false);
  const [loading, setIsloading] = useState(true);
  const [formDataNew, setFormDataNew] = useState(() =>
    formatAddress(EditInfo?.addresses[0])
  );

  const setFormAddress = (key, val) => {
    setFormDataNew((formDataNew)=>({ ...formDataNew, [key]: val }));
  };

  const getAddress = (key) => formDataNew[key];

  const setFormValue = (e) => {
    console.log(e, e.target.value, e.target.name)
    setFormDataNew({ ...formDataNew, [e.target.name]: e.target.value });
  };

  const onStateChange = async (e) =>{
    const country_code = formDataNew.country
    const state_prefix = e.target.value
    setFormDataNew({ ...formDataNew, state: state_prefix });
    console.log(state_prefix)
      const res = await getStateSugestions({country_code, state_prefix})
      console.log(res)
      if (res.data && Array.isArray(res?.data)){
        setStateSugestions(res.data)
      }
      setOpenStateSuggestions(true)
  }

  const onStateSelect = (state) =>{
    setFormDataNew({ ...formDataNew, state });
    setOpenStateSuggestions(false)
  }



  const show = (e) => {
    if (!showAddress){
      setShowAddress(true);
      setTimeout(() => {
        const autoCompleteInput = document.getElementsByClassName("pac-target-input")
        if (autoCompleteInput.length > 0){
          autoCompleteInput[0].focus()
          autoCompleteInput[0].value = formDataNew.primary_line
        }
      }, 500);
    }else{
      setShowAddress(false);
    }
  };

  const populateGoogleAddress = (place) => {
    console.log(place)
    let primary_line = ""
    for (let component of place.address_components){
      if (component.types.includes("street_number"))  {primary_line += component.long_name}
      if (component.types.includes("route")) {primary_line = primary_line + " " + component.long_name}
      setFormAddress("primary_line", primary_line)

      if (component.types.includes("locality")) { setFormAddress("city", component.long_name)}
      if (component.types.includes("administrative_area_level_1"))  {setFormAddress("state", component.long_name)}
      if (component.types.includes("country")) {setFormAddress("country", component.short_name)}
      if (component.types.includes("postal_code"))  {setFormAddress("zip_code", component.long_name)}
      show()
     
    }
  }

  useEffect(() => {
    let autoCompleteInput = document.getElementsByClassName("pac-target-input")
    
    var observer = new MutationObserver(function(mutations) {
      mutations.forEach(function(mutation) {
        if (mutation.attributeName === "disabled") {
          autoCompleteInput[0].disabled = false
          autoCompleteInput[0].focus()
        }
      });
    });
    if (autoCompleteInput.length > 0){
      observer.observe(autoCompleteInput[0], {
        attributes: true
      });
}

    if (
      checkEmpty(formDataNew["primary_line"]) &&
      checkEmpty(formDataNew["city"]) &&
      checkEmpty(formDataNew["state"]) &&
      (checkEmpty(formDataNew["zip_code"]) || formDataNew["country"] !== "US")
    ) {
      setIsloading(false);
    } else {
      setIsloading(true);
    }
  }, [formDataNew]);


    const { isLoaded, loadError } = useJsApiLoader({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_PLACES_API_KEY,
        libraries,
    });
console.log(process.env.REACT_APP_GOOGLE_PLACES_API_KEY)
    const [searchResult, setSearchResult] = useState(null)

    function onLoad(autocomplete) {
      setSearchResult(autocomplete);
    }

    function onPlaceChanged() {
      if (searchResult){
        const place = searchResult.getPlace();
        populateGoogleAddress(place)
      }
    }


    const onSumit = ()=>{
      let toBeSubmitted = {...formDataNew}
      // check if the values have state and no state code try to get the state code

      if (!toBeSubmitted.zip_code){
        delete toBeSubmitted["zip_code"]
      }
      if (!toBeSubmitted.postal_code){
        delete toBeSubmitted["postal_code"]
      }

      console.log(toBeSubmitted)
      onContinue(toBeSubmitted, EditInfo)
    }
 

  return (
    <div>
      <ModalHeader rightType="cancel" />

      <WafiImage />

      <Flex className="center-hor margin-top-40">
        <div className="full-width">
          <_2414Cochin color="#000" display="block">
            Provide your address
          </_2414Cochin>
          <_1620Cochin className="margin-top-12" display="block" color="#000">
            We’re required by law to collect certain information that helps us
            know it’s you when you pay with Wafi.
          </_1620Cochin>
        </div>
      </Flex>
      <Form className="margin-top-40">
        <Flex className="center-hor">
          <FormWrapper className="full-width">
            <div className="formSignUp removepadding">

            <div className="FormChildInput add-mb-20" style={{width:"100%"}}>
                    <label>Country</label>
                    <select
                      type="text"
                      id="country"
                      name="country"
                      onChange={setFormValue}
                      value={getAddress("country")}
                      style={{border:"1px solid black"}}
                    >
                      <option value="US" label="United States" selected> United States </option>
                      {Countries.map((x)=>(<option value={x.code} label={x.name} >{x.name}</option>))}
                    </select>
            </div>
            
              {!showAddress ? (
                <div className="FormChildInput add-mb-20">
                  <label>Residential address</label>
                  <input
                    type="text"
                    id="address"
                    onClick={show}
                    name="primary_line"
                    onChange={setFormValue}
                    value={getAddress("primary_line")}
                    />
                </div>
              ):isLoaded?(
                <Autocomplete onLoad={ onLoad}
                 onPlaceChanged={onPlaceChanged}
                 className="FormChildInput add-mb-20"
                 options={
                  {
                  componentRestrictions: { country: formDataNew.country.toLowerCase() },
                  types:  ["address"], 
                  fields: ["address_components", "formatted_address", "geometry"]}
                }
                  >
                    <>
                    <label>Residential address *</label>
                       <input
                          name="primary_line"
                          type="text"
                          onChange={setFormValue}
                          className="form-control"
                          placeholder="Enter Location"
                          />
                          </>
                </Autocomplete>
              ):null}
              <div>
              </div>

              <div className="addressWrapper">
              <div>

              <div style={{flexDirection:"row"}}>
                <div className="FormChildInput add-mb-20 half-input" >
                    <label>Unit/ Apt/ Floor etc.</label>
                    <input
                      type="text"
                      id="address"
                      name="secondary_line"
                      onChange={setFormValue}
                      value={getAddress("secondary_line")}
                      />
                  </div>
                  <div className="FormChildInput add-mb-20 half-input" >
                    <label>City *</label>
                    <input
                      type="text"
                      id="address"
                      name="city"
                      onChange={setFormValue}
                      value={getAddress("city")}
                      />
                  </div>
              </div>

              <div style={{flexDirection:"row"}}>

                <div className="FormChildInput add-mb-20 half-input" style={{position:"relative"}}>
                  <label>State *</label>
                  <input
                    type="text"
                    id="address"
                    name="state"
                    onChange={onStateChange}
                    value={getAddress("state")}
                    />
                    {openStateSuggestions && <div className="state-dropdown">
                      {stateSugestions.map(state => (
                        <div onClick={()=>onStateSelect(state)}>
                          {state}
                        </div>
                      ))}
                    </div> }
                </div>

                <div className="FormChildInput add-mb-20 half-input">
                  <label>Zip/Postal Code *</label>
                  <input
                    type="text"
                    id="address"
                    name="zip_code"
                    onChange={setFormValue}
                    value={getAddress("zip_code")}
                    />
                </div>
              </div>

               


                </div>
                </div>
            </div>
          </FormWrapper>
        </Flex>
        <Flex className="center-hor margin-top-30">
          <ContinueButton
            className="full-width2"
            onClick={onSumit}
            // onClick={(e) => {e.preventDefault(); console.log(formDataNew, EditInfo)}}
            disabled={loading}
            type="submit"
            loading={false}
          >
            <Icon name="lock" inverted={true} />
            <_2014Cochin color="#fff">Continue</_2014Cochin>
          </ContinueButton>
        </Flex>
      </Form>
    </div>
  );
};

export default Address;