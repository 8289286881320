import { Icon } from "semantic-ui-react";
import useConsent from "./hooks/useConsent";
import SectionContainer from "./SectionContainer";
import { SectionWrapper } from "./styles/Consent";
import {
  Flex,
  ContinueButton,
  _2014Cochin,
  _1624Cochin,
} from "../_shared/@styles";
import { showQuit } from "../_shared/services/quitService";
import PageContent from "../_shared/components/PageContent/PageContent";
import ModalHeader from "../_shared/components/PageContent/ModalHeader";
import WafiImage from "../_shared/components/PageContent/WafiImage";
import WafiDescription from "../_shared/components/PageContent/WafiDescription";
import WafiFooter from "../_shared/components/PageContent/WafiFooter";

const Consent: React.FC = () => {
  const { handleSignUp, handleSignIn } = useConsent();

  return (
    <PageContent>
      <>
        <ModalHeader
          leftType="signin"
          handleLeftAction={handleSignIn}
          rightType="cancel"
        />
        <WafiImage />
        <WafiDescription />
        <SectionWrapper className="margin-top-40">
          <SectionContainer
            className="flex-wrapper "
            name="money bill alternate"
            titleText="Earn cashback every time you pay"
            subTitleText="Earn cash back every time and everywhere you pay with Wafi.
                  First time users earn 5% cash back. Earn 0.5% cash back every
                  other time you pay with Wafi."
          />

          <SectionContainer
            className="flex-wrapper"
            name="shield"
            titleText="Your data is always secure"
            subTitleText="Wafi processes and stores your data using bank-level encryption to keep your data safe. We never save or share your bank login credentials with any third party"
          />

          <SectionContainer
            className="flex-wrapper"
            name="protect"
            titleText="Connect once & pay everywhere"
            subTitleText="Once you connect your bank account to Wafi you can use it to pay everywhere Wafi is accepted. You can also track all your transactions using the Wafi app."
          />

          <SectionContainer
            className="flex-wrapper"
            name="refresh"
            titleText="Simple refunds when you need it"
            subTitleText="Request a refund the same way you would for any transaction and once your merchant approves, we’ll issue a refund to the connected account that was charged. Very simple!"
          />
        </SectionWrapper>
        <Flex className="center-hor margin-top-40">
          <ContinueButton
            className="full-width2"
            onClick={handleSignUp}
            disabled={false}
            bg="#000000"
          >
            <Icon name="lock" inverted={true} />
            <_2014Cochin color="#fff">Continue</_2014Cochin>
          </ContinueButton>
        </Flex>
        <Flex className="center-hor margin-top-4">
          <_1624Cochin color="#707070" display="block" alignText="center">
            By clicking “Continue” you agree to our terms of service and privacy
            policy.
          </_1624Cochin>
        </Flex>
        <WafiFooter />
      </>
    </PageContent>
  );
};

export default Consent;
