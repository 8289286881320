import { useEffect, useState } from "react";
import { LoginObject, LoginPages } from "../@types";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  changePin,
  getInitialLogInData,
  initializeResetPin,
  login,
} from "../services";
import {
  clearToken,
  formatPhoneNumberToExtension,
  getToken,
  isAuthenticated,
  nonSecurePost,
  removeSeperators,
  saveAccessToken,
  showErrorMessage,
  showSuccessMessage,
} from "../../_shared/services";
import {
  initializeVerification,
  verifyToken,
} from "../../_shared/components/VerificationForm/services";
import { useUser } from "../../../redux/hooks";
import {
  fetchUserExist,
  getCurrentUserBankAccount,
} from "../../_shared/services/userService";
import K from "../constants";
import { getFinicityUrl } from "../../finicity/services/finicityService";
import localforage from "localforage";
import Constants from "../../_shared/constants";
import useReadiness from "../../_shared/hooks/useReadiness";
import { getCards } from "../../cardForm/services/cardService";


function useLogin() {
  const navigate = useNavigate();
  const { search } = useLocation();
  const {waitForReadiness} = useReadiness() 

  // add a useReducer here to store the states temporarily, you do not need to store it with the main state

  const [page, setPageType] = useState<LoginPages>(LoginPages.PHONE);

  const [loginData, setLoginData] = useState<LoginObject>(
    getInitialLogInData()
  );

  const [resetToken, setResetToken] = useState<string | null>(null);

  const [tokenData, setTokenData] = useState<any>(null);

  const [storeToken, setStoreToken] = useState<any>(null);

  const { saveLoginData, userVerified, state, setSignin } = useUser();
  const [loading, setLoading] = useState<boolean>(false);
  const [changephoneNo, setchangephoneNo] = useState<boolean>(false);

  const [token, setToken] = useState<undefined | string>(undefined);

  const [recipient, setRecipient] = useState<undefined | object>(undefined);
  const [verificationSent, setVerificationSent] = useState<boolean>(false);
  const [loginType, setLogintype] = useState<string>("phone");

  useEffect(() => {
    setRoute();
  }, [state.prefetchedUser]);
  
  const setRoute = async () => {
    const data = state.prefetchedUser;

    let f_a = new URLSearchParams(search).get("f_a");
    if (f_a === "1") {
      // f_a is an instruction from the payment UI telling us to force authentication
      // this means clearing all saved tokens and allowing the user to auth again
      await clearToken();

      if (data && data.recipient && data.recipient.phone) {
        setLoginData({ ...loginData, id: data.recipient.phone });
      } else {
        setLoginData({ ...loginData, id: "" });
      }

      return switchToPhone();
    }

    const isAuth = await isAuthenticated();
    console.log(isAuth);
    if (isAuth === true || isAuth === false) {
      if (isAuth) {
        const accesToken = await getToken(Constants.STORAGE_KEYS.JWT_TOKEN);
        const refreshToken = await getToken(
          Constants.STORAGE_KEYS.REFRESH_TOKEN
        );
        const tokenData = {
          access_token: accesToken,
          refresh_token: refreshToken,
        };

        return switchToPayment(tokenData);
      } else {
        if (!data) return;

        if (state.signin) {
          switchToPhone();
          setSignin(false);
          return;
        }

        if (data && data.recipient.phone) {
          const res = await fetchUserExist({
            phone_number: formatPhoneNumberToExtension(data.recipient.phone),
          });

          if (res.data && res.data.data.exist) {
            setLoginData({ ...loginData, id: data.recipient.phone });
            switchToPin();
            return;
          } else {
            // navigate(`/${Constants.ROUTES.registration}`);
            handleSignUp();
            return;
          }
        }

        // // navigate(`/${Constants.ROUTES.registration}`);
        handleSignUp();
        // switchToPhone();
        return;
      }
    }
  };

  function handleSetLogintype(val: string) {
    setLogintype(val);
  }

  function switchToPin() {
    setPageType(LoginPages.PIN);
  }

  function switchToVerification() {
    setPageType(LoginPages.VERIFICATION);
  }

  function switchToPhone() {
    setPageType(LoginPages.PHONE);
  }

  function switchToResetPin() {
    setPageType(LoginPages.INIT_RESET_PIN);
  }

  function switchToChangePin() {
    setPageType(LoginPages.RESET_PIN);
  }

  function handleSignUp() {
    navigate(`/${K.ROUTES.registration}${search}`);
  }

  async function switchToPayment(token: any) {
    let url = new URLSearchParams(search).get("continue_url");
    const accessToken = encodeURIComponent(token.access_token);
    let RD: any = await getToken("rememberDevice");
    const refreshToken = encodeURIComponent(token.refresh_token);
    const expiry: any = await getToken(Constants.STORAGE_KEYS.jWT_EXPIRY);
    const encodeExpiry = encodeURIComponent(expiry);
    url = url?.includes("?")
      ? `${url}&A_TK=${accessToken}&R_TK=${refreshToken}&EXP=${encodeExpiry}&REM_DEVICE=${RD}`
      : `${url}?A_TK=${accessToken}&R_TK=${refreshToken}&EXP=${encodeExpiry}&REM_DEVICE=${RD}`;

    (window as any).location.replace(url);
  }

  async function getPaymentURL(token: any) {
    let url = new URLSearchParams(search).get("continue_url");
    const accessToken = encodeURIComponent(token.access_token);
    let RD: any = await getToken("rememberDevice");
    const refreshToken = encodeURIComponent(token.refresh_token);
    const expiry: any = getToken(Constants.STORAGE_KEYS.jWT_EXPIRY);
    const encodeExpiry = encodeURIComponent(expiry);
    let paymentUrl = url?.includes("?")
      ? `${url}?r=i&A_TK=${accessToken}&R_TK=${refreshToken}&EXP=${encodeExpiry}&REM_DEVICE=${RD}`
      : `${url}&A_TK=${accessToken}&R_TK=${refreshToken}&EXP=${encodeExpiry}&REM_DEVICE=${RD}`;

    return paymentUrl;
  }

  async function handleSubmitPhoneNumber(value: string) {
    setLoading(true);
    setLoginData({ ...loginData, id: value });

    //get access code
    const accessCode = sessionStorage.getItem(K.ACCESS_CODE.SESSION_TOKEN);
    if (accessCode) {
      const res = await fetchUserExist({
        phone_number: formatPhoneNumberToExtension(value),
      });

      setLoading(false);

      if (res.data && !res.data.data.exist) {
        showSuccessMessage(
          "Account does not exist. Redirected to sign up page"
        );
        navigate(`/${K.ROUTES.registration}${search}`);
      }
    }
    setLoading(false);

    switchToPin();
  }

  async function handleChangePin(value: string) {
    setLoading(true);
    let id =
      loginType === "phone"
        ? formatPhoneNumberToExtension(loginData.id)
        : loginData.id;
    const changePinResponse = await changePin({
      emailOrPhone: id,
      token: resetToken,
      password: value,
      password_confirmation: value,
    });

    setLoading(false);

    if (!changePinResponse.error) {
      showSuccessMessage("Pin changed");
      setLoginData(getInitialLogInData());
      setResetToken(null);
      setTokenData(null);
      switchToPhone();
    } else {
      showErrorMessage("Error changing pin");
    }
  }

  async function handleInitResetPin() {
    setLoading(true);
    let id =
      loginType === "phone"
        ? formatPhoneNumberToExtension(loginData.id)
        : loginData.id;
    const forgotPinResponse = await initializeResetPin({
      emailOrPhone: id,
    });
    setLoading(false);

    if (!forgotPinResponse.error) {
      showSuccessMessage("Verification token sent to your phone");
      return { success: true };
    } else {
      showErrorMessage("Try again later");
      return { error: true };
    }
  }

  async function handleForgotPin() {
    setLoading(true);
    const res = await handleInitResetPin();

    setLoading(false);

    if (res.success) switchToResetPin();
  }

  async function handleForgotPinContinue(value: string) {
    setLoading(true);

    setResetToken(value);
    switchToChangePin();
    setLoading(false);
  }

  async function handleSubmitPin(value: string) {
    // login
    let id =
      loginType === "phone"
        ? formatPhoneNumberToExtension(loginData.id)
        : loginData.id;
    const loginResponse = await login({
      id: id,
      password: value,
    });

    if (loginResponse.error) {
      showErrorMessage(loginResponse.message ?? "Couldn't login");
      return Promise.resolve();
    }
    showSuccessMessage("Sign in successful, sending verification token");

    // send verification email
    const tokenData = {
      access_token: loginResponse.data.access,
      refresh_token: loginResponse.data.refresh,
    };

    setTokenData(tokenData);
    setStoreToken(tokenData);
    const verificationResponse = await handleInitVerification(loginData.id);
    if (verificationResponse.error) {
      showErrorMessage("Invalid token");

      return Promise.resolve();
    }

    switchToVerification();
  }

  const Handletwofactor = async () => {
    let currentuser: any = localStorage.getItem("currentLogin");
    const item: any = localStorage.getItem("resendtoken");
    const resentToken = JSON.parse(item);

    if (resentToken) {
      if (!resentToken[currentuser]) {
        const newVal = {
          [currentuser]: {
            value: 1,
            updated_at: new Date().getTime() + 24 * 60 * 60 * 1000,
          },
        };

        localStorage.setItem(
          "resendtoken",
          JSON.stringify({
            ...resentToken,
            ...newVal,
          })
        );
      } else {
        resentToken[currentuser].value++;
        resentToken[currentuser].updated_at =
          new Date().getTime() + 24 * 60 * 60 * 1000;

        localStorage.setItem("resendtoken", JSON.stringify(resentToken));
      }
    } else {
      const obj = {
        [currentuser]: {
          value: 1,
          updated_at: new Date().getTime() + 24 * 60 * 60 * 1000,
        },
      };
      localStorage.setItem("resendtoken", JSON.stringify(obj));
    }
  };

  async function handleInitVerification(value?: string) {
    localStorage.setItem("currentLogin", value || loginData.id);
    setLoading(true);
    const verificationResponse = await initializeVerification(
      value || loginData.id
    );

    setLoading(false);

    if (verificationResponse.error) {
      showErrorMessage("Couldn't send verification token");
      setVerificationSent(false);
      return { error: true };
    }
    // setToken(verificationResponse.data.data.code);
    Handletwofactor();
    showSuccessMessage(`Verification pin sent to your ${loginType==="phone"?"phone number":"email"}`);
    setVerificationSent(true);
    return { success: true };
  }

  function switchToFinicity(url: string) {
    navigate(`/finicity${url}`);
  }

  function goToPaymentOptions(url: string) {
    console.log(`/${K.ROUTES.paymentMethod}?continue_url=${url}`);
    navigate(`/${K.ROUTES.paymentMethod}?continue_url=${url}`);
  }


  function goToAddCard(url: string) {
    console.log(`/${K.ROUTES.onboardCard}?continue_url=${url}`);
    navigate(`/${K.ROUTES.onboardCard}?continue_url=${url}`);
  }


  async function handleFinicityUrl(url: string) {
    setLoading(true);

    const finresponse = await getFinicityUrl();
    if (finresponse.error) {
      setLoading(false);
      showErrorMessage("We encountered an error, try again later.");
      return;
    }
    sessionStorage.setItem(K.STORAGE_KEYS.WAFI_FIN_URL, finresponse.data.link);
    setLoading(false);
    switchToFinicity(`?continue_url=${url ?? ""}`);
  }

  function updateResendTokenOnSuccessfulVerification() {
    let currentuser = localStorage.getItem("currentLogin");
    const item: any = localStorage.getItem("resendtoken");
    const resentToken = JSON.parse(item);

    let name: any = currentuser;
    const { [name]: removedProperty, ...others } = resentToken;
    localStorage.setItem("resendtoken", JSON.stringify(others));
  }

  async function handleSubmitVerification(value: string) {
    // confirm verification token
    const verificationResponse = await verifyToken({
      emailPhone: loginData.id,
      token: value,
    });

    if (verificationResponse.error || !verificationResponse.data.data) {
      showErrorMessage("Couldn't verify token. Try again later ");
      await userVerified(false);
      return Promise.resolve();
    }
    // await userVerified(true);
    console.log(tokenData);
    updateResendTokenOnSuccessfulVerification();
    await saveLoginData(tokenData, state.rememberDevice);

    const getUserAccount = await getCurrentUserBankAccount();

    const ready = await waitForReadiness()
    if (!ready) return
        
    const userCards = await getCards();
    const merchant_accepts_card = state.prefetchedUser?.channels?.includes("card")
    const merchant_accepts_bank = state.prefetchedUser?.channels?.includes("bank")


    if (!merchant_accepts_card && getUserAccount.data.bank_accounts.length <= 0){
      // go to finicity
      return handleFinicityUrl(await getPaymentURL(storeToken));
    }

    if (!merchant_accepts_bank && userCards.data.length <= 0){
      // go to add card
      return goToAddCard(await getPaymentURL(storeToken));
    }

    //check if user has connected bank account or card
    if (getUserAccount.data.bank_accounts.length > 0 || userCards.data.length > 0) {
      return switchToPayment(tokenData);
    } else {
      return goToPaymentOptions(await getPaymentURL(storeToken));
      
    }
  }

  return {
    page,
    setPageType,
    switchToPin,
    switchToVerification,
    handleSignUp,
    switchToPayment,
    handleSubmitPhoneNumber,
    handleSubmitPin,
    switchToPhone,
    loginData,
    handleSubmitVerification,
    loading,
    handleInitVerification,
    handleForgotPin,
    handleForgotPinContinue,
    handleChangePin,
    handleInitResetPin,
    token,
    changephoneNo,
    verificationSent,
    handleSetLogintype,
    loginType,
  };
}

export default useLogin;
