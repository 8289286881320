import { FlexCenter, WafiAgreement } from "../../@styles"


export const WafiConsent: React.FunctionComponent = () => {
  return(
    <FlexCenter>
        <WafiAgreement>
        By completing this form you also consent to Wafi sending you SMS and email 
        for the purpose of verifying your identity and enabling you to access our services.
         To unsubscribe to SMS reply STOP. Standard Message and data rates may apply.
        </WafiAgreement>
    </FlexCenter>
  ) ;
};

export default WafiConsent;
