import {
  apiGet,
  apiPost,
  checkPhone,
  formatPhoneNumberToExtension,
  getExtension,
  getPhoneNumber,
  nonSecureGet,
  nonSecurePost,
  removeSeperators,
} from "../../_shared/services";
import { NavigateFunction } from "react-router";
import { RegistrationData, PersonalInfoObject, AddressObject } from "../@types";
import K from "../constants";

export const getInitialRegistrationData = (): Partial<RegistrationData> => {
  return {
    pin: undefined,
    personalInfo: undefined,
    // addressInfo: [],
  };
};

export const getInitialPersonalInfo = (
  registrationData?: PersonalInfoObject,
  userId?: any
): PersonalInfoObject => {
  return {
    firstName: registrationData?.firstName ?? "",
    lastName: registrationData?.lastName ?? "",
    email: registrationData?.email ?? "",
    phone:
      userId ??
      (registrationData?.phone && checkPhone(registrationData?.phone)) ??
      "",
    addresses: getInitialAddresses(registrationData?.addresses),
  };
};

export const getInitialAddresses = (addressData: any): AddressObject[] => {
  const address: AddressObject = {
    street1: addressData?.[0]?.street1 ?? "",
    apartment_no: addressData?.[0]?.apartment_no ?? "",
    postalCode: addressData?.[0]?.postalCode ?? "",
    city: addressData?.[0]?.city ?? "",
    state: addressData?.[0]?.state ?? "",
    country: addressData?.[0]?.country ?? "",
  };

  return [address];
};

export const getNewAddress = (): AddressObject => {
  return {
    street1: "",
    apartment_no: "",
    postalCode: "",
    city: "",
    state: "",
    country: "",
  };
};

const getAddressesRegistration = (values: any) => {
  console.log("IN", values);
  const newList = [];
  const addresses = values.personalInfo?.addresses;

  const shipping = {
    is_shipping: true,
    is_default: true,
    street1: addresses[0]?.primary_line || addresses[0]?.street1,
    apartment_no: addresses[0]?.secondary_line || addresses[0]?.apartment_no,
    // postal_code: addresses[0]?.zip_code || addresses[0].postalCode,
    postal_code:
      addresses[0]?.postal_code ||
      addresses[0]?.postalCode ||
      addresses[0]?.zip_code,
    city: addresses[0]?.city,
    country_code: addresses[0]?.country,
    state: addresses[0]?.state,
  };
  newList.push(shipping);
  console.log("OUT", newList);
  return newList;
};

export const formatRegistrationData = (values: Partial<RegistrationData>) => {
  const addresses = getAddressesRegistration(values);
  const result = {
    first_name: values.personalInfo?.firstName,
    last_name: values.personalInfo?.lastName,
    email: values.personalInfo?.email,
    phone_number: formatPhoneNumberToExtension(values.personalInfo?.phone!),
    extension: "+1",
    pin: values.pin,
    addresses,
  };

  return result;
};

export function register(data: any) {
  const url = K.API_URLS.REGISTRATION;
  return nonSecurePost(url, data, { returnDataExpected: true });
}

export function initializeWallet() {
  const url = K.API_URLS.CONNECT_USER_WALLET_INIT;
  return apiPost(url, {}, { returnDataExpected: true });
}

export function getRecipientData(nav?: any) {
  const url = K.API_URLS.GET_RECIPIENT_DATA;
  return nonSecureGet(url, {}, nav);
}

export function getExpressRecipientData(expressId: string, nav?: any) {
  const url = K.API_URLS.GET_EXPRESS_DATA(expressId);
  return nonSecureGet(url, {suppressErrors:true}, nav);
}

export function getRecipientBanklink(nav?: any) {
  const url = K.API_URLS.GET_BANK_LINK;
  return nonSecureGet(url, {}, nav);
}

export function getReadiness(nav?: NavigateFunction) {
  const url = K.API_URLS.CONNECT_USER_READINESS;
  return apiGet(url,  { returnDataExpected: true }, nav);
}

export function getStateSugestions( data: any, nav?: any) {
  const url = K.API_URLS.GET_STATE_SUGGESTIONS;
  return nonSecurePost(url, data, { returnDataExpected: true });
}
