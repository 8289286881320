import { string } from "yup";

const initialStore = {
  version: 3,
  user: {
    authenticated: false,
    verified: false,
    currentUser: null as any,
    prefetchedUser: null as any,
    rememberDevice: true,
    signin: false,
    signup: false,
  },
  settings: {
    themeMode: "dark" as "light" | "dark" | "device",
    cancel: false,
  },
};

export default initialStore;
