import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Button, Icon, Modal } from "semantic-ui-react";
import styled from "styled-components";
import { useUser } from "../../../../redux/hooks";
import { useSettings } from "../../../../redux/hooks/useSettings";
import Constants from "../../constants";
import K from "../../constants";
import { EventEmitter, QuitTransaction, logout, postCancelMessage } from "../../services";

const Quitpayment: React.FC<{}> = () => {
  const [parameters, updateParameters] = useState<any>({} as any);
  const { content } = parameters;
  const history = useNavigate();
  const { state, savePrefetchedUser } = useUser();
  const { updateCancel, cancel } = useSettings();

  useEffect(() => {
    // console.log(parameters);
  }, [parameters]);

  useEffect(() => {
    const eventId = EventEmitter.subscribe(
      K.EVENTS.SHOW_CANCEL,
      updateParameters
    );
    return () => EventEmitter.unsubscribe(K.EVENTS.SHOW_CANCEL, eventId);
  }, []);

  function hide() {
    updateParameters({});
  }

 async function close() {
  if(state.prefetchedUser && state.prefetchedUser.id && state.prefetchedUser.client_id){
    await QuitTransaction(state.prefetchedUser.client_id, state.prefetchedUser.id)
  }
    if (state.prefetchedUser && state.prefetchedUser.cancel_callback_url) {

      window.location.replace(state.prefetchedUser.cancel_callback_url);

      savePrefetchedUser(null);
      logout();
    }else{
      savePrefetchedUser(null);
      hide();
      history(`/`);
    }
    

  }

  return (
    <QuitModal open={!!content} onClose={hide}>
      <Header>
        <div className="left-text">Quit</div>

        <div className="right-text" onClick={hide}>
          Cancel
        </div>
      </Header>
      <QuitBody>Quit</QuitBody>
      <QuitSubtext>
        Are you sure you want to quit making a payment and return to{" "}
        {sessionStorage.getItem(Constants.CLIENT_NAME.SESSION_TOKEN) ||
          "WAFI merchant"}{" "}
        ?
      </QuitSubtext>
      <QuitButton>
        <Button onClick={close}>
          Quit payment now
          {/* Quit and return to{" "}
          {sessionStorage.getItem(Constants.CLIENT_NAME.SESSION_TOKEN) ||
            "WAFI merchant"} */}
        </Button>
      </QuitButton>
    </QuitModal>
  );
};

const QuitModal = styled(Modal)`
  &&.ui.modal {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    opacity: 1;
    width: 30% !important;
    padding: 23px 0px;

    ${K.LAYOUT.MEDIA_QUERIES.MOBILE} {
      width: 45% !important;
    }
    ${K.LAYOUT.MEDIA_QUERIES.SMALL_PX} {
      width: 90% !important;
    }
  }
`;

const Header = styled("div")`
  display: flex;
  padding: 0px 20px 20px 20px;
  border-bottom: 1.5px solid #000;
  width: 100%;
  align-items: center;
  .left-text {
    font: normal normal bold 16px/19px Cochin;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
  }

  .right-text {
    margin-left: auto;
    font: normal normal normal 16px/18px Cochin;
    letter-spacing: 0px;
    color: #707070;
    opacity: 1;
    cursor: pointer;
  }

  > div {
  }
`;

const QuitBody = styled.div`
  width: 100%;
  text-align: center;
  font: normal normal bold 20px/23px Cochin;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  padding: 50px 20px 30px 20px;
`;

const QuitSubtext = styled.div`
  font: normal normal normal 16px/20px Cochin;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  padding: 0px 20px 20px 20px;
  text-align: center;
`;

const QuitButton = styled("div")`
  background: transparent 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  padding: 10px 20px 20px 20px;

  &&.ui.button{
    width: 100%;
    background: #000 !important;
    color: #fff !important;
    height: 50px;
    border-radius: 5px;
  }

  .button {
    width: 100%;
    background: #000 !important;
    color: #fff !important;
    height: 50px;
    border-radius: 5px;
  }
`;

export default Quitpayment;
