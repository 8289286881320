// import React, { Fragment, useEffect } from "react";
// import { Icon, Modal } from "semantic-ui-react";
// import WafiIcon from "../../../_shared/assets/wafi.png";

// import {

//   ModalWrapper,
//   ModalActionWrapper,
//   Flex,

//   CancelText,

// } from "./styles/learnmore";



// interface IAppProps {
//     show?: boolean;
//     setWafiInfo: React.Dispatch<React.SetStateAction<boolean>>
// }

// const LearnMoree: React.FunctionComponent<IAppProps> = (props) => {
//     const { setWafiInfo, show} = props;



//   return (
//     <Fragment>
//       <ModalWrapper >
//         <div className="wafiModal"></div>
//         <Modal.Header>
//           <Flex className="center">
//           <img src={WafiIcon} alt=""/>
//             <CancelText onClick={()=>setWafiInfo(!show)}>&times;</CancelText>
//           </Flex>
//         </Modal.Header>
//         <Modal.Content>
// <p>Hello</p>
//         </Modal.Content>
//         <ModalActionWrapper>
//         <ContinueButton
//             className="full-width2"
//             // onClick={handleSubmit}
          
//             type="submit"
           
//           >
//             <Icon name="lock" inverted={true} />
//             <_2014Cochin color="#fff">Continue</_2014Cochin>
//           </ContinueButton>
//         </ModalActionWrapper>
//       </ModalWrapper>
//     </Fragment>
//   );
// };

// export default LearnMoree;

import * as React from 'react';
import {LearnMoreWrapper} from './styles/learnmore';
import WafiIcon from "../../../_shared/assets/wafi.png";
import {
    _2014Cochin,

    ContinueButton,

  } from "../../../_shared/@styles";
import Security_icon from "../../../_shared/assets/Security_icon.png";
import Cashbackicon from "../../../_shared/assets/Cashbackicon.png";
import Connectbankicon from "../../../_shared/assets/Connectbankicon.png";
import RefundIcon from "../../../_shared/assets/Refundicon.png";
import { Icon } from 'semantic-ui-react';


interface IAppProps {
    show?: boolean;
    setWafiInfo: React.Dispatch<React.SetStateAction<boolean>>
}

const LearnMoree: React.FunctionComponent<IAppProps> = (props) => {
    const { setWafiInfo, show} = props;

    const listContent = [
        {"text" : "Connect your bank account securely" },
        {"text" : "Pay easily with one click everywhere" },
        {"text" : "Earn rewards and access exclusive promotions" },
        {"text" : "Your data is always securely encrypted" },
    ]
  return(
    <LearnMoreWrapper>
 
        <div id="mywafiModal" className="wafiModal">

            <div className="wafiModal-content">
                <div className="wafiModal-header">
                 
                   <div className='wafilogo'>  <img src={WafiIcon} alt=""/></div>
                 
                    <p className="close" onClick={()=>setWafiInfo(!show)}>
                        &times;</p>
            
           
                 
                       
                  
                 
                </div>

                <div className="wafiModal-body">
                    <div className="full-width2">
                        <p className='maintext'>
                        Wafi is a secure way to pay with your bank account and earn rewards and promotions.
                        </p>
                    {listContent.map((t) => 
                 <div className="text"><span> <Icon name = "meanpath"/></span>{t.text}</div>
                    )}
                       
                   
                    </div>
                    <div>
                    <ContinueButton
            className="full-width2 margin-top-40"
            onClick={()=>setWafiInfo(!show)}
          
            type="submit"
           
          >
            <Icon name="lock" inverted={true} />
            <_2014Cochin color="#fff">Continue</_2014Cochin>
          </ContinueButton>
                    </div>
           

                </div>
            </div>
            
        </div>
           
    </LearnMoreWrapper>
  ) ;
};

export default LearnMoree;

