import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import { getRecipientBanklink } from "../../registration/services";
import K from "../constants";

declare const window: any;

function useFastFinicity() {
  //leverage use reducers when you have the time
  const [finicityUrl, setFinicityUrl] = useState<string | null>(null);
  const [step, setstep] = useState<number | 0>(0);
  const [logo, setLogo] = useState<string | null>(null);
  const [client_name, setClientName] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [transaction_id, setTransactionId] = useState<any>();
  const [error, setError] = useState<boolean>(false);
  const { id } = useParams();

  async function getData() {
    setLoading(true);
    const res = await getRecipientBanklink();
    
    if (!res.success){ 
      setLoading(false);
      return;
    };

    setFinicityUrl(res.data["authorization_url"]);
    setLogo(res.data["image_url"]);
    setClientName(res.data["client_name"]);
    setTransactionId(res.data["id"])

    //session store the callback_url
    res.data?.success_callback_url &&
     sessionStorage.setItem(K.STORAGE_KEYS.SUCCESS_CALLBACK_URL, res.data?.success_callback_url);

    res.data?.cancel_callback_url &&
     sessionStorage.setItem(K.STORAGE_KEYS.CANCEL_CALLBACK_URL, res.data?.cancel_callback_url);

    sessionStorage.setItem(K.STORAGE_KEYS.CONNECTID, res.data?.id);
    setLoading(false);
  }

  function handleCustomMessage(event:any){
      const message = JSON.parse(JSON.stringify(event?.data));
      if (message?.name === "CONNECTBANK"){
          handleConnectBank(message?.link)
      }

      if (message?.name === "CANCEL"){
          handleErrorCancel()
      }
          
      return;
  }

  function handleErrorCancel() {
    //fire an event to the application
    if(window?.ReactNativeWebView){
      broadcastMessage(
        {name: "CANCEL"}
      )
      return;
    }

    if(sessionStorage.getItem(K.STORAGE_KEYS.CANCEL_CALLBACK_URL)){
      //append the connection id and user_id
      window.location.replace(sessionStorage.getItem(K.STORAGE_KEYS.CANCEL_CALLBACK_URL))
      return;
    }
  }

  function handleSuccess() {
    //Display our connection success, then fire success event

    //fire success event 
    if(window?.ReactNativeWebView){
      broadcastMessage(
          {name: "SUCCESS"}
      )
      return;
    }

    const success_callback = sessionStorage.getItem(K.STORAGE_KEYS.SUCCESS_CALLBACK_URL);
    const connectionId = sessionStorage.getItem(K.STORAGE_KEYS.CONNECTID);
    if(success_callback){
      //append the connection id and user_id
      window.location.replace(`${success_callback}?reference=${connectionId}`)

      return;
    }

  }

  const handleInit = async() => {
    if (finicityUrl){
        setstep(1)
        loadScriptCallback()
      };
    return;
  }

  const broadcastMessage = async(message:any) => {
    //fire initialize event to the app
    const stringifyMessage = JSON.stringify(message)
    if(window?.ReactNativeWebView){
        window.ReactNativeWebView.postMessage(
          stringifyMessage
        )
        return;
    }
  }

  function handleConnectBank(link:string) {
    //listens for event 
    setFinicityUrl(link);
    setLoading(false);
    return;
  }

  async function fetchFinicity(url:string) {
    setError(false);
    setLoading(true);

    //fire event to get finicity url
    setFinicityUrl(url)
  }

  function loadScript() {
    setLoading(true);

    const script = document.createElement("script");

    script.src = K.FINICITY.JS_SCRIPT;
    script.async = true;
    script.addEventListener("load", async function () {
      setLoading(false);

      window["finicityConnect"].launch(finicityUrl!, {
        selector: "#connect-container",
        success: (event: any) => {
          // console.log("there success", event);
          handleSuccess();
        },
        cancel: (event: any) => {
          // console.log("there cancel", event);
          handleErrorCancel();
        },
        error: (error: any) => {
          // console.log("there errror", error);
          setstep(2)
        },
      });
    });
    script.addEventListener("error", function (e) {
      // console.log("errro srcipt", e);
      setLoading(false);
    });
    document.body.appendChild(script);

    return script;
  }

  const loadScriptCallback = useCallback(loadScript, [finicityUrl]);

  useEffect(() => {
      window.addEventListener('message', handleCustomMessage)

      return() =>{
          window.removeEventlistner('message', handleCustomMessage)
      }
  }, []);

  useEffect(() => {
    if(id){
      sessionStorage.setItem(
        K.ACCESS_CODE.SESSION_TOKEN,
        id
      );
      getData()
    }

}, []);

  return {
    loading,
    finicityUrl,
    error,
    setFinicityUrl,
    fetchFinicity,
    handleInit,
    logo,
    client_name,
    step,
    handleErrorCancel,
    // value,
  };
}

export default useFastFinicity;
