import { Button } from "semantic-ui-react";
import styled from "styled-components";
import Constants from "../../../constants";

export const Flex = styled("div")`
  display: flex;

  .margin-left-auto {
    margin-left: auto !important;
  }

  .margin-right-auto {
    margin-right: auto !important;
  }
`;

// modal-like wrapper
export const BoxWrapper = styled("div")`
  width: 100%;
  margin: auto;
  padding: 15px 25px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;

  .bank-icon {
    height: 20px;
    width: 14px;
  }

  .cancel {
    width: 16px !important;
    height: 16px !important;
    font-size: inherit !important;
  }

  .pointer {
    cursor: pointer;
    :hover {
      transform: scale(1.2);
    }
  }

  .pinfield {
    input {
      text-align: center !important;
      letter-spacing: 14.4px !important;
    }
  }

  // ${Constants.LAYOUT.MEDIA_QUERIES.TABLET_PX} {
  //   width: 60%;
  // }

  .center {
    align-items: center;
    // text-align: center;
  }

  .center-hor {
    justify-content: center;
  }

  .full-width {
    width: 100% !important;
  }

  .full-width2 {
    width: 100%;

    ${Constants.LAYOUT.MEDIA_QUERIES.TABLET_PX} {
      width: 100%;
    }
  }

  .margin-top-70 {
    margin-top: 70px !important;
  }

  .margin-top-40 {
    margin-top: 40px !important;
  }

  .margin-top-19 {
    margin-top: 19px !important;
  }

  .margin-top-12 {
    margin-top: 12px !important;
  }
`;

export const WafiText = styled("h4")`
  font: normal normal bold 20px/24px Kefa;
  text-align: left;
  color: #000000;
  opacity: 1;
  padding-left: 12px;
  letter-spacing: 4px;
  margin: 0px;

  ${Constants.LAYOUT.MEDIA_QUERIES.TABLET_PX} {
    font: normal normal bold 28px/34px Kefa;
    letter-spacing: 5.6px;
  }
`;

export const ContinueButton = styled(Button)`
  background: #707070 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 3px 6px #00000029 !important;
  border-radius: 5px !important;
  opacity: 1;
`;

export const ContinueText = styled("span")`
  text-align: left;
  font: normal normal bold 20px/14px Cochin;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
`;

export const NoAccountText = styled("h4")`
  // text-align: left;
  font: normal normal normal 16px/18px Cochin;
  letter-spacing: 0px;
  color: #2c39d1;
  opacity: 1;
`;

export const ErrorWrapper = styled("div")`
  background: #f89494 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  text-align: left;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  padding: 1.2em;
  font: normal normal normal 16px/20px Cochin;
`;

export const ConnectedBanksWrapper = styled("div")`
  background: #e3e0e0 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  padding: 1em 2em;
`;

export const ConnectedBankText = styled("h4")`
  font: normal normal bold 18px/21px Cochin;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin: 0px;
`;

export const ConnectedBankAccountsWrapper = styled("div")`
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 2px #00000029;
  border-radius: 10px;
  opacity: 1;
  padding: 1.3em;
`;

export const BankText = styled("h4")`
  // text-align: left;
  font: normal normal normal 16px/18px Cochin;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
  margin: 0px;
  display: none;

  ${Constants.LAYOUT.MEDIA_QUERIES.TABLET_PX} {
    display: block;
  }
`;

export const AmountText = styled("h4")`
  text-align: right;
  font: normal normal normal 18px/21px Cochin;
  letter-spacing: 0px;
  color: #2c39d1;
  margin: 0px;
  margin-left: 38px;

  ${Constants.LAYOUT.MEDIA_QUERIES.TABLET_PX} {
    margin-left: 0px;
  }
`;

export const AccountWrapper = styled("div")`
display: flex;
flex-wrap: wrap;
background: #e3e0e0 0% 0% no-repeat padding-box;
border-radius: 10px;
opacity: 1;
padding 1em;
`;

export const AccountNumberText = styled("h4")`
  text-align: left;
  font: normal normal normal 16px/18px Cochin;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  padding-left: 4px;
  margin: 0px;
`;

export const BankWrapper = styled("div")`
  width: 36px;
  height: 36px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 40px;
  opacity: 1;
  line-height: 36px;
  vertical-align: middle;
  text-align: center;

  img {
    vertical-align: middle;
  }
`;
