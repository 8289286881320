import Constants from "../../../constants";
import styled from "styled-components";

export const CheckBoxWrapper = styled("div")`
  margin-top: 19px;
  .margin-top-0 {
    margin-top: 0px;
    // padding: 0px;
  }

  .ui.radio.checkbox label:before,
  .ui.radio.checkbox label:after {
    width: 30px !important;
    height: 30px !important;
    margin-left: -13px !important;
    margin-top: -13px !important;
  }

  .circular-icon {
    width: 36px;
    height: 36px;
    /* UI Properties */
    background: #808080;
    border-radius: 40px;
    text-align: center;
    vertical-align: middle;
    line-height: 36px;
    margin-right: 10px;
  }

  .mobile {
    display: block;

    ${Constants.LAYOUT.MEDIA_QUERIES.TABLET_PX} {
      display: none;
    }
  }

  .tab {
    display: none;

    ${Constants.LAYOUT.MEDIA_QUERIES.TABLET_PX} {
      display: block;
    }
  }
`;

export const Flex = styled("div")`
  display: flex;

  .margin-right-auto {
    margin-right: auto !important;
  }

  .margin-left-auto {
    margin-left: auto !important;
  }
`;

export const MainText = styled("h4")`
  text-align: left;
  font: normal normal normal 16px/19px Cochin;
  letter-spacing: 0px;
  color: #000000;
  margin: 0px;
`;
