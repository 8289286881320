import styled from "styled-components";

// place items in the middle
export const BodyWrapper = styled("div")`
  width: 100%;
  box-sizing: border-box;
  // background: black !important;
  background: url("img/Rectangle 1209.png") 0% 0% no-repeat padding-box !important;
  // background: red;
`;

export const FlexWrapper = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
`;


export const PaymentMethodRow = styled("div")`
border: 2px solid black;
border-radius: 5px;
padding: 15px;
display: flex;
flex-grow: 1;
justify-content: space-between;
width: 100%;
margin-top: 10px;
position: relative;
cursor: pointer;

@media (max-width: 768px) {
  padding: 20px;
  }
`;

export const Recommended = styled("div")`
position: absolute;
    background: limegreen;
    padding: 3px 15px;
    right: -4px;
    border-radius: 5px;
    top: -12px;
    font-weight: 700;

      
  @media (max-width: 768px) {
    right: auto;
    left: 0;
  }
    `;
    
    
export const PaymentMethodRowIcon = styled("div")`
background: black;
border-radius: 50%;
display: flex;
align-items: center;
justify-content: center;
margin-right: 10px;
height: 40px;
width: 40px;
`;
