import { StatusBasedError } from "./@types";

const ERRORS: StatusBasedError = {
  1000: "Sorry, something went wrong. We are looking into it.",
  999: "We are having connection problems. Please verify that you have internet access.",
  500: "Something unexpected occurred. Relax, we'll fix it soon",
  401: "Authentication Failed!",
  409: "Duplicate Resource!", // override in local module with a better error message
  452: "You have not yet been added to the system. Please contact an administrator to add you.",
  405: "Invalid invitation.",
  498: "Expired!",
};

export default ERRORS;
