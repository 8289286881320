import { Loading } from "../_shared/components";
// import ModalHeader from "../_shared/components/PageContent/ModalHeader";
import PageContent from "../_shared/components/PageContent/PageContent";
// import WafiDescription from "../_shared/components/PageContent/WafiDescription";
// import WafiFooter from "../_shared/components/PageContent/WafiFooter";
// import WafiImage from "../_shared/components/PageContent/WafiImage";
// import { showQuit } from "../_shared/services/quitService";

export default function _404() {
  return (
    <PageContent>
      <>
        <Loading  active={true}/>

        {/* <ModalHeader rightType="cancel" />
        <WafiImage />
        <WafiDescription />
        <WafiFooter /> */}
      </>
    </PageContent>
  );
}
