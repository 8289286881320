import * as React from "react";
import { LearnInfo } from "../_shared/components/LearnMore/learnInfo";
import PageContent from "../_shared/components/PageContent/PageContent";
import Security_icon from "../_shared/assets/Security_icon.png";
import Connectbankicon from "../_shared/assets/Connectbankicon.png";
import { LearnMoreWrapper } from "../_shared/components/LearnMore/styles/learnMore";
import WafiFooter from "../_shared/components/PageContent/WafiFooter";
import {
  ConnectButton,
  ContinueButton,
  EllipsisLoader,
  Flex,
} from "../registration/styles/form/PersonalInformation";
import { Icon } from "semantic-ui-react";
import { _2014Cochin,_2023Cochin } from "../_shared/@styles";
import WafiIconSmall from "../_shared/assets/wafi-icon.png";
import BankLogo from "../_shared/assets/bankLogo.png";
import ModalHeader from "../_shared/components/PageContent/ModalHeader";

export interface IFastConnectProps {
  setFinicityUrl: React.Dispatch<React.SetStateAction<string | null>>;
  handleInit: () => Promise<void>;
  logo: string | null;
  clientName: string | null;
  handleErrorCancel: () => void;
}

export function FastConnect(props: IFastConnectProps) {
  const { setFinicityUrl, handleInit, logo, clientName, handleErrorCancel } = props;

  return (
    <>
      <ModalHeader rightType="close" handleLeftAction={handleErrorCancel} />

      <Flex className="center-hor margin-top-40">
        
          <img src={logo || BankLogo} alt="wafi-icon" className="wafi-icon-small" />
          <EllipsisLoader>
            <div className="lds-ellipsis">
              <div></div>
              <div></div>
              <div></div>
            </div>
          </EllipsisLoader>
          <img src={WafiIconSmall} alt="wafi-icon" className="wafi-icon-small" />
      
      </Flex>

      <Flex className="center-hor margin-top-30 ">
        <div className="margin-bottom-42 padding-lr-10">
          <_2023Cochin color="#000" display="block">
            {clientName} partners with Wafi to securely process payments
          </_2023Cochin>
        </div>
      </Flex>

      <LearnMoreWrapper>
        <div className="wafiModal-body p-0">
          <LearnInfo
            title="Secure bank connection"
            icon={Security_icon}
            content="Wafi enables you to connect your bank securely. We never safe your login credentials and its not shared with Merchant"
          />

          <LearnInfo
            title="Your data is secure"
            icon={Connectbankicon}
            content="Wafi processes and saves your data using bank-level encryption to keep it safe. Wafi never stores your bank login credentials."
          />
        </div>
      </LearnMoreWrapper>

      <Flex className="center-hor ">
        <ConnectButton
          className="full-width2 margin-top-43"
          onClick={handleInit}
          // disabled={disabled || isSubmitting || loading}
          type="submit"
          loading={false}
        >
          <Icon name="lock" inverted={true} />
          <_2014Cochin color="#fff">Connect bank instantly</_2014Cochin>
        </ConnectButton>
      </Flex>

      <WafiFooter page="fast-connect"/>
    </>
  );
}
