import { NotificationParams } from "../@types";
import K from "../constants";
import { EventEmitter } from "./eventEmitter";

export function dismissQuit() {
  EventEmitter.dispatch(K.EVENTS.DISMISS_CANCEL);
}

export function showQuit(content?: string) {
  EventEmitter.dispatch(K.EVENTS.SHOW_CANCEL, {
    content: content || "",
  } as any);
}
