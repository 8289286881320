import { Icon } from "semantic-ui-react";
import WafiIcon from "../../_shared/assets/wafi-icon.png";
import WafiIconSmall from "../../_shared/assets/wafi-icon-small.png";

import useForm, { FormChangeDataProps } from "../../_shared/hooks/useForm";
import {
  EmailValidationSchema,
  getInitialPhoneData,
  PhoneValidationSchema,
} from "../services";

import {
  Flex,
  ColFlex,
  ContinueText,
  ContinueButton,
  _2414Cochin,
  _1620Cochin,
  _1618Cochin,
  _2014Cochin,
} from "../../_shared/@styles";
import { InputField } from "../../_shared/components";
import {
  checkIfCharacter,
  formatPhoneNumbers,
  removeSeperators,
} from "../../_shared/services";

import ModalHeader from "../../_shared/components/PageContent/ModalHeader";
import WafiFooter from "../../_shared/components/PageContent/WafiFooter";
import WafiImage from "../../_shared/components/PageContent/WafiImage";
import withTimeout from "../../_shared/hooks/useTimeout";
import { useEffect, useState } from "react";
import WafiNotes from "../../_shared/components/PageContent/WafiAgreement";
import { useUser } from "../../../redux/hooks";

interface PhoneProps {
  onContinue: (value: string) => void;
  onLoginOptionChange: (value: string) => void;
  handleSignUp: () => void;
  phoneInfo?: string;
  userId?: string;
  loginType: string;
}

const PhoneForm: React.FC<PhoneProps> = (props) => {
  const {
    onContinue,
    handleSignUp,
    phoneInfo,
    userId,
    loginType,
    onLoginOptionChange,
  } = props;
  const { setSignup, state } = useUser();

  const handleSetSignUp = () => {
    handleSignUp();
    setSignup(true);
  };
  const isPhone = loginType === "phone";
  const isEmail = loginType === "email";

  const form = useForm({
    initialValues: getInitialPhoneData(phoneInfo, userId),

    onSubmit,
    validationSchema: isPhone ? PhoneValidationSchema : EmailValidationSchema,
  });

  const {
    handleSubmit,
    isSubmitting,
    values,
    getError,
    handleBlur,
    handleChange,
    isValid,
    dirty,
    errors,
    setFieldValue,
  } = form;

  const [disable, setDisable] = useState(isValid);

  useEffect(() => {
    values.phone !== "" && setDisable(!isValid);
  }, [isValid, values]);

  function onSubmit() {
    loginType === "phone" ? onContinue(values.phone) : onContinue(values.email);
  }

  const handleKeyDown = async (event: any) => {
    // if (checkIfCharacter(event.target.value)) {
    //   event.preventDefault();
    //   return false;
    // }

    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  async function handlePhoneChange(
    _: React.SyntheticEvent<HTMLElement, Event> | undefined,
    data: FormChangeDataProps
  ) {
    const val = data.value?.toString().replace(/ /g, "");

    if ((_?.nativeEvent as any).inputType === "deleteContentBackward")
      return handleChange(_, data);

    if (val && checkIfCharacter(removeSeperators(val))) return;
    await setFieldValue("phone", formatPhoneNumbers(val));
    return;
  }
  async function handleEmailChange(
    _: React.SyntheticEvent<HTMLElement, Event> | undefined,
    data: FormChangeDataProps
  ) {
    const val = data.value;

    await setFieldValue("email", val);
    return;
  }

  const handleLoginOptionChange = (val: string) => {
    onLoginOptionChange(val);
  };

  return (
    <>
      <ModalHeader
        leftType="signup"
        handleLeftAction={handleSetSignUp}
        rightType="cancel"
      />
      <WafiImage />
      {isPhone && (
        <>
          <Flex className="center-hor margin-top-40">
            <div className="full-width">
              <_2414Cochin color="#000" display="block">
                Enter your phone number
              </_2414Cochin>
              <_1620Cochin
                color="#000"
                display="block"
                className="margin-top-12"
              >
                Please sign in with your phone number to continue
              </_1620Cochin>
            </div>
          </Flex>
          <ColFlex className="center-hor margin-top-70">
            <InputField
              className="full-width2 phonefield"
              name="phone"
              id="phone"
              autocomplete="on"
              error={getError("phone")}
              onChange={handlePhoneChange}
              onBlur={handleBlur}
              value={values.phone}
              placeholder="000-000-0000"
              label=""
              handleKeyPress={handleKeyDown}
              type="phone"
            />
            <p
              className="login-text"
              onClick={() => handleLoginOptionChange("email")}
            >
              Sign in with email instead
            </p>
            {/* <input
              type="password"
              name="password"
              id="password"
              autoComplete="current_password"
              autoFocus
              style={{ display: "none" }}
            />{" "} */}

            {/* for the auto save, this is needed*/}
          </ColFlex>
        </>
      )}
      {isEmail && (
        <>
          {" "}
          <Flex className="center-hor margin-top-40">
            <div className="full-width">
              <_2414Cochin color="#000" display="block">
                Enter your email address
              </_2414Cochin>
              <_1620Cochin
                color="#000"
                display="block"
                className="margin-top-12"
              >
                Please sign in with your email address to continue
              </_1620Cochin>
            </div>
          </Flex>
          <ColFlex className="center-hor margin-top-70">
            <InputField
              className="full-width2 phonefield"
              name="email"
              id="email"
              autocomplete="on"
              error={getError("email")}
              onChange={handleEmailChange}
              onBlur={handleBlur}
              value={values.email}
              placeholder="example@gmail.com"
              label=""
              handleKeyPress={handleKeyDown}
              type="email"
            />
            <p
              className="login-text"
              onClick={() => handleLoginOptionChange("phone")}
            >
              Sign in with phone instead
            </p>
          </ColFlex>
        </>
      )}

      <Flex className="center-hor margin-top-80">
        <ContinueButton
          className="full-width2"
          onClick={handleSubmit}
          type="submit"
          disabled={isSubmitting}
          // disabled={disable || isSubmitting}
          loading={isSubmitting}
        >
          <Icon name="lock" inverted={true} />
          <_2014Cochin color="#fff">Continue</_2014Cochin>
        </ContinueButton>
      </Flex>

      <WafiNotes />

      <WafiFooter
        typeText={"Don’t have a Wafi account? Sign up"}
        handleType={handleSetSignUp}
      />
    </>
  );
};

export default PhoneForm;
