import { useIdleTimer } from "react-idle-timer";
import React, { useEffect, useState } from "react";
import {
  dismissNotifications,
  EventEmitter,
  logout,
  QuitTransaction,
  showErrorMessage,
  showSuccessMessage,
  showTimeout,
  showWarning,
} from "../services";
import { Navigate, useNavigate } from "react-router";
import localforage from "localforage";
import { useUser } from "../../../redux/hooks";
import K from "../../finicity/constants";

declare const window: any;

const withTimeout =
  <P extends object>(Component: React.ComponentType<P>) =>
  (props: P) => {
    const [stop, setStop] = useState<boolean>(false);
    const [promptOpen, setPromptOpen] = useState<boolean>(false);
    const [remaining, setRemaining] = useState<number | null>(null);
    const { state, savePrefetchedUser } = useUser()
    const navigation = useNavigate();

    function close() {   
      if(window?.ReactNativeWebView){
        const stringifyMessage = JSON.stringify({name: "CANCEL"})
          window.ReactNativeWebView.postMessage(
            stringifyMessage
          )
          return;
      }

      if(sessionStorage.getItem(K.STORAGE_KEYS.CANCEL_CALLBACK_URL)){
        window.location.replace(sessionStorage.getItem(K.STORAGE_KEYS.CANCEL_CALLBACK_URL))
        return;
      }

      if (state.prefetchedUser && state.prefetchedUser.cancel_callback_url) {
        window.location.replace(state.prefetchedUser.cancel_callback_url);
        localforage.clear();
      }else{
        savePrefetchedUser(null);
        localforage.clear();
        navigation("/login");
      }
  
    }

    const onIdle = async() => {
      if(state.prefetchedUser && state.prefetchedUser.id && state.prefetchedUser.client_id){
        await QuitTransaction(state.prefetchedUser.client_id, state.prefetchedUser.id)
      }
      setRemaining(null);
      setPromptOpen(false);
      close()
    };

    const onPrompt = () => {
      setPromptOpen(true);
    };

    const onActive = (event: any) => {
      setPromptOpen(false);
    };

    const { getRemainingTime, reset } = useIdleTimer({
      onIdle,
      onActive,
      onPrompt,
      timeout: 1000 * 60 * 2,
      promptTimeout: 1000 * 60 * 3,
      immediateEvents: [],
      debounce: 0,
      throttle: 0,
      // eventsThrottle: 200,
      element: document,
      startOnMount: true,
    });

    function handleActiveTimeout() {
      dismissNotifications();
      setStop(true)
      setRemaining(null);
      setPromptOpen(false);
      
      reset();
      // console.log("KDOIDODIIioi")
    }

    useEffect(() => {
      const interval = setInterval(() => {
        if (promptOpen) {
          setRemaining(Math.ceil(getRemainingTime() / 1000));
        }
      }, 1000);
      return () => {
        clearInterval(interval);
      };
    }, [promptOpen, getRemainingTime]);

    useEffect(() => {
      if (!stop && (promptOpen && remaining))

        showTimeout(
          `Your session will expire in ${remaining} seconds. Click the button below to continue your session.`,
          // `Noticed inactivity - You will be logged out in ${remaining} seconds`,
          handleActiveTimeout
        );
    }, [remaining]);

    if (React.isValidElement(Component))
      return React.cloneElement(Component, props as P);
    return <Component {...(props as P)} />;
  };

export default withTimeout;
