import { Form, Icon } from "semantic-ui-react";
import WafiIcon from "../../_shared/assets/wafi-icon.png";
import WafiIconSmall from "../../_shared/assets/wafi-icon-small.png";
import { AddressFields, InputField } from "../../_shared/components";
import useForm, { FormChangeDataProps } from "../../_shared/hooks/useForm";
import { PersonalInfoObject } from "../@types";
import {
  getInitialPersonalInfo,
  PersonalInfoValidationSchema,
} from "../services";
import { Autocomplete } from "@lob/react-address-autocomplete";

import {
  // Flex,
  FormWrapper,
  // BoxWrapper,
  SignUpButton,
  // WafiText,
  PhoneText,
  PhoneSubText,
  ContinueText,
  // ContinueButton,
  SecureText,
  NoAccountText,
  ManualAddressText,
} from "../styles/form/PersonalInformation";
import {
  BodyWrapper,
  WafiText,
  BoxWrapper,
  Flex,
  FlexWrapper,
  _2414Cochin,
  _1620Cochin,
  _1624Cochin,
  _2014Cochin,
  ContinueButton,
  WafiAgreement,
  FlexCenter,
} from "../../_shared/@styles";
import {
  checkIfCharacter,
  formatPhoneNumberToExtension,
  formatPhoneNumbers,
  removeSeperators,
} from "../../_shared/services";
import { useEffect, useState } from "react";
import { ConditionRenderComponent } from "../../_shared/components/ConditionRender";
import AddressForm from "./AddressForm";
import { showQuit } from "../../_shared/services/quitService";
import ModalHeader from "../../_shared/components/PageContent/ModalHeader";
import WafiImage from "../../_shared/components/PageContent/WafiImage";
import WafiFooter from "../../_shared/components/PageContent/WafiFooter";
import LearnMoree from "../../_shared/components/learnMoree/learnmore";
// import LearnMoree from "../../../_shared/components/LearnMoree/LearnMoree";
import K from "../constants";
import WafiNotes from "../../_shared/components/PageContent/WafiAgreement";
import WafiConsent from "../../_shared/components/PageContent/WafiSMSEMailConsent";
import { useUser } from "../../../redux/hooks";

interface PersonalInfoProps {
  onContinue: (values: any) => void;
  handleSignIn: () => void;
  personalInfo?: PersonalInfoObject;
  loading?: boolean;
  userId?: any;
}

const PersonalInfoForm: React.FC<PersonalInfoProps> = (props) => {
  const { onContinue, handleSignIn, personalInfo, loading, userId } = props;
  const [manualAddress, setManualAddress] = useState<boolean>(false);
  const [mobileScreen, setMobileScreen] = useState<boolean>(false);
  const [wafiInfo, setWafiInfo] = useState<boolean>(false);

  const form: any = useForm({
    initialValues: getInitialPersonalInfo(personalInfo, userId),
    onSubmit,
    validationSchema: PersonalInfoValidationSchema,
    enableReinitialize: true,
    validateOnMount: true,
  });

  const {
    handleSubmit,
    isSubmitting,
    values,
    getError,
    handleBlur,
    handleChange,
    isValid,
    dirty,
    setFieldValue,
    setFieldTouched,
    errors,
  } = form;


  const disabled = personalInfo ? !isValid : !(dirty && isValid);

  async function onSubmit() {
    // store in the general hook
    // move to the next page
    await onContinue({ ...values });
  }

  const handleKeyDown = async (event: any) => {
    if (checkIfCharacter(event.keyCode)) {
      event.preventDefault();
      return false;
    }
    // if (event.key === "Enter") {
    //   handleSubmit();
    // }
  };

  // async function handlePhoneChange(
  //   _: React.SyntheticEvent<HTMLElement, Event> | undefined,
  //   data: FormChangeDataProps
  // ) {
  //   const val = formatPhoneNumbers(data.value?.toString());
  //   console.log(val);
  //   if ((_?.nativeEvent as any).inputType === "deleteContentBackward")
  //     return handleChange(_, data);

  //   await setFieldValue("phone", val);
  //   return;
  // }

  async function handlePhoneChange(
    _: React.SyntheticEvent<HTMLElement, Event> | undefined,
    data: FormChangeDataProps
  ) {
    const val = data.value?.toString().replace(/ /g, "");

    if ((_?.nativeEvent as any).inputType === "deleteContentBackward")
      return handleChange(_, data);

    if (val && checkIfCharacter(removeSeperators(val))) return;
    await setFieldValue("phone", formatPhoneNumbers(val));
    return;
  }

  async function handleSelection(selected: any) {
    // console.log(selected, "check");
    const { value } = selected;
    const name = "addresses[0].";
    setFieldTouched && (await setFieldTouched(`${name}street1`, true));
    setFieldTouched && (await setFieldTouched(`${name}city`, true));
    setFieldTouched && (await setFieldTouched(`${name}state`, true));
    setFieldTouched && (await setFieldTouched(`${name}postalCode`, true));
    setFieldTouched && (await setFieldTouched(`${name}country`, true));

    setFieldValue(`${name}street1`, value.primary_line);
    setFieldValue(`${name}city`, value.city);
    setFieldValue(`${name}state`, value.state);
    setFieldValue(`${name}postalCode`, value.zip_code);
    setFieldValue(`${name}country`, "US");
  }

  const showInfo = () => {
    setWafiInfo(!wafiInfo);
  };

  // useEffect(() =>{
  //   if (window.innerWidth <= 767)
  //   setMobileScreen(true)

  // }, [window.innerHeight])

  const {setSignin, state } = useUser();

  const handleSetSignin =() => {
    setSignin(true)
    handleSignIn()
  }

  return (
    <>
      <ModalHeader
        leftType="signin"
        handleLeftAction={ handleSetSignin }
        rightType="cancel"
      />

      <WafiImage />

      <Flex className="center-hor margin-top-40">
      <ConditionRenderComponent renderIf={wafiInfo} children={<LearnMoree show={wafiInfo} setWafiInfo={setWafiInfo} />} />
        <div className="full-width">
          <_2414Cochin color="#000" display="block">
            Let us meet you
          </_2414Cochin>
          <_1620Cochin className="margin-top-12" display="block" color="#000">
            Enter your first and last name as they appear on your government ID.
            <span className="font-blue cursor-pointer" onClick={showInfo}>
              {" "}
              Learn more.
            </span>
          </_1620Cochin>
        </div>
      </Flex>

      <div className="ui form margin-top-40">
        <Flex className="center-hor">
          <FormWrapper className="full-width">
            <div className="per-row">
              <InputField
                label="First name"
                className="flex-wrapper"
                name="firstName"
                id="first_name"
                error={getError("firstName")}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.firstName}
                // placeholder="Jane"
                autocomplete="name given-name"
                // handleKeyPress={handleKeyDown}
              />

              <InputField
                label="Last name"
                className="flex-wrapper"
                name="lastName"
                id="last_name"
                error={getError("lastName")}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.lastName}
                // placeholder="Doe"
                autocomplete="name family-name"
              />
            </div>
            <div className="per-row">
              <InputField
                label="Email address"
                className="flex-wrapper"
                name="email"
                id="email"
                type="email"
                error={getError("email")}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.email}
                // placeholder="jane@gmail.com"
                autocomplete="email"
                // handleKeyPress={handleKeyDown}
              />

              <InputField
                label="Phone number"
                className="flex-wrapper phonefield"
                name="phone"
                id="phone"
                error={getError("phone")}
                onChange={handlePhoneChange}
                onBlur={handleBlur}
                value={values.phone}
                handleKeyPress={handleKeyDown}
                autocomplete="tel"
              />
            </div>
           

           
          </FormWrapper>
        </Flex>

        <Flex className="center-hor margin-top-30">
          <ContinueButton
            className="full-width2"
            onClick={handleSubmit}
            disabled={disabled || isSubmitting || loading}
            type="submit"
            loading={isSubmitting || loading}
          >
            <Icon name="lock" inverted={true} />
            <_2014Cochin color="#fff">Continue</_2014Cochin>
          </ContinueButton>

        </Flex>
      </div>

   <WafiNotes />
   <WafiConsent />

   
      

      <WafiFooter
        typeText="Already have a Wafi account? Sign in"
        handleType={handleSetSignin}
        page={'signup'}
      />
    </>
  );
};

export default PersonalInfoForm;
