import { useEffect, useState } from "react";
import PageContent from "../_shared/components/PageContent/PageContent";
import { registerProps } from "../_shared/@types";
import { useLocation, useNavigate } from "react-router-dom";
import { Icon,  } from "semantic-ui-react";
import {
  _2414Cochin,
  _1620Cochin,
  Flex,
  _2014Cochin,
  ContinueButton,
} from "../_shared/@styles";
import ModalHeader from "../_shared/components/PageContent/ModalHeader";
import WafiImage from "../_shared/components/PageContent/WafiImage";
import WafiFooter from "../_shared/components/PageContent/WafiFooter";
import K from "../registration/constants";
import { getFinicityUrl } from "../finicity/services/finicityService";
import { PaymentMethodRow, PaymentMethodRowIcon, Recommended } from "./styles/Login";
import cardOptionsImg from "../_shared/assets/cardOptions.png"
import bankOptionsImg from "../_shared/assets/bankOptions.png"

const Login: React.FC<any> = (props) => {

  console.log(props.state)
  let cardAvailable = true
  let bankAvailable = true
  if (props.state?.prefetchedUser){
    cardAvailable = props.state?.prefetchedUser?.channels?.includes("card")
    bankAvailable = props.state?.prefetchedUser?.channels?.includes("bank")
  }

  const navigate = useNavigate();
  const { search } = useLocation();

  const [paymentMethod, setPaymentMethod] = useState("bank")
  const [loading, setLoading] = useState(true)

  useEffect(() => {
  }, []);

  async function handleGotoFinicity() {
    setLoading(true);
    let url = new URLSearchParams(search).get("continue_url");
    const finresponse = await getFinicityUrl();
    if (finresponse.error) {
      setLoading(false);
      return;
    }
    sessionStorage.setItem(K.STORAGE_KEYS.WAFI_FIN_URL, finresponse.data.link);
    setLoading(false);
    navigate(`/finicity?continue_url=${url ?? ""}`);
  }

  async function handleGotoCard() {
    let url = new URLSearchParams(search).get("continue_url");
    navigate(`/onboard-card?continue_url=${url ?? ""}`);

  }

  const handleSubmit = (e:any)=>{
    e.preventDefault(); 
    console.log("CLICKED")
    if (paymentMethod === "bank"){
      // go to finicity
      handleGotoFinicity()
      
    }else{
      // go to card onboard
      handleGotoCard()
    }
  }
  
  return (
    <PageContent>
      <form autoComplete="on">

      <ModalHeader
        leftType={undefined}
        handleLeftAction={()=>{}}
        rightType="cancel"
      />
      <br />
      <WafiImage />
      <Flex className="center-hor margin-top-40">
        <div className="full-width">
          <_2414Cochin display="block" color="#000">
          Select a payment method
          </_2414Cochin>
          <_1620Cochin display="block" color="#000" className="margin-top-12">
          We’ll save your payment method and prefer it the next time you pay.
          </_1620Cochin>
        </div>
      </Flex>

        
        <Flex className="center-hor margin-top-20 flex-col">
          <PaymentMethodRow 
            onClick={bankAvailable ? ()=>setPaymentMethod("bank"): ()=>{}}
            style={{ opacity: bankAvailable ? 1 : 0.4}}
          >
            {bankAvailable && <Recommended>Recommended</Recommended>}
            <div className="flex">
              <PaymentMethodRowIcon>
              <Icon name="university" size="large" className="flex-center m-0" inverted={true} />
              </PaymentMethodRowIcon>
              <div className="flex-col">
                <_1620Cochin color="black">Pay with Bank</_1620Cochin>
                <_1620Cochin color="#2C39D1">Earn 0.75% cash back</_1620Cochin>
              </div>
            </div>

            <div className="flex-center">
              <img height={24} className="no-mobile" style={{marginRight:"10px"}} src={bankOptionsImg} alt="" />
            <input type="radio" className="custom-radio" checked={paymentMethod==="bank"} />
            </div>
          </PaymentMethodRow>

          {!bankAvailable && (
            <div style={{color:"#707070", width:"100%", marginTop:"10px"}}>
              This Merchant does not accept bank
            </div>
          )}

          <PaymentMethodRow 
            style={{ opacity: cardAvailable ? 1 : 0.4}}
            onClick={cardAvailable ? ()=>setPaymentMethod("card"): ()=>{}}
            >
            <div className="flex">
              <PaymentMethodRowIcon>
              <Icon name="credit card" className="flex-center m-0" inverted={true} />
              </PaymentMethodRowIcon>
              <div className="flex-col">
                <_1620Cochin color="black">Pay with Card</_1620Cochin>
                <_1620Cochin color="#8D8D8D">No Wafi cash back</_1620Cochin>
              </div>
            </div>

            <div className="flex-center">
            <img height={22} className="no-mobile" style={{marginRight:"10px"}} src={cardOptionsImg} alt="" />
            <input type="radio" className="custom-radio" checked={paymentMethod==="card"} />
            </div>
          </PaymentMethodRow>

          {!cardAvailable && (
            <div style={{color:"#707070", width:"100%", marginTop:"10px"}}>
              This Merchant does not accept card
            </div>
          )}

        </Flex>

        
        <Flex className="center-hor margin-top-40">
          <ContinueButton
            className="full-width2"
            onClick={handleSubmit}
            disabled={false}
            type="submit"
            loading={false}
          >
            <Icon name="lock" inverted={true} />
            
            <_2014Cochin color="#fff">Continue</_2014Cochin>
          </ContinueButton>
         
      
        </Flex>
        <br />
        <br />
      <WafiFooter
        typeText={undefined}
        handleType={()=>{}}
        page={"signup"}
      />
      </form>
    </PageContent>
  );
};

export default Login;
