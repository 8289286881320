import { Fragment, useState, useEffect, useCallback} from "react";
import { Modal } from "semantic-ui-react";
import {
    Flex, ChangeBankText, ContinueSession, ModalWrapper
  } from "../../@styles";
import { EventEmitter } from "../../services";
import K from "../../constants";


export const SessionExpiry: React.FC = (props) => {
    const [parameters, updateParameters] = useState<any>({} as any);
    const {
        autoHides,
        durationShown,
        content,
        iconColor,
        icon,
        iconSize,
        isDismissable,
        isError,
        isWarning,
        handleActiveTimeout,
      } = parameters;

      const hide = useCallback(() => {
        updateParameters({} as any);
      }, []);

      useEffect(() => {
        if (!autoHides) return;
        const timeout = setTimeout(hide, durationShown);
        return () => clearTimeout(timeout);
      }, [autoHides, durationShown, hide]);

    useEffect(() => {
        const eventId = EventEmitter.subscribe(
            K.EVENTS.SHOW_TIMEOUT,
            updateParameters,
            parameters
        );
        return () => EventEmitter.unsubscribe(K.EVENTS.SHOW_TIMEOUT, eventId);
    }, []);

    useEffect(() => {
        const eventId = EventEmitter.subscribe(K.EVENTS.DISMISS_NOTIFICATION, hide);
        return () =>
          EventEmitter.unsubscribe(K.EVENTS.DISMISS_NOTIFICATION, eventId);
      }, [hide]);

  return (
    <Fragment>
        <ModalWrapper open={!!content} size="small" >
         
                <Flex className="center mg-42">
                    <ChangeBankText className="margin-right-auto">
                        Session expiration warning
                    </ChangeBankText>
                </Flex>
         

            <Modal.Content>
                <p className="mgb-42">{content}</p>
                <ContinueSession
                    fluid
                    onClick={handleActiveTimeout}
                >
                    Continue session
                </ContinueSession>
            </Modal.Content>
        </ModalWrapper>
    </Fragment>
  );
}
