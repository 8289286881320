
export interface ILearnInfoProps {
    title: string;
    icon:string;
    content: string;
}

export function LearnInfo (props: ILearnInfoProps) {
    const {title, icon, content} = props
  return (
    
        <div className="wafi-d-flex wafi-mb-25">
            <div>
                <div className="blackgb">
                    <span className="margin-top-5"><img src={icon} alt="" /></span>                     
                </div>
            </div>

            <div>
                <p className="margin-bottom-6">{title}</p>
                <div className="wafiInfo">
                    {content}                
                </div>
            </div>
        </div>
    

  );
}
