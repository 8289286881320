import { Icon } from "semantic-ui-react";
import WafiIcon from "../../assets/wafi-icon.png";
import WafiIconSmall from "../../assets/wafi-icon-small.png";
import useForm from "../../hooks/useForm";
import { getInitialPin, LoginPinValidationSchema, PinValidationSchema } from "./services";
import { PhoneText, PhoneSubText, BoldHint, HintColor,Footercheckbox } from "./styles/PinForm";
import { useLocation } from "react-router";
import {
  _2414Cochin,
  _1620Cochin,
  Flex,
  _2014Cochin,
  ContinueButton,
} from "../../@styles";
import { InputField } from "../InputField";
import { showQuit } from "../../services/quitService";
import PageContent from "../PageContent/PageContent";
import ModalHeader from "../PageContent/ModalHeader";
import WafiImage from "../PageContent/WafiImage";
import WafiFooter from "../PageContent/WafiFooter";
import { checkIfCharacter } from "../../services";
import Constants from "../../constants";
import { LoginPages } from "../../../login/@types";
import { useState } from "react";
import { useUser } from "../../../../redux/hooks";
interface PinProps {
  onContinue?: (value: any) => void;
  leftAction?: "signup" | "back";
  handleLeftAction?: () => void;
  title: string;
  mainText: string;
  forgotPassword?: boolean;
  handleForgotPassword?: any;
  loading?: boolean;
  id?: string;
  page?: string;

}

const PinForm: React.FC<PinProps> = (props) => {

  const {
    onContinue,
    leftAction,
    handleLeftAction,
    title,
    mainText,
    forgotPassword,
    handleForgotPassword,
    loading,
    page,
    id,

  } = props;
  const { pathname } = useLocation();
  const { state ,setRememberDevice} = useUser();

  const form = useForm({
    initialValues: getInitialPin(),
    onSubmit,
    validationSchema: (pathname.includes(Constants.ROUTES.login) && page !== LoginPages.RESET_PIN )? LoginPinValidationSchema : PinValidationSchema,
  });
  //
  
  const {
    handleSubmit,
    isSubmitting,
    values,
    getError,
    handleBlur,
    handleChange,
    isValid,
    dirty,
  } = form;

  async function onSubmit() {
    await onContinue!(values.pin);
  }

  const handleKeyDown = async (event: any) => {
    if (checkIfCharacter(event.keyCode)) {
      event.preventDefault();
      return false;
    }
    if (event.key === "Enter") {
      handleSubmit();
    }
  };
 function handleRememberDevice (){
  
  setRememberDevice(!state.rememberDevice)


 }

  return (
    <>
      <ModalHeader
        leftType={leftAction}
        handleLeftAction={handleLeftAction}
        rightType="cancel"
      />
      <WafiImage />
      <Flex className="center-hor margin-top-40">
        <div className="full-width">
          <_2414Cochin display="block" color="#000">
            {title}
          </_2414Cochin>
          <_1620Cochin display="block" color="#000" className="margin-top-12">
            {mainText}
          </_1620Cochin>
        </div>
      </Flex>

      <input value={id} name="username" id="username" autoComplete="on" style={{"display":"none"}}/> {/* for the auto save, this is needed*/}
        
        <Flex className="center-hor margin-top-70">
          
          <InputField
            className="full-width2 pinfield"
            id="password"
            name="pin"
            error={getError("pin")}
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.pin}
            placeholder={"000000"}
            label=""
            autocomplete="current_password"
            type="password"
            disabled={loading}
            maxLength={6}
            autoFocus={true}
            handleKeyPress={handleKeyDown}
          />
        </Flex>

        {
          !forgotPassword &&
          <div>
            <BoldHint>
              Note:{" "}
            </BoldHint>

            <HintColor>
              Your pin number should not have more than 2 identical or sequential numbers (e.g 111, 123, 654).
            </HintColor>
          </div>
        }
        
        <Flex className="center-hor margin-top-40">
          <ContinueButton
            className="full-width2"
            onClick={handleSubmit}
            disabled={!(dirty && isValid) || isSubmitting || loading}
            type="submit"
            loading={isSubmitting || loading}
          >
            <Icon name="lock" inverted={true} />
            
            <_2014Cochin color="#fff">Continue</_2014Cochin>
          </ContinueButton>
         
      
        </Flex>
        
       <Footercheckbox><input type="checkbox" name="rememberDevice" id="rememberDevice" checked={state.rememberDevice} defaultChecked = {state.rememberDevice} onChange={handleRememberDevice} />
        <label htmlFor="rememberDevice">Remember this device</label></Footercheckbox>

      
       


      

      <WafiFooter
        typeText={
          leftAction === "signup"
            ? "Don’t have a Wafi account? Sign up"
            : forgotPassword
            ? "Forgot PIN number?"
            : undefined
        }
        handleType={
          leftAction === "signup"
            ? handleLeftAction
            : forgotPassword
            ? handleForgotPassword
            : undefined
        }

        page={leftAction}
      />
    </>
  );
};

export default PinForm;
