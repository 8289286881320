import React, { Children, Fragment, useEffect, useState } from "react";
import logo from "./logo.svg";
import "./App.css";
import { GlobalStyle } from "./global";
import {
  Loading,
  Notification,
  ProtectedRoute,
  ProtectedRouteProps,
  Quitpayment,
} from "./modules/_shared/components";
import { Routes, Route } from "react-router-dom";
import { RouteProps, useLocation, useNavigate} from "react-router";
import { defaultRoutes, protectedRoutes } from "./modules/routing";
import Landing from "./Landing";
import {FastConnect} from "./modules/fast-connect/FastConnect";
import { Page404 } from "./modules/_404";
import { formatPhoneNumberToExtension, isAuthenticated, saveAccessToken } from "./modules/_shared/services";
import { useSettings } from "./redux/hooks/useSettings";
import { useUser } from "./redux/hooks";
import { getRecipientData, getExpressRecipientData } from "./modules/registration/services";
import ReactGA from "react-ga";
import { SessionExpiry } from "./modules/_shared/components/modal/sessionExpiry";
import FastFinicity from "./modules/finicity/FastFinicity";
import withTimeout from "./modules/_shared/hooks/useTimeout";
import Constants from "./modules/_shared/constants";
import localforage from "localforage";
import { fetchUserExist } from "./modules/_shared/services/userService";
import useGA from "./modules/useGA";

function App() {
  // ReactGA.initialize("G-CN2VTS4TQK");
  // // UA-224767839-1
  ReactGA.initialize("UA-224767839-2");
  ReactGA.pageview(window.location.pathname + window.location.search);

  useGA()

  const { search } = useLocation();
  const { updateCancel } = useSettings();
  const { savePrefetchedUser, state, setRememberDevice } = useUser();
  const navigate = useNavigate();
  const [userId, setuserId] = useState('')


  const getExpressIdFromContinueUrl = ()=> {
    const continue_url = new URLSearchParams(search).get("continue_url");
    const continue_url_search = "?" + continue_url?.split("?")[1]
    const express_id = new URLSearchParams(continue_url_search).get("express_id");
    return express_id
  }
  

  useEffect(() => {
 
    async function _() {
      console.log("Hi")
      const token = await saveAccessToken(search);
      
      if (token) {
       
        await _prefetchUser();
        updateCancel(true);
        return;
      }
      savePrefetchedUser(null);
      setRememberDevice(true);
      return updateCancel(false);
    }

    async function _prefetchUser() {
      const access_code = new URLSearchParams(search).get("access_code");

      if (access_code === "express"){
        const express_id = getExpressIdFromContinueUrl() || ""
        const res = await getExpressRecipientData(express_id)
        const hasRecipient = res.data && Object.keys(res.data).length !== 0
        const x =  {recipient: res.data, accepted_card_options: res.data["meta"]["accepted_card_options"]}
        return savePrefetchedUser( hasRecipient ? x : null)
        }
        
      const res = await getRecipientData();
      if (!res.success) return savePrefetchedUser(null);
      savePrefetchedUser(res.data);
    }
    _();
  }, []);

  
  function renderRoute(item: ProtectedRouteProps) {
    return <Route path={item.path!} element={<ProtectedRoute state={state} {...item} />} />;
  }

  function renderDefaultRoute(item: RouteProps) {
    let El: any = item.element!;
    return <Route path={item.path!} element={<El userId={userId} setuserId={setuserId} />} />;
  }

  return (
    <Fragment>
      <Routes>
        {Children.toArray(defaultRoutes.map(renderDefaultRoute))}
        {Children.toArray(protectedRoutes.map(renderRoute))}
        <Route path="/:id" element={<Landing />} />
        <Route path="*" element={<Page404 />} />
        <Route path="/connect-bank/:id" element={<FastFinicity /> } />

      </Routes>
      <Notification />
      <SessionExpiry/>
      <Quitpayment />
    </Fragment>
  );
}

export default withTimeout(App);
