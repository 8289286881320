import { AxiosError } from "axios";
import { NavigateFunction } from "react-router";
import {
  ApiRequestResult,
  IFetchParamsWitUrl,
  IRequestOptions,
} from "../@types";
import { getSecureAxiosInstance, instance } from "./authService";
import { processErrorResponse } from "./errorService";
import {
  dismissNotifications,
  showSuccessMessage,
} from "./notificationService";

export type ApiGet<T> = (
  url: string,
  options?: IRequestOptions,
  nav?: NavigateFunction
) => Promise<ApiRequestResult<T>>;
export async function apiGet<TData = any>(
  url: string,
  options?: IRequestOptions,
  nav?: NavigateFunction
): Promise<ApiRequestResult<TData>> {
  try {
    dismissNotifications();
    const axiosSecure = await getSecureAxiosInstance();
    const response = await axiosSecure.get(url);
    const { data, count } = response.data;
    return { data, success: true, count };
  } catch (err) {
    return processErrorResponse(err as AxiosError, options, nav);
  }
}

export async function nonSecureGet<TData = any>(
  url: string,
  options?: IRequestOptions,
  nav?: NavigateFunction
): Promise<ApiRequestResult<TData>> {
  try {
    dismissNotifications();
    const axiosSecure = await instance("");
    const response = await axiosSecure.get(url);
    const { data, count } = response.data;
    return { data, success: true, count };
  } catch (err) {
    return processErrorResponse(err as AxiosError, options, nav);
  }
}

export async function apiPost<TData = any>(
  url: string,
  data: Object,
  options?: IRequestOptions,
  nav?: NavigateFunction
): Promise<ApiRequestResult<TData>> {
  try {
    dismissNotifications();
    const axiosSecure = await getSecureAxiosInstance();
    const response = await axiosSecure.post(url, { ...data });

    const message = response.data.message;

    if (message && !options?.suppressSuccessMessage)
      showSuccessMessage(message);

    if (options?.returnDataExpected) {
      return { success: true, data: response.data, message };
    }
    return { success: true, message };
  } catch (err) {
    return processErrorResponse(err as AxiosError, options, nav);
  }
}

export async function nonSecurePost<TData = any>(
  url: string,
  data: Object,
  options?: IRequestOptions
): Promise<ApiRequestResult<TData>> {
  try {
    dismissNotifications();
    const axiosSecure = await instance("");
    // const response = await axiosSecure.post(url, { ...data }, { withCredentials: true });
    const response = await axiosSecure.post(url, { ...data });
    const message = response.data.message;

    if (message) showSuccessMessage(message);

    if (options?.returnDataExpected) {
      return { success: true, data: response.data, message };
    }
    return { success: true, message };
  } catch (err) {
    return processErrorResponse(err as AxiosError, options);
  }
}

export async function apiDelete(
  url: string,
  data?: Record<string, any>,
  options?: IRequestOptions,
  nav?: NavigateFunction
): Promise<ApiRequestResult> {
  try {
    dismissNotifications();
    const axiosSecure = await getSecureAxiosInstance();
    await axiosSecure.delete(url, { data: { ...data } });
    return { success: true };
  } catch (err) {
    return processErrorResponse(err as AxiosError, options, nav);
  }
}

export async function apiPut<T = unknown>(
  url: string,
  data?: Record<string, any>,
  options?: IRequestOptions,
  nav?: NavigateFunction
): Promise<ApiRequestResult<T>> {
  try {
    dismissNotifications();
    const axiosSecure = await getSecureAxiosInstance();
    const response = await axiosSecure.put(url, { ...data });
    const message = response.data.message;
    const dataToReturn: ApiRequestResult<T> = {
      success: true,
      message,
      status: response.status,
    };

    if (message) showSuccessMessage(message);
    if (options?.returnDataExpected) dataToReturn.data = response.data.data;

    return dataToReturn;
  } catch (error) {
    return processErrorResponse(error as AxiosError, options, nav);
  }
}

export async function apiPatch(
  url: string,
  data?: Record<string, any>,
  options?: IRequestOptions,
  nav?: NavigateFunction
): Promise<ApiRequestResult> {
  try {
    dismissNotifications();
    const axiosSecure = await getSecureAxiosInstance();
    const response = await axiosSecure.patch(url, { data });
    const message = response.data.message;

    if (message) showSuccessMessage(message);

    return { success: true, message, status: response.status };
  } catch (error) {
    return processErrorResponse(error as AxiosError, options, nav);
  }
}

export function parseGet(val?: object) {
  if (!val) return "";
  return encodeURIComponent(JSON.stringify(val));
}

export function fetchPaginatedData<TData = any>(
  params: IFetchParamsWitUrl,
  starter: string = "?"
): Promise<ApiRequestResult<TData>> {
  let {
    baseUrl: url,
    pageNumber,
    pageSize,
    filters,
    orderBy: orderColumn,
    sort: sortDirection,
    customFilters,
    searchTerm,
    searchDelimiter,
  } = params;

  let op = starter;
  if (pageNumber) {
    url = `${url}${op}pageNumber=${pageNumber}`;
    op = "&";
  }
  if (pageSize) {
    url = `${url}${op}pageSize=${pageSize}`;
    op = "&";
  }
  if (filters?.length) {
    url = `${url}${op}filters=${parseGet(filters)}`;
    op = "&";
  }
  if (orderColumn) {
    url = `${url}${op}orderBy=${orderColumn}`;
    op = "&";
  }
  if (sortDirection) {
    url = `${url}${op}sort=${sortDirection}`;
    op = "&";
  }
  if (customFilters) {
    url = `${url}${op}customFilter=${customFilters}`;
    op = "&";
  }

  if (searchTerm) {
    url = `${url}${op}searchTerm=${searchTerm}`;
    op = "&";
  }

  if (searchTerm && searchDelimiter) {
    url = `${url}${op}searchDelimiter=${searchDelimiter}`;
    op = "&";
  }
  return apiGet<TData>(url);
}
