import Constants from "../constants";
import PhoneNumber from "google-libphonenumber";
import { apiPost, nonSecurePost } from "./apiService";

export function parseStringifiedNumber(number: any, defaultValue?: any) {
  const value: string = number.toString().replace(/[^0-9.-]/g, "");
  if (value === "0-") return "-";
  if (value.endsWith(".") || value.endsWith("-")) return value;
  const parsed = Number(value);
  return isNaN(parsed) ? defaultValue : parsed;
}

export function centsToDollars(value: number) {
  if (!value) return value;
  value = value * 0.01;
  return value.toFixed(2);
}

export function centsToDollarsString(value: number) {
  const dollarValue = centsToDollars(value);
  if (!dollarValue || value >= 0) return `$${dollarValue}`;

  if (value < 0) return `-$${dollarValue.toString().substring(1)}`;
}

// might not be used
export function dollarsToCents(value: number) {
  if (!value) return value;
  return value / 0.01;
}

export const removeSeperators = (value: string) => {
  if (!value) return value;

  let newValue = value.replace(/ /g, "");
  if (newValue.includes("+")) return newValue.substring(2).replace(/\D/g, "");
  return newValue.replace(/\D/g, "");
};

export const getPhoneNumber = (value: string) => {
  value = removeSeperators(value);
  if (!value) return value;

  return value.substring(3);
};

export const getExtension = (value: string) => {
  value = removeSeperators(value);
  if (!value) return value;
  return value.substring(0, 3);
};

export function formatPhoneNumbers(value?: string): string {
  if (!value) return "";

  value = removeSeperators(value);
  let formattedString = "";
  let j = 0;
  for (let i = 0; i < value.length; i++) {
    let charToAppend = value[i];
    if ((j === 3 || j === 7) && charToAppend !== "-") {
      charToAppend = `-${charToAppend}`;
      j++;
    }
    j++;
    formattedString += charToAppend;
  }

  return formattedString.substring(0, 12);
}

export const checkPhone = (value: string): string => {
  const myArray = value.split("");

  if (myArray[0] === "+") {
    myArray.splice(0, 2);
    let numbers = myArray.join("");
    return formatPhoneNumbers(numbers);
  }
  return value;
};

export function last4Numbers(val?: string) {
  if (!val) return val;
  val = removeSeperators(val);
  const len = val.length;
  return val.substring(len - 4, len);
}

export function formatPhoneNumberToExtension(val?: string) {
  if (!val) return "";
  val = removeSeperators(val);
  return val[0] === "+" ? val : `+1${val}`;
}

export function postSuccessMessage(data: any) {
  const publishedData = {
    event: "success",
    data: data,
  };
  window.opener?.postMessage(publishedData, "*");
}

export function postCancelMessage(data: any) {
  const publishedData = {
    event: "cancel",
    data: data,
  };

  window.opener?.postMessage(publishedData, "*");
}

export function postErrorMessage(data: any) {
  const publishedData = {
    event: "error",
    data: data,
  };
  window.opener?.postMessage(publishedData, "*");
}

export function strictValidatePhoneNumber(value: any) {
  value = formatPhoneNumberToExtension(value);
  if (!value || value.length < 10) return true;
  if (value.includes("+")) return true;
  if (value.length > 10) return true;
  const phoneUtil = PhoneNumber.PhoneNumberUtil.getInstance();
  const number = phoneUtil.parseAndKeepRawInput(value, "US");
  return phoneUtil.isValidNumberForRegion(number, "US");
}

export function strictValidateEmail(email: any) {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

export function strictValidatePassword(value: any) {
  //if value is empty
  if (!/\S/.test(value)) {
    return false;
  }

  //check if the difference in the consecutive numbers are not the equal

  let values;
  if (value) {
    values = value.split("");
    if (values.length > 2) {
      for (let i = 2; i < values.length; i++) {
        if (
          values[i] - values[i - 1] === values[i - 1] - values[i - 2] &&
          [-1, 0, 1].includes(values[i] - values[i - 1])
        ) {
          return false;
        }
      }
    }

    if (values.length === 6) {
      return true;
    }
  }

  return false;
}

export function strictValidateNumber(value: any) {
  //check if values are number
  return /^-?\d+$/.test(value);
}

export async function isVOIPNumber(value: any) {
  if (!value || value.length < 12) return true;

  const res = await twilioLookUp(value);
  return true;
}

export async function twilioLookUp(phoneNumber: string) {
  const url = Constants.API_URLS.TWILIO_LOOKUP;
  return nonSecurePost(
    url,
    { phone_number: phoneNumber },
    { returnDataExpected: true }
  );
}

export function checkIfCharacter(value: any) {
  return isNaN(value);
  // return String.fromCharCode(value).match(/[a-zA-Z]/g);
}

export const checkEmpty = (value: string) => {
  if (/\S/.test(value)) {
    return true;
  }

  return false;
};
