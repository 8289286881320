import axios from "axios";
import localforage from "localforage";
import { ReduxState } from "../../../redux/@types";
import K from "../constants";
import { Maybe } from "../@types";
import { apiPost, apiPut } from "./apiService";
import { getBaseApiUrl } from "./generalService";
import { showErrorMessage } from "./notificationService";
import Constants from "../constants";
import { NavigateFunction } from "react-router";

export async function saveAccessToken(search: string) {
  const accessToken = new URLSearchParams(search).get("access_code");
  if (accessToken) {
    sessionStorage.setItem(K.ACCESS_CODE.SESSION_TOKEN, accessToken);
    return accessToken;
  }

  return null;
}
export async function saveToken(token: string) {
  try {
    await localforage.setItem(K.STORAGE_KEYS.JWT_TOKEN, token);
  } catch (e) {}
}

export async function handleVerificationState(value: string) {
  try {
    await sessionStorage.setItem(K.VERIFICATION.SESSION_TOKEN, value);
  } catch (e) {}
}

export async function getVerificationState() {
  try {
    return sessionStorage.getItem(K.VERIFICATION.SESSION_TOKEN);
  } catch (e) {}
}

export async function getToken(tokenName: string): Promise<string | null> {
  try {
    let token = await localforage.getItem(tokenName);
    if (token) {
      return localforage.getItem(tokenName);
    }
    return sessionStorage.getItem(tokenName);
  } catch (error) {
    return null;
  }
}

export async function isAuthenticated() {
  try {
    const jwt = await getToken(K.STORAGE_KEYS.JWT_TOKEN);
    const expiry = await getToken(Constants.STORAGE_KEYS.jWT_EXPIRY);
    const reduxState: Maybe<ReduxState> = await localforage.getItem(
      K.STORAGE_KEYS.REDUX_STATE
    );
    console.log(
      jwt,
      expiry,
      reduxState?.user?.currentUser?.exp,
      new Date((expiry || reduxState?.user?.currentUser?.exp) * 1000),
      new Date(),
      new Date((expiry || reduxState?.user?.currentUser?.exp) * 1000) >
        new Date()
    );
    if (
      jwt &&
      new Date((expiry || reduxState?.user?.currentUser?.exp) * 1000) >
        new Date()
    ) {
      return true;
    }
    // showErrorMessage("You have been logged out");
    return false;
  } catch (error) {
    return false;
  }
}

export async function clearToken(): Promise<null> {
  try {
    await localforage.removeItem(K.STORAGE_KEYS.JWT_TOKEN);
    await localforage.removeItem(K.STORAGE_KEYS.REFRESH_TOKEN);
    await localforage.removeItem(K.STORAGE_KEYS.jWT_EXPIRY);

    sessionStorage.removeItem(K.STORAGE_KEYS.JWT_TOKEN);
    sessionStorage.removeItem(K.STORAGE_KEYS.REFRESH_TOKEN);
    sessionStorage.removeItem(K.STORAGE_KEYS.jWT_EXPIRY);

    return null;
  } catch (error) {
    return null;
  }
}

/**
 * Returns an instance of axios that already contains the API token needed by the server
 * and also contains the base url for the api
 */
export function instance(token: string) {
  return axios.create({
    baseURL: getBaseApiUrl(),
    headers: {
      Authorization: `Bearer ${token}`,
      "Access-Code":
        sessionStorage.getItem(Constants.ACCESS_CODE.SESSION_TOKEN) ?? "",
    },
  });
}

export async function getSecureAxiosInstance() {
  let token: string | null = await getToken(Constants.STORAGE_KEYS.JWT_TOKEN);
  if (!token)
    throw new Error(
      "Attempting to securely connect to api when there is no token!"
    );
  return instance(token);
}

export async function logoutRequest() {
  return apiPost(Constants.API_URLS.LOGOUT, {}, { suppressErrors: true });
}


export async function logout() {
  try {
    await logoutRequest();
    // localforage.clear();
    sessionStorage.clear();
    //
  } catch (error) {}
}

export async function strictLogout() {
  try {
    await logoutRequest();
    localforage.clear();
    sessionStorage.clear();
    //
  } catch (error) {}
}

export function getAccessCode() {
  return sessionStorage.getItem(Constants.ACCESS_CODE.SESSION_TOKEN);
}

export async function QuitTransaction(client_id: string,transaction_id :string) {
  return apiPost(Constants.API_URLS.QUIT_TRANSACTION(client_id,transaction_id), {}, { suppressErrors: true });
}

