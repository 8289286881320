import {
  ConditionRender,
  VerificationForm,
  PinForm,
  InsufficientBalance,
  BankConnection,
} from "../_shared/components";
import { useEffect } from "react";
// import withTimeout from "../_shared/hooks/useTimeout";
import { LoginPages } from "./@types";
import PhoneForm from "./forms/PhoneForm";
import { useLogin } from "./hooks";
import { BodyWrapper, FlexWrapper } from "../_shared/@styles";
import PageContent from "../_shared/components/PageContent/PageContent";
import WafiFooter from "../_shared/components/PageContent/WafiFooter";
import { last4Numbers } from "../_shared/services";
import { registerProps } from "../_shared/@types";

// const ConditionRenderPhone = ConditionRender(InsufficientBalance);
// const ConditionRenderPhone = ConditionRender(BankConnection);
const ConditionRenderPhone = ConditionRender(PhoneForm);

const ConditionRenderPin = ConditionRender(PinForm);
const ConditionRenderVerification = ConditionRender(VerificationForm);

const Login: React.FC<registerProps> = (props) => {
  const { userId, setuserId } = props;
  const {
    page,
    switchToPin,
    handleSubmitVerification,
    handleSignUp,
    switchToPhone,
    loginData,
    handleSubmitPhoneNumber,
    handleSubmitPin,
    loading,
    handleInitVerification,
    handleForgotPin,
    handleForgotPinContinue,
    handleChangePin,
    handleInitResetPin,
    token,
    changephoneNo,
    verificationSent,
    loginType,
    handleSetLogintype,
  } = useLogin();
  useEffect(() => {
    setuserId(loginData.id);
  }, [loginData]);

  return (
    <PageContent>
      <form autoComplete="on">
        <ConditionRenderPhone
          renderIf={page === LoginPages.PHONE}
          onContinue={handleSubmitPhoneNumber}
          handleSignUp={handleSignUp}
          phoneInfo={loginData.id}
          userId={userId}
          loginType={loginType}
          onLoginOptionChange={handleSetLogintype}
        />

        <ConditionRenderPin
          renderIf={page === LoginPages.PIN}
          onContinue={handleSubmitPin}
          leftAction="back"
          handleLeftAction={switchToPhone}
          title="Enter your secure pin"
          mainText="Please enter your secure pin to sign in and complete your payment."
          forgotPassword={true}
          id={loginData.id}
          handleForgotPassword={handleForgotPin}
          loading={loading}
        />
        <ConditionRenderVerification
          renderIf={page === LoginPages.VERIFICATION}
          onContinue={handleSubmitVerification}
          title="Enter verification code"
          mainText={`For your security we sent a six digit code to your ${
            loginType === "phone" ? "phone number ending in " : "email address."
          } `}
          boldText={`${
            loginType === "phone"
              ? "...." + last4Numbers(loginData.id) + "."
              : ""
          }`}
          subText=" Enter the code below to continue."
          leftAction="back"
          handleLeftAction={switchToPin}
          // inputRightAction="Send code to email"
          handleInputRightAction={() => {}}
          loading={loading}
          sendCode={() => {
            handleInitVerification();
          }}
          token={token}
          changephoneNo={changephoneNo}
        />
        <ConditionRenderVerification
          renderIf={page === LoginPages.INIT_RESET_PIN}
          onContinue={handleForgotPinContinue}
          title="Verification"
          mainText={`We sent a code to your ${
            loginType === "phone"
              ? "phone number ending in ....,"
              : "email address,"
          } `}
          subText=" enter it below to continue. The code will expire after 30 minutes."
          boldText={loginType === "phone" ? last4Numbers(loginData.id) : ""}
          cancel={true}
          // inputRightAction="Send code to email"
          handleInputRightAction={() => {}}
          loading={loading}
          sendCode={() => {
            handleInitResetPin();
          }}
          changephoneNo={changephoneNo}
        />
        <ConditionRenderPin
          renderIf={page === LoginPages.RESET_PIN}
          onContinue={handleChangePin}
          leftAction="back"
          handleLeftAction={switchToPhone}
          title="Reset PIN number"
          page={page}
          mainText="Create a new pin to continue and make your payment."
        />
      </form>
    </PageContent>
  );
};

export default Login;
