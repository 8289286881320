import { useEffect } from "react";
import { registerProps } from "../_shared/@types";
import {
  AddressFields,
  BankConnection,
  ConditionRender,
  Loading,
  PinForm,
  VerificationForm,
} from "../_shared/components";
import Addressform from "../_shared/components/AddressForm/AddressFormGoogle";
import { ConditionRenderComponent } from "../_shared/components/ConditionRender";
import PageContent from "../_shared/components/PageContent/PageContent";
import ProcessingModal from "../_shared/components/processing/Processing1";
import withTimeout from "../_shared/hooks/useTimeout";
import { checkPhone, last4Numbers } from "../_shared/services";
import { RegistrationPages } from "./@types";
import PersonalInfoForm from "./form/PersonalInfoForm";
import { useRegistration } from "./hooks";

const ConditionRenderPersonalInfo = ConditionRender(PersonalInfoForm);
const ConditionRenderVerification = ConditionRender(VerificationForm);
const ConditionRenderPin = ConditionRender(PinForm);
const ConditionRenderProcessingModal = ConditionRender(ProcessingModal);
const ConditionRenderBankConnection = ConditionRender(BankConnection);
const ConditionRenderAddress = ConditionRender(Addressform);

const Registration: React.FC<registerProps> = (props) => {
  const { userId, setuserId } = props;
  const {
    page,
    switchToAddress,
    handleSignIn,
    switchToPersonalInfo,
    registrationData,
    onPersonalInfoSubmit,
    // onAddressSubmit,
    onPinSubmit,
    handleSubmitVerification,
    loading,
    prefetched,
    handleInitVerification,
    token,
    showProcessing,
    showBankError,
    handleFinicityUrl,
    onAddressSubmit,
    changephoneNo
  } = useRegistration();

  useEffect(() => {
    if (registrationData.personalInfo?.phone) {
      const phone_no = checkPhone(registrationData.personalInfo?.phone);
      setuserId(phone_no);
    }
  }, [registrationData]);

  return (
    <PageContent>
      <form autoComplete="on">
        <ConditionRenderBankConnection
          renderIf={showBankError}
          onContinue={handleFinicityUrl}
          loading={loading}
        />
        <ConditionRenderProcessingModal
          renderIf={!!showProcessing}
          content={showProcessing?.content}
          value={showProcessing?.value}
        />
        <ConditionRenderComponent
          renderIf={!prefetched && !showProcessing && !showBankError}
        >
          <Loading active={true} />
        </ConditionRenderComponent>
        <ConditionRenderPersonalInfo
          renderIf={
            prefetched &&
            page === RegistrationPages.PERSONAL_INFORMATION &&
            !showProcessing &&
            !showBankError
          }
          onContinue={onPersonalInfoSubmit}
          handleSignIn={handleSignIn}
          personalInfo={registrationData.personalInfo}
          loading={loading}
          userId={userId}
        />
        <ConditionRenderVerification
          renderIf={
            page === RegistrationPages.VERIFICATION &&
            !showProcessing &&
            !showBankError
          }
          onContinue={handleSubmitVerification}
          title="Verify your phone number"
          mainText="For your security we sent a six digit code to your phone number "
          boldText={`...${last4Numbers(registrationData.personalInfo?.phone)}`}
          subText=" Enter it below to secure your account."
          leftAction="back"
          cancel={false}
          inputRightAction=""
          handleInputRightAction={undefined}
          handleLeftAction={switchToPersonalInfo}
          loading={loading}
          sendCode={() => handleInitVerification()}
          token={token}
          changephoneNo={changephoneNo}
        />
        <ConditionRenderPin
          renderIf={
            page === RegistrationPages.PIN && !showProcessing && !showBankError
          }
          onContinue={onPinSubmit}
          title="Choose a secure pin"
          mainText="For your security choose a 6 digit pin to use every time you pay with Wafi."
          leftAction="back"
          handleLeftAction={switchToAddress}
          id={registrationData.personalInfo?.phone}
        />

        <ConditionRenderAddress
          renderIf={
            page === RegistrationPages.ADDRESS &&
            !showProcessing &&
            !showBankError
          }
          onContinue={onAddressSubmit}
          loading={loading}
          EditInfo={registrationData.personalInfo}
        />
      </form>
    </PageContent>
  );
};

export default Registration;
