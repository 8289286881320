import styled from "styled-components";
import Constants from "../../../constants";
import { Button } from "semantic-ui-react";

export const Flex = styled("div")`
  display: flex;

  .margin-left-auto {
    margin-left: auto !important;
  }

  .margin-right-auto {
    margin-right: auto !important;
  }
`;

// modal-like wrapper
export const BoxWrapper = styled("div")`
  width: 100%;
  margin: auto;
  padding: 35px 25px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;

  .cancel {
    width: 16px !important;
    height: 16px !important;
    font-size: inherit !important;
  }

  .pointer {
    cursor: pointer;
    :hover {
      transform: scale(1.2);
    }
  }

  ${Constants.LAYOUT.MEDIA_QUERIES.TABLET_PX} {
    width: 50%;
  }

  .center {
    align-items: center;
    // text-align: center;
  }

  .center-hor {
    justify-content: center;
  }

  .full-width {
    width: 100% !important;
  }

  .full-width2 {
    width: 100%;

    ${Constants.LAYOUT.MEDIA_QUERIES.TABLET_PX} {
      width: 65%;
    }
  }

  .margin-top-70 {
    margin-top: 70px !important;
  }

  .margin-top-40 {
    margin-top: 40px !important;
  }

  .margin-top-19 {
    margin-top: 19px !important;
  }

  .margin-top-12 {
    margin-top: 12px !important;
  }
`;

export const WafiText = styled("h4")`
  font: normal normal bold 20px/24px Kefa;
  text-align: left;
  color: #000000;
  opacity: 1;
  padding-left: 12px;
  letter-spacing: 4px;
  margin: 0px;

  ${Constants.LAYOUT.MEDIA_QUERIES.TABLET_PX} {
    font: normal normal bold 28px/34px Kefa;
    letter-spacing: 5.6px;
  }
`;

export const ContinueButton = styled(Button)`
  background: #707070 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 3px 6px #00000029 !important;
  border-radius: 5px !important;
  opacity: 1;
`;

export const ContinueText = styled("span")`
  text-align: left;
  font: normal normal bold 20px/14px Cochin;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
`;

export const NoAccountText = styled("h4")`
  // text-align: left;
  font: normal normal normal 16px/18px Cochin;
  letter-spacing: 0px;
  color: #2c39d1;
  opacity: 1;
`;

export const ErrorWrapper = styled("div")`
  background: #f89494 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  text-align: left;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  padding: 1.2em;
  font: normal normal normal 16px/20px Cochin;
`;

export const SmileyText = styled("h4")`
  font-size: 50px;
`;
