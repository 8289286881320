import { Icon, Progress } from "semantic-ui-react";
import WafiIcon from "../../assets/wafi-icon.png";
import { GrayBox } from "./styles/Processing";
import K from "../../constants";
import { showQuit } from "../../services/quitService";
import ModalHeader from "../PageContent/ModalHeader";
import { Flex, _2414Cochin, _1620Cochin } from "../../@styles";
import WafiFooter from "../PageContent/WafiFooter";

interface IProcessingProps {
  value?: number;
  content?: string;
  max?: number;
}

const ProcessingModal: React.FC<IProcessingProps> = ({
  value,
  content,
  max,
}) => {
  return (
    <>
      <ModalHeader rightType="cancel" />

      <Flex className="center-hor center margin-top-40 progress-bar">
        <Progress
          value={value ?? K.PROGRESS_BAR.DEFAULT}
          total={max ?? K.PROGRESS_BAR.MAX}
          progress="percent"
          color="blue"
          className="full-width"
          indicating
        />
      </Flex>
      <Flex className="center-hor margin-top-40 center">
        <div className="full-width">
          <_2414Cochin color="#000" alignText="center" display="block">
            Processing ....
          </_2414Cochin>
        </div>
      </Flex>

      <Flex className="center-hor margin-top-70 ">
        <GrayBox className="full-width">
          <_1620Cochin color="#000" alignText="center" display="block">
            {content || `We’re now securely contacting your bank`}
          </_1620Cochin>
        </GrayBox>
      </Flex>

      <Flex className="center-hor center margin-top-70 ">
        {/* <div className="spinner">
          <img src="/assets/WafiIcon.png" alt="" />
        </div> */}

        <div className="secured">
          <p>Secured with 256 bit encryption</p>
        </div>
      </Flex>
      <WafiFooter />
    </>
  );
};

export default ProcessingModal;
