import { Flex, _1924Cochin, _1618Cochin } from "../../@styles";

export default function WafiDescription() {
  return (
    <Flex className="center-hor">
      <div className="full-width">
        <_1924Cochin
          className="margin-top-40"
          color="#000"
          display="block"
          alignText="center"
        >
          Pay with your bank account and earn cash backs
        </_1924Cochin>
        <_1618Cochin
          className="margin-top-12"
          display="block"
          alignText="center"
        >
          Wafi let’s you pay with your bank account and earn cash back every
          time you pay.
        </_1618Cochin>
      </div>
    </Flex>
  );
}
