const Constants = {
  APP_VERSION: 1,
  SERVICES: {
    RESULTS_PER_PAGE: 30,
  },
  BASE_API_URL:process.env.REACT_APP_BASE_API_URL,
  API_URLS: {
    REGISTRATION: "/connect/register",
    LOGIN: "/connect/login",
    FAST_CONNECT:"/connect-bank",
    // LOGIN: "dashboard/login",
    INITIALIZE_VERIFICATION: "/user/verify",
    VERIFY_TOKEN: "/user/token/verify",
    CONNECT_FINICITY: "/connect/finicity",
    CONNECT_CARD: "/card",
    CONNECT_SINGLE_CARD: (card_id: string) => `/card/${card_id}`,
    CONNECT_USER: "/connect/user",
    CREATE_ADDRESS_USER: "/connect/address",
    UPDATE_CONNECT_USER_ADDRESS: (addressId: string) =>
      `/connect/user/address/${addressId}`,
    CONNECT_USER_BANK_ACCOUNTS: "/connect/user/bank-accounts",
    CONNECT_USER_LIVE_BALANCE: "/connect/user/live-balance",
    CONNECT_USER_WALLET: "/connect/user/wallet",
    CONNECT_USER_WALLET_INIT: "/connect/user/wallet/init",
    CONNECT_USER_PAYMENT_CASHBACK: "/connect/user/payment/cashback",
    CONNECT_USER_PAYMENT_CHARGE: "/connect/user/payment/charge",
    CONNECT_USER_DECRYPT_TOKEN: "/token/decrypt",
    INITIALIZE_RESET_PIN: "/connect/password/reset",
    CHANGE_PIN: "/connect/password/change",
    GET_RECIPIENT_DATA: "/connect/prefetch-data",
    GET_EXPRESS_DATA: (expressId: string) => `/express-checkout-recipient/${expressId}`,
    GET_BANK_LINK: "/connect/prefetch/bank",
    GET_STATE_SUGGESTIONS: "/connect/state-sugestions",
    GENERATE_ACCESS_CODE: "/checkout-ui",
    ACCOUNT_EXIST_CHECK: "/connect/user/exists",
    LOGOUT: "/connect/logout",
    TWILIO_LOOKUP: "/twilio-lookup",
    QUIT_TRANSACTION: (client_id: string, transaction_id: string) =>  `/checkout/${client_id}/quit/${transaction_id}/`,
    CONNECT_USER_READINESS: "/connect/user/readiness",
  },
  STORAGE_KEYS: {
    REDUX_STATE: "wafi-connect_state-!09.s-redux_",
    JWT_TOKEN: "wafi-connect_k13.golgo13_",
    REFRESH_TOKEN: "wafi-connect_k13.golgo144_",
    WAFI_FIN_URL: "wafi-connect_k13.fin13_",
    jWT_EXPIRY: "wafi-connect_k14doid.fin373",
    SUCCESS_CALLBACK_URL:"wafi-connect_k13.golgo14_",
    CANCEL_CALLBACK_URL:"wafi-connect_k13.golgo15_",
    CONNECTID:"wafi-connect_k13.golgo16_"
  },
  EVENTS: {
    RELOAD: "reload",
    NEW_FILTER_CREATED: "new_filter_created",
    SHOW_NOTIFICATION: "show_top_level_notification",
    DISMISS_NOTIFICATION: "dismiss_top_level_notification",
    SHOW_CANCEL: "show_top_level_cancel",
    DISMISS_CANCEL: "dismiss_top_level_cancel",
    SHOW_DIALOG: "show_dialog_menu",
    SHOW_TIMEOUT: "show_timeout",
    DISMISS_DIALOG_MENU: "dismiss_dialog_menu",
  },
  LAYOUT: {
    MEDIA_QUERIES: {
      MOBILE: "@media (max-width: 61rem)",
      TABLET: "@media (max-width: 72rem)",
      SMALL_PX: "@media (max-width: 767px)",
      TABLET_PX: "@media (min-width: 768px)",
      LAPTOP_PX: "@media (min-width: 1024px)",
      HEIGHT_440: "@media only screen and  (max-height : 440px) ",
      WIDTH_800: "@media only screen and  (max-width : 800px) ",
      LANDSCAPE: "and (orientation : landscape)",
    },
    MEDIA_QUERIES_NUM: {
      // these are in pixels, based on the media queries right above. they're currently mainly for working with window widths in JS
      MOBILE: 976,
      TABLET: 1152,
    },
  },
  ROUTES: {
    login: "login",
    payment: "payment",
    consent: "consent",
    registration: "registration",
    paymentMethod: "payment-method",
    onboardCard: "onboard-card",
    finicity: "finicity",
  },
  VERIFICATION: {
    SESSION_TOKEN: "wafi-connect_k13_verifcation.session_",
    STATES: {
      VERIFIED: "verified",
      NOT_VERIFIED: "not_verified",
    },
  },
  ACCESS_CODE: {
    SESSION_TOKEN: "wafi-connect_k13_access_code.session_",
  },
  CLIENT_NAME: {
    SESSION_TOKEN: "wafi-connect_k13_merchant._",
  },
  CAN_MAKE_PAYMENT: {
    SESSION_TOKEN: "wafi-connect_k13_merchant_can_payment._",
  },
  LOB_API_KEY: process.env.REACT_APP_LOB_API_KEY,
  SENTRY: {
    DSN: process.env.REACT_APP_SENTRY_DSN,
    SAMPLE_RATE: process.env.REACT_APP_SENTRY_SAMPLE_RATE,
  },
  PROGRESS_BAR: {
    DEFAULT: 3,
    MAX: 5,
  },
};

export default Constants;
