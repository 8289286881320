import styled from "styled-components";
import Constants from "../../../constants";

export const StyledDiv = styled.div`
  position: absolute;
  width: 100%;
  z-index: 9999;
  opacity: 0;
  transition: all 300ms ease;
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.1rem;
  padding: 10px;
  display: flex;
  justify-content: center;
  background-color: white;
  box-shadow: ${({ theme }) => theme.alternateShadow};
  color: #333;
  &.error {
    background-color: #970c0c;
    box-shadow: 0px 0px 6px #970c0c;
    color: white;
  }
  &.error i.icon {
    color: white !important;
  }
  &.warning {
    background-color: white;
    box-shadow: 0px 0px 6px #970c0c;
    color: #970c0c;
  }
  &.warning i.icon {
    color: white !important;
  }
  &.success {
    box-shadow: 0px 0px 6px teal;
    background-color: #fff;
  }
  .ui.circular.exit-btn {
    box-shadow: none;
    padding: 7px;
    margin-left: 15px;
  }
  .ui.circular.exit-btn:hover,
  .ui.circular.exit-btn:active,
  .ui.circular.exit-btn:focus {
    background-color: transparent !important;
    color: ${({ theme }) => theme.background} !important;
  }
  .content {
    flex: 1;
    text-align: center;
  }
  i {
    margin: 0 20px 0 7px !important;
  }
  > * {
    width: 100%;
  }

  .button_active {
    display: flex;
    width: 100%;
    justify-content: center;
    color: #2c39d1;
    cursor: pointer;
    ${Constants.LAYOUT.MEDIA_QUERIES.TABLET_PX} {
      justify-content: end;
    }

    .ui.button {
      background: #2c39d1;
    }
  }
`;

export const styles = {
  show: {
    opacity: 1,
    top: 0,
  },
  hide: {
    opacity: 0,
    top: -40,
  },
};
