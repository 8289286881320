


import styled from "styled-components";

export const LearnMoreWrapper = styled("div")`





.wafilogo img{
    width: 80px;
    height: 25px;
}




/* The wafiModal (background) */
.wafiModal {
    position: fixed; /* Stay in place */
    z-index: 999; /* Sit on top */
    padding-top: 3%; /* Location of the box */
    padding-bottom: 3%;
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  }
  
  /* wafiModal Content */
  .wafiModal-content {
    position: relative;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 20px;
    margin: auto;
    
    padding: 0;
    width: 420px;
 min-height: 386px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
    -webkit-animation-name: animatetop;
    -webkit-animation-duration: 0.4s;
    animation-name: animatetop;
    animation-duration: 0.4s
  }
  
  /* Add Animation */
  @-webkit-keyframes animatetop {
    from {top:-300px; opacity:0} 
    to {top:0; opacity:1}
  }
  
  @keyframes animatetop {
    from {top:-300px; opacity:0}
    to {top:0; opacity:1}
  }
  
  /* The Close Button */
  .close {
    color: #000;
    float: right;
    font-size: 28px;
    font-weight: bold;
    margin: 10px;
  }
  
  .close:hover,
  .close:focus {
    // color: white;
    // text-decoration: none;
    cursor: pointer;
  }
  
  .wafiModal-header {
    border-bottom: 2px solid black;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 0 1rem;

  }
  
  .wafiModal-body {
    padding: 20px 20px;
  }

  .margin-bottom-6{
    margin-bottom: 6px;
  }
  


.wafiModal-body p{
    font: normal normal bold 16px/20px Cochin;
  
}




.wafi-mb-25{
    margin-bottom: 25px;
}
.text{
    margin-left: 1.2rem;
    padding: .5rem;
}




.no-show{
    display: none;
}

@media only screen and (max-width: 768px) {
    .wafiModal-content {
        width: 95%;
    }

 
  }


  /* @media only screen and (max-width: 420px) {

    .sectionBanner .wafilogo{
        display: none;
    }

  } */
`;
