import { Fragment, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import styled from "styled-components";
import { BankConnection, Loading } from "../_shared/components";
import { ConditionRenderComponent } from "../_shared/components/ConditionRender";
import withTimeout from "../_shared/hooks/useTimeout";
import PageContent from "../_shared/components/PageContent/PageContent";
import WafiFooter from "../_shared/components/PageContent/WafiFooter";
import useFastFinicity from "./hooks/useFastFinicity";
import { FastConnect } from "../fast-connect/FastConnect";
import BankError from "../_shared/components/BankError/BankError";
declare const window: any;

const FastFinicity: React.FC = () => {
  const {
    loading,
    finicityUrl,
    error,
    setFinicityUrl,
    fetchFinicity,
    handleInit,
    logo,
    ...rest
  } = useFastFinicity();

  return (
    <PageContent>
      <>
        <ConditionRenderComponent renderIf={!loading && rest?.step==0}>
          <FastConnect
            setFinicityUrl={setFinicityUrl}
            handleInit={handleInit}
            logo={logo}
            clientName={rest?.client_name}
            handleErrorCancel={rest.handleErrorCancel}
          />
        </ConditionRenderComponent>

        <ConditionRenderComponent renderIf={loading && !error}>
          <Loading active={true} />
        </ConditionRenderComponent>

        <ConditionRenderComponent renderIf={!!finicityUrl && rest?.step==2}>
          <div>
            <BankError onContinue={handleInit} onExit={rest.handleErrorCancel}/>
          </div>
        </ConditionRenderComponent>

        <ConditionRenderComponent renderIf={!!finicityUrl && rest?.step==1}>
          <div>
            <FrameWrapper id="connect-container" />
          </div>
        </ConditionRenderComponent>
      </>
    </PageContent>
  );
};

const FrameWrapper = styled("div")`
  iframe {
    height: 100vh !important;
    width: 100% !important;
  }
`;

export default FastFinicity;
