import { Icon } from "semantic-ui-react";
import { Flex, ContinueButton, _2014Cochin, _1618Cochin, _1626Cochin } from "../../@styles";
import { showQuit } from "../../services/quitService";
import { ConditionRenderComponent } from "../ConditionRender";
import ModalHeader from "../PageContent/ModalHeader";
import WafiFooter from "../PageContent/WafiFooter";
import { MainText } from "../RadioField/styles/RadioField";
import { WafiText, ErrorWrapper, SmileyText } from "./styles/BankConnection";

interface PhoneProps {
  onContinue?: () => void;
  handleSignUp?: () => void;
  type?: number;
  loading?: boolean;
}

const BankConnection: React.FC<PhoneProps> = (props) => {
  const { onContinue, handleSignUp, type, loading } = props;
  return (
  
    <>
      <ModalHeader rightType="cancel" />
      <Flex className="center-hor center margin-top-100">
        
     
        <span style={{font:"normal normal bold 20px/20px Cochin"}}>
        {type ? "" : "Bank connecting issue"}
        </span>
     
   
      </Flex>
      <Flex className="center-hor margin-top-10">
        <ErrorWrapper className="full-width2">
          {type
            ? "Sorry, we could not fetch user data. Try again later"
            : "Sorry it appears we have lost our connection to your bank. We are now working to resolve the issue."}
        </ErrorWrapper>
      </Flex>

      <Flex className="center-hor margin-top-19">
        {/* <SmileyText>😔</SmileyText> */}
      </Flex>
      <ConditionRenderComponent renderIf={!type}>
        <Flex className="center-hor margin-top-40">
          <ContinueButton
            bg="#000"
            className="full-width2"
            onClick={onContinue}
            disabled={loading}
            loading={loading}
          >
            <Icon name="lock" inverted={true} />
            <_2014Cochin color="#fff">Connect your bank now</_2014Cochin>
          </ContinueButton>
        </Flex>
      </ConditionRenderComponent>
      <Flex className="center-hor margin-top-19 pointer">
        <_1618Cochin onClick={() => showQuit("quit")}>
          Exit and pay with a different method
        </_1618Cochin>
      </Flex>
      <WafiFooter />
    </>
  

  );
};

export default BankConnection;
