import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import storeCreator from "./redux/store";
import { Provider } from "react-redux";
import 'semantic-ui-css/semantic.css'
import * as Sentry from "@sentry/react";
import Constants from "./modules/_shared/constants";

Sentry.init({
  dsn: Constants.SENTRY.DSN,
  // To set a uniform sample rate
  tracesSampleRate: Constants.SENTRY.SAMPLE_RATE,
});

storeCreator().then((store) => {
  ReactDOM.render(
    <React.StrictMode>
      {/* <link
        // async
        rel="stylesheet"
        href="https://cdn.jsdelivr.net/npm/semantic-ui@2/dist/semantic.min.css"
      /> */}
      {/* <script src="https://cdn.jsdelivr.net/npm/semantic-ui-react/dist/umd/semantic-ui-react.min.js"></script> */}
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
    </React.StrictMode>,
    document.getElementById("root")
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
