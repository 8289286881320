import { Button } from "semantic-ui-react";
import styled from "styled-components";
import K from "../../constants";

export const Flex = styled("div")`
  align-items: center;
  display: flex;

  .margin-bottom-42{
    margin-bottom: 42px;
  }

  .margin-top-43{
    margin-top: 43px;
  }

  .padding-lr-10{
    padding 0px 10px;
  }

  .margin-right-auto {
    margin-right: auto !important;
  }

  .wafi-icon-small{
    width: 42px;
    height:42px;
  }

  .logoHeight{
    height: 50px;
  }
`;
export const EllipsisLoader = styled("div")`
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #E6EDf5;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
`;

export const FormWrapper = styled("div")`
    // background: #1956cb2b 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  // padding: 1.2em 1.3em;
  display: flex;
  flex-wrap: wrap;


  
    ${K.LAYOUT.MEDIA_QUERIES.SMALL_PX} {
      flex-direction: column !important;
    }



  .wafi-icon {
    display: block;
    ${K.LAYOUT.MEDIA_QUERIES.TABLET_PX} {
      display: none;
    }
  }
  

  .wafi-icon-small {
    display: none;
    ${K.LAYOUT.MEDIA_QUERIES.TABLET_PX} {
      display: block;
    }
  }

  .flex-wrapper-padding {
    ${K.LAYOUT.MEDIA_QUERIES.LAPTOP_PX} {
      padding: 0 0.8em;
    }
  }
  

  .flex-wrapper {
    flex: 100%;
   
   

    // &:not(:first-of-type) {
    //   margin-top: 19px;
    // }
    

  
  


    ${K.LAYOUT.MEDIA_QUERIES.LAPTOP_PX} {
      // padding: 0 0.8em;

      flex: 50%;

      &:nth-of-type(2) {
        margin-top: 0px;
      }
    }
    .field{
      &::after{
        content: "*" ;
        color: #1956CB ;
    }
  }
  }
  
  .per-row{
    display: flex;
    gap: 1.6em;
    margin: .8em 0;
    ${K.LAYOUT.MEDIA_QUERIES.SMALL_PX} {
      flex-direction:column !important;
    }
  }

  .phonefield {
    input {
      letter-spacing: 0.1px !important;
   
    }
  }
`;

// modal-like wrapper
export const BoxWrapper = styled("div")`
  width: 100%;
  margin: auto;
  padding: 15px 25px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;

  .cancel {
    width: 16px !important;
    height: 16px !important;
    font-size: inherit !important;
  }

  .pointer {
    cursor: pointer !important;
    :hover {
      transform: scale(1.2) !important;
    }
  }

  #manaulAddress {
    .field {
      margin: 0 !important;
    }
  }

  ${K.LAYOUT.MEDIA_QUERIES.TABLET_PX} {
    width: 50%;
  }

  .center {
    align-items: center;
    // text-align: center;
  }

  .center-hor {
    justify-content: center;
  }

  .full-width {
  
    ${K.LAYOUT.MEDIA_QUERIES.SMALL_PX} {
      flex-direction: column !important;
    }
  }

  .full-width2 {
    width: 100% !important;

    ${K.LAYOUT.MEDIA_QUERIES.TABLET_PX} {
      width: 65% !important;
    }
  }

  .margin-top-70 {
    margin-top: 70px !important;
  }

  .margin-top-40 {
    margin-top: 40px !important;
  }

  .margin-top-52 {
    margin-top: 52px !important;
  }

  .margin-top-19 {
    margin-top: 19px !important;
  }

  .margin-top-20 {
    margin-top: 20px !important;
  }
  // margin-0 {
  //   margin-bottom: 0px !important;
  // }

  .margin-top-12 {
    margin-top: 19px !important;
  }
`;

export const SignUpButton = styled(Button)`
  background: #ffffff 0% 0% no-repeat padding-box !important;
  border: 1px solid #000000 !important;
  border-radius: 5px !important;
  font: normal normal bold 16px/19px Cochin !important;
  letter-spacing: 0px !important;
  color: #000000 !important;
  width: 129px;
`;

export const WafiText = styled("h4")`
  font: normal normal bold 20px/24px Kefa;
  text-align: left;
  color: #000000;
  opacity: 1;
  padding-left: 12px;
  letter-spacing: 4px;
  margin: 0px;

  ${K.LAYOUT.MEDIA_QUERIES.TABLET_PX} {
    font: normal normal bold 28px/34px Kefa;
    letter-spacing: 5.6px;
  }
`;

export const PhoneText = styled("h4")`
  font: normal normal bold 24px/14px Cochin;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
`;

export const PhoneSubText = styled("h4")`
  font: normal normal normal 16px/20px Cochin;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
`;

export const ContinueButton = styled(Button)`
  background: #707070 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 3px 6px #00000029 !important;
  border-radius: 5px !important;
  opacity: 1;
  height: 50px;
  width: 65%;
`;

export const ConnectButton = styled(Button)`
  background: #000 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 3px 6px #00000029 !important;
  border-radius: 5px !important;
  opacity: 1;
  height: 50px;
  width: 65%;
  align-items: center;
  justify-content: center;
  display: flex !important;
`;

export const ContinueText = styled("span")`
  text-align: left;
  font: normal normal bold 20px/14px Cochin;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
`;

export const SecureText = styled("h4")`
  text-align: center;
  font: normal normal normal 16px/24px Cochin;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
`;

export const NoAccountText = styled("h4")`
  // text-align: left;
  font: normal normal normal 16px/18px Cochin;
  letter-spacing: 0px;
  color: #2c39d1;
  opacity: 1;
`;

export const ManualAddressText = styled("h4")`
  text-align: left;
  font: normal normal normal 16px/24px Cochin;
  letter-spacing: 0px;
  color: #2c39d1;
  opacity: 1;
  margin: 0px;
`;
