import styled from "styled-components";

export const InputWrapper = styled("div")`
  .field {
    margin: 0px !important;
    font: normal normal normal 16px/18px Cochin;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
  }
  .right-10{
    position: absolute;
    margin-top: 10px;
    right: 30px;
    
  }
  .eye-color i{
    color: #707070 !important;
    font-size: 20px;
  }
  .ui.input {
    width: 100%;
    margin-top: 0%;
    font: normal normal normal 16px/18px Cochin !important;
    letter-spacing: 0.1px !important;
    color: #000000 !important;
    border-radius: 20px !important;
    input {
      border: 1px solid black;
    }
  }
  
  .error {
    input {
      border: 1px solid red !important;
    }
  }
`;
