import { NotificationParams } from "../@types";
import K from "../constants";
import { EventEmitter } from "./eventEmitter";

export function dismissNotifications() {
  EventEmitter.dispatch(K.EVENTS.DISMISS_NOTIFICATION);
}

export function showErrorMessage(msg: string, duration: number = 5000) {
  EventEmitter.dispatch(K.EVENTS.SHOW_NOTIFICATION, {
    content: msg,
    isError: true,
    autoHides: false,
    isDismissable: true,
    icon: "warning",
    iconSize: "large",
    iconColor: "grey",
    durationShown: duration,
  } as NotificationParams);
}

export function showSuccessMessage(msg: string, duration: number = 5000) {
  EventEmitter.dispatch(K.EVENTS.SHOW_NOTIFICATION, {
    content: msg,
    isError: false,
    autoHides: true,
    isDismissable: true,
    icon: "check",
    iconSize: "large",
    iconColor: "teal",
    durationShown: duration,
  } as NotificationParams);
}

export function showWarning(msg: string, duration: number = 5000) {
  EventEmitter.dispatch(K.EVENTS.SHOW_NOTIFICATION, {
    content: msg,
    isWarning: true,
    autoHides: false,
    isDismissable: true,
    icon: "warning",
    iconSize: "large",
    iconColor: "grey",
    durationShown: duration,
  } as NotificationParams);
}

export function showTimeout(
  msg: string,
  handleActiveTimeout: () => void,
  duration: number = 5000
) {
  EventEmitter.dispatch(K.EVENTS.SHOW_TIMEOUT, {
    content: msg,
    isWarning: true,
    autoHides: false,
    isDismissable: true,
    icon: "warning",
    iconSize: "large",
    iconColor: "grey",
    durationShown: duration,
    handleActiveTimeout: handleActiveTimeout,
  } as NotificationParams);
}
