import Constants from "../../../constants";
import styled from "styled-components";
import { Button, Input } from "semantic-ui-react";

export const Flex = styled("div")`
  display: flex;
  align-items: center;
  .wafi-icon {
    display: block;
    ${Constants.LAYOUT.MEDIA_QUERIES.TABLET_PX} {
      display: none;
    }
  }

  .wafi-icon-small {
    display: none;
    ${Constants.LAYOUT.MEDIA_QUERIES.TABLET_PX} {
      display: block;
    }
  }

  .margin-right-auto {
    margin-right: auto !important;
  }

  .margin-left-auto {
    margin-left: auto !important;
  }

  .cancel {
    width: 16px !important;
    height: 16px !important;
    font-size: inherit !important;
  }

  .pointer {
    cursor: pointer;
    :hover {
      transform: scale(1.2);
    }
  }

  .pinfield {
    input {
      text-align: center !important;
      letter-spacing: 14.4px !important;
    }
  }


  
`;


// modal-like wrapper
export const BoxWrapper = styled("div")`
  width: 100%;
  margin: auto;
  padding: 35px 25px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  opacity: 1;

  ${Constants.LAYOUT.MEDIA_QUERIES.TABLET_PX} {
    width: 50%;
  }

  ${Constants.LAYOUT.MEDIA_QUERIES.LAPTOP_PX} {
    width: 50%;
  }

  .center {
    align-items: center;
    // text-align: center;
  }

  .center-hor {
    justify-content: center;
  }

  .full-width {
    width: 100% !important;
  }

  .full-width2 {
    width: 100% !important;

    ${Constants.LAYOUT.MEDIA_QUERIES.TABLET_PX} {
      width: 100%;
    }
  }

  .margin-top-70 {
    margin-top: 70px !important;
  }

  .margin-top-40 {
    margin-top: 40px !important;
  }

  .margin-top-19 {
    margin-top: 19px !important;
  }

  .margin-top-12 {
    margin-top: 19px !important;
  }
`;

export const SignUpButton = styled(Button)`
  background: #ffffff 0% 0% no-repeat padding-box !important;
  border: 1px solid #000000 !important;
  border-radius: 5px !important;
  font: normal normal bold 16px/19px Cochin !important;
  letter-spacing: 0px !important;
  color: #000000 !important;
`;

export const WafiText = styled("h4")`
  font: normal normal bold 20px/24px Kefa;
  text-align: left;
  color: #000000;
  opacity: 1;
  padding-left: 12px;
  letter-spacing: 4px;
  margin: 0px;

  ${Constants.LAYOUT.MEDIA_QUERIES.TABLET_PX} {
    font: normal normal bold 28px/34px Kefa;
    letter-spacing: 5.6px;
  }
`;

export const PhoneText = styled("h4")`
  font: normal normal bold 24px/14px Cochin;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
`;

export const PhoneSubText = styled("h4")`
  font: normal normal normal 16px/20px Cochin;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
`;

export const InputField = styled(Input)`
  background: #ffffff 0% 0% no-repeat padding-box !important;
  border: 0.5px solid #000000 !important;
  border-radius: 5px !important;
  font: normal normal normal 18px/14px Cochin !important;
  letter-spacing: 7.2px !important;
  color: #707070 !important;
`;

export const ContinueButton = styled(Button)`
  background: #707070 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 3px 6px #00000029 !important;
  border-radius: 5px !important;
  opacity: 1;
  height: 50px !important;
`;

export const ContinueText = styled("span")`
  text-align: left;
  font: normal normal bold 20px/14px Cochin;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
`;


export const SecureText = styled("h4")`
  text-align: center;
  font: normal normal normal 16px/24px Cochin;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
`;


export const ForgotPinText = styled("h4")`
  text-align: center;
  font: normal normal normal 16px/18px Cochin;
  letter-spacing: 0px;
  color: #2c39d1;
  opacity: 1;
`;

export const NoAccountText = styled("h4")`
  // text-align: left;
  font: normal normal normal 16px/18px Cochin;
  letter-spacing: 0px;
  color: #2c39d1;
  opacity: 1;
`;

export const HintColor = styled("span")`
  font: normal normal normal 14px/17px Cochin;
  letter-spacing: 0px;
  color: #707070;
`;

export const BoldHint = styled("span")`
  font: normal normal bold 14px/17px Cochin;
  letter-spacing: 0px;
  color: #000000;
`;
export const Footercheckbox = styled("div")`
 display:flex;
 gap:.5rem;
 padding-top:10px;

 input[type=  checkbox]:checked {
  accent-color: #000000 !important;
 
`;
