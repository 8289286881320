import localforage from "localforage";
import throttle from "lodash/throttle";
import { createStore, Store } from "redux";
import K from "../modules/_shared/constants";
import initialStore from "./initialStore";
import { rootReducer } from "./reducers";
import { ReduxState } from "./@types";

const storeCreator = (): Promise<Store<any, any>> => {
  return new Promise(async (resolve) => {
    let persistedState: Record<string, any> = {};
    let newState = {} as ReduxState;

    try {
      persistedState =
        (await localforage.getItem(K.STORAGE_KEYS.REDUX_STATE)) || {};
    } catch (error) {}
    /**
     * if the structure of redux has changed, using the existing structure (saved in localforage)
     * will break stuff. In that case neglect the persisted state.
     * Of course a better way will be to know exactly what keys have changed and merge the new
     * structure with the persisted one...
     */
    if (persistedState?.version !== initialStore.version) {
      for (const key of Object.keys(initialStore)) {
        if (key === "version") {
          // for the redux version, we simply use the new one
          newState[key] = initialStore[key];
        } else {
          /**
           * for everything else, we make sure not to discard the existing data
           * so we simply merge them together
           */
          const stateKey = key as keyof ReduxState;
          newState[stateKey] = Object.assign(
            {},
            initialStore[stateKey],
            persistedState[key]
          );
        }
      }
      persistedState = newState;
    }

    const store = createStore(
      rootReducer,
      persistedState,
      (window as any).__REDUX_DEVTOOLS_EXTENSION__ &&
        (window as any).__REDUX_DEVTOOLS_EXTENSION__()
    );
    store.subscribe(
      throttle(() => {
        /**
         * NOTE: not every key in redux is persisted. e.g is the misc key
         */
        const reduxStore = store.getState();
        localforage.setItem(K.STORAGE_KEYS.REDUX_STATE, {
          version: reduxStore.version,
          user: reduxStore.user,
          settings: reduxStore.settings,
        });
      }, 1500)
    );

    resolve(store);
  });
};

export default storeCreator;
