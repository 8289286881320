export enum RegistrationPages {
  PERSONAL_INFORMATION = "personal_information",
  ADDRESS = "address",
  VERIFICATION = "verification",
  PIN = "pin"
}

export type PersonalInfoObject = {
  firstName?: string;
  lastName?: string;
  email: string;
  phone: string;
  userId?: any;
  addresses: AddressObject[];
};

export type AddressObject = {
  street1: string;
  apartment_no: string;
  city: string;
  state: string;
  postalCode: string;
  country: string;
  id?: string;
};

export type RegistrationData = {
  personalInfo: PersonalInfoObject;
  addressInfo: AddressObject[];
  pin: string;
};

export interface IProcessingData {
  content: string;
  value: number;
}
