import styled from "styled-components";

export const StyledDiv = styled.div`
  width: 100%;
  height: 100%;
  background-color: white;
  z-index: 1;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;


  .spinner > img {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-roll 1.5s infinite ease-in-out both;
    animation: sk-roll 1.5s infinite ease-in-out both;
  }
  .spinner .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }
  .spinner .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }
  .secured{
    position absolute;
    bottom: 50px;
    min-width: 100px;
    text-align: center;
    font: normal normal normal 16px/24px Cochin;
    letter-spacing: 0px;
    color: #707070;
  }
  @keyframes sk-roll {
    0%,
    80%,
    100% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
      width: 70px;
      height: 70px;

    }
    40% {
      -webkit-transform: rotate(180deg);
      transform: rotate(180deg);
      width: 50px;
      height: 50px;
    }
  }
`;
