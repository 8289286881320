import { useLocation, useNavigate } from "react-router";
import K from "../constants";

function useConsent() {
  const navigate = useNavigate();
  const { search } = useLocation();

  function handleSignUp() {
    navigate(`/${K.ROUTES.registration}${search}`);
  }

  function handleSignIn() {
    navigate(`/${K.ROUTES.login}${search}`);
    return null
  }

  return {
    handleSignUp,
    handleSignIn,
  };
}

export default useConsent;
