import styled from "styled-components";

export const SectionWrapper = styled("div")`
.margin-top-2 {
  margin-top: 2%;
}

.margin-left-2 {
  margin-left 2%;
}
`;

export const Flex = styled("div")`
  display: flex;

  .margin-right-auto {
    margin-right: auto !important;
  }
`;

export const TitleText = styled("h4")`
  //   text-align: center;
  font: normal normal bold 16px/19px Cochin;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
`;

export const SubTitleText = styled("h4")`
  // text-align: left;
  font: normal normal normal 16px/18px Cochin;
  letter-spacing: 0px;
  color: #707070;
  opacity: 1;
`;

export const IconContainer = styled("div")`
  width: 38px;
  height: 38px;
  /* UI Properties */
  background: #000000 0% 0% no-repeat padding-box;
  opacity: 1;
  text-align: center;
  vertical-align: middle;
  line-height: 32px;
  border-radius: 40px;
`;
