import React, { useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router";
import Constants from "../../constants";
import { isAuthenticated } from "../../services";

export interface ProtectedRouteProps {
  path: string;
  exact?: boolean;
  element?: React.ComponentType;
  state?: any;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  element,
  path,
  exact,
  state,
}) => {
  const [authenticated, setAuthenticated] = useState<boolean | null>(null);
  let El: any = element!;

  useEffect(() => {
    const auth = async () => {
      const isAuth = await isAuthenticated();
      // console.log(isAuth, "check auth");
      setAuthenticated(isAuth);
    };
    auth();
  }, []);

  if (authenticated === true || authenticated === false) {
    return authenticated ? (
      <El state={state} />
    ) : (
      <Navigate to={{ pathname: `/${Constants.ROUTES.login}?continue_url=${window.location.href}` }} />
    );
  }
  return null;
};

export default ProtectedRoute;
